import React, { ReactNode } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { AnimatePresence, motion } from "framer-motion";
import Landing from "./views/Landing";
import PatooLanding from "./views/PatooLanding";
import Microdosing from "./views/Microdosing";
import FAQ from "./views/Faq";
import Protocols from "./views/Protocols";
import PrivacyPolicy from "./views/PrivacyPolicy";
import AboutUs from "./views/AboutUs";
import Terms from "./views/Terms";
import InvestorsDeck from "./views/InvestorsDeck";
import Auth from "./views/Auth";
import NayaChat from "./views/NayaChat";
// Journal
import Journal from "./views/Journal";
import MyPractice from "./components/Portal/MyPractice";
import Chat from "./components/Portal/Chat";
import JournalHome from "./components/Portal/JournalHome";
import JournalEntries from "./components/Portal/Journal";
import Account from "./components/Portal/Account";
import Journeys from "./components/Portal/Journeys";
import ContactSupport from "./components/Portal/ContactSupport";

interface PageTransitionProps {
  children: ReactNode;
}
function PageTransition({ children }: PageTransitionProps) {
  const pageTransition = {
    initial: { opacity: 0.3, y: -20, delay: 0.2 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 1, y: 20 },
    transition: { duration: 1.2, ease: "easeInOut" },
  };

  return <motion.div {...pageTransition}>{children}</motion.div>;
}

function App() {
  return (
    <Router>
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/patoo" element={<PatooLanding />} />
          <Route path="/what-is-microdosing" element={<Microdosing />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/investors" element={<InvestorsDeck />} />
          <Route path="/protocols" element={<Protocols />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/chat" element={<NayaChat />} />
          <Route path="/journal" element={<Journal />}>
            <Route
              index
              element={
                <PageTransition>
                  <JournalHome />
                </PageTransition>
              }
            />
            <Route
              path="chat"
              element={
                <PageTransition>
                  <Chat />
                </PageTransition>
              }
            />
            <Route
              path="entries"
              element={
                <PageTransition>
                  <JournalEntries />
                </PageTransition>
              }
            />
            <Route
              path="journeys"
              element={
                <PageTransition>
                  <Journeys />
                </PageTransition>
              }
            />
            <Route
              path="account"
              element={
                <PageTransition>
                  <Account />
                </PageTransition>
              }
            />
            <Route
              path="support"
              element={
                <PageTransition>
                  <ContactSupport />
                </PageTransition>
              }
            />
          </Route>
          <Route path="/auth" element={<Auth />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AnimatePresence>
    </Router>
  );
}

export default App;
