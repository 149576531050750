import { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { supabase } from "../../../lib/supabaseClient";
import { getUserBenefitsFromMap } from "../../../utils/benefits";
import { UserType } from "../../../types";
import { useAuth } from "../../../contexts/AuthProvider";

interface CheckInProps {
  user: UserType;
  setOpenCheckIn: () => void;
}

const CheckIn = ({ user, setOpenCheckIn }: CheckInProps) => {
  const { getUser } = useAuth();
  const benfitsArray = getUserBenefitsFromMap({
    benefit1: user.benefit1,
    benefit2: user.benefit2,
    benefit3: user.benefit3,
  });
  const benefitsObject: { [key: string]: string } = benfitsArray.reduce(
    (accumulator, value) => {
      return { ...accumulator, [value]: 4 };
    },
    {}
  );
  const [benfitRange, setBenfitRange] = useState(benefitsObject);

  const saveCheckIn = async () => {
    const { data, error } = await supabase
      .from("survey")
      .insert({
        ...benfitRange,
        user_id: user.id,
        protocol_day: user.state.protocol_day,
        day_type: user.state.day_type,
        tg_user_id: user.tg_user_id,
      })
      .select();
    setOpenCheckIn();
    toast("Your daily checkin was recorded.");
    const currentDate = moment().format("YYYY-MM-DD"); // Get the current date in the desired format
    const stageTracking = user?.state.stage_tracking || {};
    const checkin = data || [];
    stageTracking[currentDate] = {
      ...stageTracking[currentDate],
      checkin: checkin[0].id,
    };
    await supabase
      .from("user_state")
      .update({ stage_tracking: stageTracking })
      .eq("user_id", user?.id);
    window.analytics.track("new_checkin");
    await getUser();
  };

  return (
    <div className="mt-3">
      <p className="text-eastern-blue text-center text-xl mb-3">Check-in +</p>
      <hr className="border-gray-light mb-8" />
      <div className="flex justify-between gap-6 mb-5 ">
        {user.state && (
          <div className="flex gap-2 text-sm">
            <div className="bg-sky-light px-2 py-1 rounded text-white">
              {user.state?.day_type}
            </div>
            <div className="bg-sky-light px-2 py-1 rounded text-white">
              {user.dose}
            </div>
          </div>
        )}
        <span className="text-sky-light">
          {moment().format("MMM DD, YYYY")}
        </span>
      </div>
      <div className="pb-4">
        {Object.keys(benfitRange).map((item, index) => (
          <div className="flex flex-col pb-6" key={index}>
            <p className="mb-2">
              {
                user[
                  `benefit${index + 1}` as "benefit1" | "benefit2" | "benefit3"
                ]
              }
            </p>
            <p className="bg-blue text-white py-1 px-2 rounded w-fit text-center m-auto mb-4">
              {benfitRange[item]}
            </p>
            <input
              value={benfitRange.benfit1}
              onChange={(e) => {
                const _benfitRange = { ...benfitRange, [item]: e.target.value };
                setBenfitRange(_benfitRange);
              }}
              min="1"
              max="7"
              step="1"
              type="range"
              className="transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-gray"
            />
          </div>
        ))}
      </div>

      <button
        onClick={() => saveCheckIn()}
        className="bg-blue w-full justify-center flex text-white rounded py-2"
      >
        Save
      </button>
    </div>
  );
};

export default CheckIn;
