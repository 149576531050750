import { useEffect, useState } from "react";
import { capitalize } from "lodash";
import moment from "moment";
import { motion } from "framer-motion";
import MenuIcon from "../../assets/img/menu.png";
import { getNextDayType } from "../../utils/nayaUtils";
import { UserType } from "../../types";
import { Link } from "react-router-dom";

interface WelcomeProps {
  user: UserType;
  isUserActive: boolean;
  setSideMenuOpen: () => void;
}

const Welcome = ({ user, setSideMenuOpen, isUserActive }: WelcomeProps) => {
  const [greeting, setGreeting] = useState<string>();
  useEffect(() => {
    const date = new Date();
    const hours = date.getHours();
    if (hours < 12) setGreeting("morning");
    else if (hours >= 12 && hours <= 17) setGreeting("afternoon");
    else if (hours >= 17 && hours <= 24) setGreeting("evening");
  }, []);

  return (
    <>
      <div className="flex text-gray text-md justify-between md:justify-end relative">
        <div
          className="absolute top-1 md:hidden l-0"
          onClick={() => setSideMenuOpen()}
        >
          <img src={MenuIcon} className="w-6" />
        </div>
        <Link
          to="/journal/chat"
          className={`${
            !isUserActive && "!text-gray pointer-events-none"
          } absolute top-1 right-0`}
        >
          <motion.div
            variants={{
              initial: { y: 0 },
              animate: {
                y: -10,
                transition: {
                  duration: 0.4,
                  repeat: 5,
                  repeatType: "reverse",
                },
              },
            }}
            initial="initial"
            animate="animate"
            style={{ display: "inline-block" }} // Adjust styling as needed
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.5em"
              className={`fill-eastern-blue hover:fill-primary ${
                !isUserActive && "!fill-gray"
              } `}
              viewBox="0 0 512 512"
            >
              <path d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM144 272a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm144-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm80 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
            </svg>
          </motion.div>
        </Link>
      </div>

      <div className="pt-2 mb-4 text-center md:text-left">
        {isUserActive ? (
          <>
            <p className={`mb-2 font-light ${!isUserActive && "text-2xl"}`}>
              Good {greeting}, {user.user_name ?? user.name}
            </p>
            {user.state.day_type && user.state?.starting_date && (
              <p className="text-2xl ">
                It's {capitalize(user.state.day_type)} Day 🥳
              </p>
            )}
          </>
        ) : (
          <div>
            {user.onboarding_step === "onboarding" && (
              <p>
                Hi!
                <br />
                I’m excited to get to know you!
                <br />
                Complete this short questionnaire and I will
                <br /> match you with a microdosing protocol 🤗
              </p>
            )}
            {user.onboarding_step === "finished_onboarding" && (
              <p>
                Thank you for sharing {user.user_name}!<br />
                It’s a pleasure to meet you 🙌
                <br />
                <b />
                Now, it’s my turn to do my magic 🪄 💫
              </p>
            )}
            {user.onboarding_step === "protocol_on_boarding" && (
              <p>
                {user.user_name}
                <br />
                Based on the information you shared, I would
                <br />
                consider starting out with....🥁🥁🥁
              </p>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-between mb-2">
        <span className="text-xs md:text-sm text-sky-light">
          {moment(new Date()).format("MMMM DD, yyyy")}
        </span>
        {user.state.day_type && user.state?.starting_date && (
          <span className="text-xs md:text-sm text-sky-light">
            Tomorrow is{" "}
            {getNextDayType(
              user.protocol,
              user.state.day_type,
              user.state.microdosing_days
            )}{" "}
            day
          </span>
        )}
      </div>
    </>
  );
};

export default Welcome;
