import { useState } from "react";
import moment from "moment";
import { useAuth } from "../../../contexts/AuthProvider";
import { supabase } from "../../../lib/supabaseClient";
import { toast } from "react-toastify";

const JournalEntry = () => {
  const [entrie, setEntrie] = useState("");
  const { user } = useAuth();
  const addJournalEntrie = async () => {
    const { data: journalData, error: journalError } = await supabase
      .from("journals")
      .insert({
        text: entrie,
        user_id: user?.id,
        day_type: user?.state?.day_type,
        dose: user?.dose,
        medicine_type: user?.medicine_type,
      })
      .select();
    const journal = journalData || [];
    const currentDate = moment().format("YYYY-MM-DD"); // Get the current date in the desired format
    const stageTracking = user?.state.stage_tracking || {};
    stageTracking[currentDate] = {
      ...stageTracking[currentDate],
      journal: journal[0].id,
    };
    await supabase
      .from("user_state")
      .update({ stage_tracking: stageTracking })
      .eq("user_id", user?.id);
    toast("Your daily journal practice is saved.");
    window.analytics.track("new_journal_entrie");
  };
  return (
    <div>
      <p className="text-xl text-orange text-center">Journal+</p>
      <hr className="text-orange-light my-4" />
      <textarea
        rows={10}
        className="bg-soft-cream text-brown w-full rounded-md py-2 focus:outline-none outline-none focus:border-sky-500 mb-2 border-none placeholder-brown"
        placeholder="Something that inspired me today... "
        name="journal-entry"
        value={entrie}
        onChange={(e) => setEntrie(e.target.value)}
      />
      <button
        className="flex m-auto bg-orange text-white rounded-lg px-4 py-1 mt-8"
        onClick={() => addJournalEntrie()}
      >
        Add
      </button>
    </div>
  );
};

export default JournalEntry;
