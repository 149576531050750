import moment from "moment";
import { useState } from "react";
import { getArrayOfMonthDates } from "../../../utils/dates";
import { getStartDate } from "../../../utils/nayaUtils";
import Monthly from "./Monthly";
import WeekDays from "./WeekDays";
import { UserType } from "../../../types";

export type Days = { [key: string]: string };
interface CalendarProps {
  type?: "monthly" | "weekly";
  user: UserType;
}

const Calendar = ({ type, user }: CalendarProps) => {
  const [seeMonthly, setSeeMonthly] = useState(false);
  const startingDates = getStartDate(
    user.state.archive_starting_date,
    user.state.starting_date,
    user.protocol
  );
  console.log(startingDates);
  const daysArray = getArrayOfMonthDates(startingDates[0].date);
  const daysType: Days = {};
  startingDates.map((startingDate) => {
    let counter = 0;
    daysArray.forEach((day, index) => {
      if (moment(day).isSameOrAfter(startingDate.date, "day")) {
        const key: string = moment(day).format("YYYY-MM-DD");
        const protocolDayConstant = startingDate.protocol === "fadiman" ? 3 : 2;
        daysType[key] =
          counter % protocolDayConstant === 0 ? "microdosing" : "";
        counter = counter + 1;
      }
    });
  });

  return (
    <div>
      <div className="flex justify-between">
        <p className="text-xl">{seeMonthly ? "My Month" : "My Week"}</p>
        <button
          onClick={() => setSeeMonthly(!seeMonthly)}
          className="text-gray text-sm"
        >
          {seeMonthly ? "See Weekly" : "See Monthly"}
        </button>
      </div>
      <hr className="border-gray my-4" />
      {seeMonthly ? (
        <Monthly
          daysType={daysType}
          startingDate={startingDates[0].date}
          userState={user.state}
        />
      ) : (
        <WeekDays
          daysType={daysType}
          protocol={user.protocol}
          startingDate={startingDates[0].date}
          userState={user.state}
        />
      )}
    </div>
  );
};
export default Calendar;
