import { BrandLayout } from "../layouts/Brand";
import Accordion from "../components/UiElements/Accordion";

export default function Faq() {
  return (
    <>
      <BrandLayout>
        <div className="px-8 md:px-0 relative pt-8 md:pt-32 pb-12 content-center items-center justify-center md:min-h-screen-75">
          <div className="container md:my-[100px] mx-auto z-10">
            <h1 className="text-5xl mb-12 md:mt-[28px] font-serif text-primary text-center md:text-left">
              FAQ
            </h1>
            <div className="space-y-6">
              <Accordion
                title="Who is microdosing for?"
                desc="People who practice microdosing come from all walks of life. Some seek to improve their personal and professional lives by boosting creativity, focus, and mood. Others microdose to regulate emotions, improve mental health, or experience a more connected state of being. There are many possible intentions, and these may vary over time. Microdosing can be supportive for any consenting adult who is curious about the possible benefits of this practice ."
              />
              <Accordion
                title="Is microdosing safe?"
                desc="Even though microdosing is generally considered safe, based on the data gathered by the microdosing institute, it is not without risks. If you have personal or family history of mental health challenges or are using prescribed medication, consult with a medical professional before microdosing."
              />
              <Accordion
                title="How important is it to follow the protocols?"
                desc="Psychedelics are powerful tools. Treating them with reverence can reduce risks and lead to positive outcomes."
              />
              <Accordion
                title="Is it safe to microdose while I am on medication?"
                desc=" It depends on the medication and its dose. There are no clinical trials that combine microdosing with specific medications. All current information is based on data that was collected through non-controlled studies, or reports of people who have microdosed successfully while being on medication. The most comprehensive list of substances that it is considered safe to microdose with can be found on the microdosing institute website (https://microdosinginstitute.com/microdosing-101/faq/microdosing-combining-medication-supplements/).  Be sure to consult your medical provider before microdosing while on medication or taper off medication before starting. We encourage you to always be responsible and do your own research from reliable sources. Note that it is not recommended to microdose while treated with Lythium Carbonate."
              />
              <Accordion
                title="Does the small amount of microdosing makes it legal?"
                desc="No. In most jurisdictions, even the bare minimal amount is considered illegal. You should research the applicable laws in you place of residence before using any substance."
              />
              <Accordion
                title="Can I trust what I see online, on Netflix or read in books?"
                desc="Do your own research! Everything on our website or in our products are for informational purposes only. You are urged to do your own research before taking any substance. While we cannot assume liability for its content Erowid (https://www.erowid.org/) is a good place to strat."
              />
              <Accordion
                title="I have a substance I want to use, want’s next?"
                desc="Always test your substances to make sure they are what you think they are.!"
              />
              <Accordion
                title="I’m experiencing headaches, nausea, migraines or other physical adverse effects, what should I do."
                desc="Current research has no explanation as to why this is happening. If you have been experiencing an adverse effect, you should consider stopping microdosing, reducing dosage or changing substances."
              />
              <Accordion
                title="I’m hearing noises that are not caused by sounds coming from the outside world - Do I have Tinnitus? What should I do?"
                desc="Current research has no explanation as to why this is happening. If you have been experiencing Tinnitus before microdosing and it got worse, or if you have started to experience Tinnitus after you started microdosing, you should consider stopping microdosing, reducing dosage or changing substances."
              />
            </div>
            <div className="pt-10">
              <p className="py-10">
                Disclaimer: The content on this website and Naya products is for
                informational and educational purposes only, and does not
                substitute professional medical advice or consultation with
                healthcare professionals. If you are seeking medical advice,
                diagnose, or treatment, we advise you to consult a medical
                professional or healthcare provider. Equally, we cannot help
                with nor promote the sourcing of illegal substances.
              </p>
            </div>
          </div>
        </div>
      </BrandLayout>
    </>
  );
}
