import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import {
  getUserBenefitsFromMap,
  getUserBenefitNameFromMap,
} from "../../utils/benefits";
import { UserType, SurveysType } from "../../types";
Chart.register(...registerables);

interface ProgressProps {
  protocolDay: number;
  surveys: SurveysType[];
  user: UserType;
}

const Progress = ({ user, protocolDay = 0, surveys }: ProgressProps) => {
  const [userBenefitsData, setUserBenefitsData] = useState<SurveysType[] | []>(
    []
  );
  const [userBenefitsName, setUserBenefitsName] = useState<string[]>([]);
  useEffect(() => {
    const names = getUserBenefitsFromMap({
      benefit1: user.benefit1,
      benefit2: user.benefit2,
      benefit3: user.benefit3,
    });
    setUserBenefitsName(names);
    getUserProgress();
  }, []);

  const getUserProgress = async () => {
    setUserBenefitsData(surveys);
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        grid: {
          display: false,
        },
        min: 1,
        max: 7,
        ticks: {
          display: false,
          stepSize: 1,
        },
        border: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
          stepSize: 1,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    width: "100%",
  };

  const getUserBenefitsGraphDate = (benefit: string) => {
    const amountOfDays = surveys.length;
    const key = getUserBenefitNameFromMap(benefit);
    const dataSet = new Array(Number(amountOfDays));

    _.map(userBenefitsData, (item: any, index) => {
      dataSet[item.protocol_day] = item[key];
    });
    const avg = dataSet.reduce((a, b) => a + b, 0) / userBenefitsData.length;
    _.map(dataSet, (item: any, index) => {
      if (!item) dataSet[index] = avg;
    });

    return {
      labels: Array.from({ length: amountOfDays! }, (v, i) => i + 1),
      datasets: [
        {
          data: dataSet,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          lineTension: 0.3, // Adjust the line tension for smoother curve
          pointBackgroundColor: "transparent", // Remove data point markers
          pointBorderColor: "transparent", // Remove data point markers
        },
      ],
    };
  };

  return (
    <>
      <div className="max-w-xl overflow-hidden mx-auto">
        <div className="py-4">
          <p className="tracking-[-0.04em] text-primary md:text-xl text-center mb-4">
            Microdosing for {user.intention}
          </p>
          {user.benefit1 && (
            <div className="mb-5 bg-soft-cream relative px-2 flex flex-col items-end">
              <div className="relative w-full">
                <Line
                  className="w-full"
                  options={options}
                  data={getUserBenefitsGraphDate(user.benefit1)}
                />
              </div>
              <p className="text-sm pb-2">{user.benefit1}</p>
            </div>
          )}
          {user.benefit2 && (
            <div className="mb-5 bg-soft-cream relative px-2 flex flex-col items-end">
              <div className="relative w-full">
                <Line
                  className="w-full"
                  options={options}
                  data={getUserBenefitsGraphDate(user.benefit2)}
                />
              </div>
              <p className="text-sm">{user.benefit2}</p>
            </div>
          )}
          {user.benefit3 && (
            <div className="mb-5 bg-soft-cream relative px-2 flex flex-col items-end">
              <div className="relative w-full">
                <Line
                  className="w-full"
                  options={options}
                  data={getUserBenefitsGraphDate(user.benefit3)}
                />
              </div>
              <p className="text-sm pb-2">{user.benefit3}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Progress;
