import moment from "moment-timezone";
import { DayType, Protocol, ProtocolMappings } from "../types";

const ProtocolNextdayMappings: ProtocolMappings = {
  fadiman: {
    microdosing: "transition",
    transition: "reflection",
    reflection: "microdosing",
  },
  other_day: {
    microdosing: "transition",
    transition: "microdosing",
  },
  naya: {
    sunday_monday: [
      "microdosing",
      "microdosing",
      "transition",
      "transition",
      "transition",
      "transition",
      "reflection",
    ],
    sunday_tuesday: [
      "microdosing",
      "reflection",
      "microdosing",
      "transition",
      "transition",
      "transition",
      "reflection",
    ],
    sunday_wednesday: [
      "microdosing",
      "transition",
      "reflection",
      "microdosing",
      "transition",
      "transition",
      "reflection",
    ],
    sunday_thursday: [
      "microdosing",
      "transition",
      "transition",
      "reflection",
      "microdosing",
      "transition",
      "reflection",
    ],
    sunday_friday: [
      "microdosing",
      "transition",
      "transition",
      "transition",
      "reflection",
      "microdosing",
      "reflection",
    ],
    sunday_saturday: [
      "microdosing",
      "transition",
      "transition",
      "transition",
      "transition",
      "reflection",
      "microdosing",
    ],
    monday_tuesday: [
      "transition",
      "reflection",
      "microdosing",
      "microdosing",
      "transition",
      "transition",
      "transition",
    ],
    monday_wednesday: [
      "transition",
      "microdosing",
      "reflection",
      "microdosing",
      "transition",
      "transition",
      "transition",
    ],
    monday_thursday: [
      "transition",
      "microdosing",
      "transition",
      "reflection",
      "microdosing",
      "transition",
      "transition",
    ],
    monday_friday: [
      "transition",
      "microdosing",
      "transition",
      "transition",
      "reflection",
      "microdosing",
      "transition",
    ],
    monday_saturday: [
      "transition",
      "microdosing",
      "transition",
      "transition",
      "transition",
      "reflection",
      "microdosing",
    ],
    tuesday_wednesday: [
      "transition",
      "transition",
      "reflection",
      "microdosing",
      "transition",
      "transition",
      "transition",
    ],
    tuesday_thursday: [
      "transition",
      "transition",
      "microdosing",
      "reflection",
      "microdosing",
      "transition",
      "transition",
    ],
    tuesday_friday: [
      "transition",
      "transition",
      "microdosing",
      "transition",
      "reflection",
      "microdosing",
      "transition",
    ],
    tuesday_saturday: [
      "transition",
      "transition",
      "microdosing",
      "transition",
      "transition",
      "reflection",
      "microdosing",
    ],
    wednesday_thursday: [
      "transition",
      "transition",
      "transition",
      "reflection",
      "microdosing",
      "transition",
      "transition",
    ],
    wednesday_friday: [
      "transition",
      "transition",
      "transition",
      "microdosing",
      "reflection",
      "microdosing",
      "transition",
    ],
    wednesday_saturday: [
      "transition",
      "transition",
      "transition",
      "microdosing",
      "transition",
      "reflection",
      "microdosing",
    ],
    thursday_friday: [
      "transition",
      "transition",
      "transition",
      "transition",
      "reflection",
      "microdosing",
      "transition",
    ],
    thursday_saturday: [
      "transition",
      "transition",
      "transition",
      "transition",
      "microdosing",
      "reflection",
      "microdosing",
    ],
    friday_saturday: [
      "transition",
      "transition",
      "transition",
      "transition",
      "reflection",
      "microdosing",
      "microdosing",
    ],
  },
  stamets: {
    sunday: [
      "microdosing",
      "microdosing",
      "microdosing",
      "microdosing",
      "transition",
      "transition",
      "reflection",
    ],
    monday: [
      "reflection",
      "microdosing",
      "microdosing",
      "microdosing",
      "microdosing",
      "transition",
      "transition",
    ],
    tuesday: [
      "transition",
      "reflection",
      "microdosing",
      "microdosing",
      "microdosing",
      "microdosing",
      "transition",
    ],
    wednesday: [
      "transition",
      "transition",
      "reflection",
      "microdosing",
      "microdosing",
      "microdosing",
      "microdosing",
    ],
    thursday: [
      "microdosing",
      "transition",
      "transition",
      "reflection",
      "microdosing",
      "microdosing",
      "microdosing",
    ],
    friday: [
      "microdosing",
      "microdosing",
      "transition",
      "transition",
      "reflection",
      "microdosing",
      "microdosing",
    ],
    saturday: [
      "microdosing",
      "microdosing",
      "microdosing",
      "transition",
      "transition",
      "reflection",
      "microdosing",
    ],
  },
};

const goToTelegram = async (tgUserId: string | null, userId: string) => {
  const url = tgUserId
    ? "https://t.me/withnaya_bot?start"
    : `https://t.me/withnaya_bot?start=${userId}`;
  window.open(encodeURI(url), "_blank", "noreferrer");
};

const returnDoseBySubstance = (dose: string, medicine_type: string) => {
  if (!medicine_type) return dose;
  const medicine = medicine_type.toLowerCase().replace(" ", "_");
  if (medicine === "lsd") return `${dose ?? 10} μg`;
  if (medicine === "psilocybin_mushrooms") return `${dose ?? 0.1} grams`;
  if (medicine === "patoo_capsules") return `${dose ?? 1} capsule`;
  if (medicine === "patoo_honey") return `${dose ?? 0.5} Tea spoon (5ml)`;
  return "No dose on record";
};

const getStartDate = (
  archiveStartingDate: { protocol: string; starting_date: string }[],
  startingDate: string,
  currentProtocol: string
): { protocol: string; date: string }[] => {
  if (!archiveStartingDate || archiveStartingDate.length === 0) {
    return [{ protocol: currentProtocol, date: startingDate }]; // Return the provided starting date if archiveStartingDate is undefined, null, or empty.
  }

  const datesArray = archiveStartingDate.map((item) => ({
    protocol: item.protocol,
    date: item.starting_date,
  }));

  datesArray.push({ protocol: currentProtocol, date: startingDate });

  // Sort the dates in ascending order (oldest to newest)
  const sortedDates = datesArray.sort((a, b) =>
    moment(a.date).isBefore(b.date) ? -1 : 1
  );

  return sortedDates;
};

const returnMicrodosingDaysString = (microdosingDays: string[]): string => {
  if (!microdosingDays) return "";
  microdosingDays.sort(
    (a, b) => moment(a, "dddd").day() - moment(b, "dddd").day()
  );
  return microdosingDays.join("_");
};

const getNextDayType = (
  protocol: Protocol,
  dayType: DayType,
  days?: string[]
): DayType => {
  const combineDays = returnMicrodosingDaysString(days || []);
  const protocolArray = ProtocolNextdayMappings[protocol];
  if (protocol === "naya" || protocol === "stamets") {
    const daysSchedule = protocolArray[combineDays];
    if (Array.isArray(daysSchedule))
      return daysSchedule[(moment().day() + 1) % 7];
  } else {
    if (protocolArray && protocolArray[dayType]) {
      return protocolArray[dayType] as DayType;
    }
  }
  return "microdosing";
};

export { returnDoseBySubstance, goToTelegram, getStartDate, getNextDayType };
