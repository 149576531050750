import { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { capitalize } from "lodash";
import { toast } from "react-toastify";
import CheckIn from "./CheckIn";
import JournalEntriesView from "./JournalEntriesView";
import { JournalEntrie, UserType } from "../../../types";
import { supabase } from "../../../lib/supabaseClient";
import { useAuth } from "../../../contexts/AuthProvider";

interface JournalEntriesProps {
  user: UserType;
}

const JournalEntries = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [journalEntries, setJournalEntries] = useState<JournalEntrie[] | null>(
    user?.journals ?? []
  );
  const [journalEntrieText, setJournalEntrieText] = useState<string | null>(
    null
  );
  const [openJournalTextEditor, setOpenJournalTextEditor] = useState(false);
  const [openCheckIn, setOpenCheckIn] = useState(false);
  const [selectedEntrie, setSelectedEntrie] = useState<JournalEntrie | null>(
    null
  );
  const [oneJournalAdayMsg, setOneJournalAdayMsg] = useState(false);
  const [mood, setMood] = useState<number | null>(null);

  useEffect(() => {
    setOpenJournalTextEditor(false);
    setOpenCheckIn(false);
  }, [location]);

  const getUserJournalEntries = async () => {
    const { data, error } = await supabase
      .from("journals")
      .select("*")
      .eq("user_id", user?.id);
    setJournalEntries(data);
  };

  const addJournalEntrie = async () => {
    if (selectedEntrie?.id) {
      await supabase
        .from("journals")
        .update({ text: journalEntrieText })
        .eq("id", selectedEntrie.id);
    } else {
      const { data: journalData, error: journalError } = await supabase
        .from("journals")
        .insert({
          text: journalEntrieText,
          mood,
          user_id: user?.id,
          day_type: user?.state?.day_type,
          dose: user?.dose,
          medicine_type: user?.medicine_type,
        })
        .select();
      const journal = journalData || [];
      const currentDate = moment().format("YYYY-MM-DD"); // Get the current date in the desired format
      const stageTracking = user?.state.stage_tracking || {};
      stageTracking[currentDate] = {
        ...stageTracking[currentDate],
        journal: journal[0].id,
      };
      await supabase
        .from("user_state")
        .update({ stage_tracking: stageTracking })
        .eq("user_id", user?.id);
      toast("Your daily journal practice is saved.");
      window.analytics.track("new_journal_entrie");
    }
    setJournalEntrieText("");
    getUserJournalEntries();
    setOpenJournalTextEditor(false);
  };

  const canOpenJournalTextEditor = () => {
    const lastjournalEntrie = journalEntries ? journalEntries[0] : null;
    if (
      lastjournalEntrie &&
      moment(lastjournalEntrie.created_at).isSame(new Date(), "day")
    ) {
      setOneJournalAdayMsg(true);
      setTimeout(() => {
        setOneJournalAdayMsg(false);
      }, 5000);
      return;
    }
    setJournalEntrieText(typeof journalEntrieText === "string" ? null : "");
    setOpenJournalTextEditor(true);
  };

  const canCheckIn = () => {
    const lastSurvey = user ? [...user.surveys].pop() : null;
    if (lastSurvey && moment(lastSurvey.created_at).isSame(new Date(), "day"))
      return;
    setOpenCheckIn(true);
  };

  const editEntrie = (entrie: JournalEntrie) => {
    setSelectedEntrie(entrie);
    setOpenJournalTextEditor(true);
  };

  const state = user?.state;

  return (
    <>
      {user && (
        <div className="overflow-hidden mx-auto border-[#E0DCD7] p-4 md:p-8">
          {openJournalTextEditor && (
            <div>
              <button onClick={() => setOpenJournalTextEditor(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  className="h-4 w-4"
                >
                  <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>
              </button>
              <div className="mt-3">
                <p className="text-eastern-blue text-center text-xl mb-3">
                  Journal +
                </p>
                <hr className="border-gray-light mb-8" />
                <div className="flex justify-between gap-6 mb-5 ">
                  {state && (
                    <div className="flex gap-2">
                      <div className="bg-sky-light px-2 py-1 rounded text-white">
                        {selectedEntrie
                          ? capitalize(selectedEntrie.day_type)
                          : capitalize(state?.day_type)}
                      </div>
                      {!selectedEntrie?.dose ||
                        (!user.dose && (
                          <div className="bg-sky-light px-2 py-1 rounded text-white">
                            {selectedEntrie ? selectedEntrie.dose : user.dose}
                          </div>
                        ))}
                    </div>
                  )}
                  <span className="text-sky-light">
                    {selectedEntrie
                      ? moment(selectedEntrie.created_at).format("MMM DD, YYYY")
                      : moment().format("MMM DD, YYYY")}
                  </span>
                </div>
                <textarea
                  rows={10}
                  className="bg-[#FAF6EF] w-full rounded-md py-2 focus:outline-none focus:border-sky-500 mb-2 border-none"
                  placeholder="Something that inspired me today... "
                  name="journal-entry"
                  defaultValue={selectedEntrie?.text ?? ""}
                  onChange={(e) => setJournalEntrieText(e.target.value)}
                />
                <button
                  disabled={Boolean(!journalEntrieText)}
                  onClick={() => addJournalEntrie()}
                  className="bg-blue w-full justify-center flex text-white rounded py-2 cursor-pointer"
                >
                  {selectedEntrie ? "Save" : "+ Add"}
                </button>
              </div>
            </div>
          )}
          {!openJournalTextEditor && !openCheckIn && (
            <div>
              <p className="text-eastern-blue text-center text-xl mb-3">
                My Journal
              </p>
              <hr className="border-gray-light" />
              <div className="flex justify-center mt-6 gap-4">
                <button
                  onClick={() => canOpenJournalTextEditor()}
                  className="bg-blue-light rounded px-2 md:px-8 py-2 flex gap-1 md:gap-4 font-light w-full text-center justify-center items-center flex"
                >
                  <span className="text-sm md:text-md">Journal 📝</span>
                  <span className="text-sm md:text-md">+</span>
                </button>
                <button
                  onClick={() => canCheckIn()}
                  className="bg-blue-light rounded px-2 md:px-8 py-2 flex gap-1 md:gap-4 font-light w-full text-center justify-center items-center flex"
                >
                  <span className="text-sm md:text-md">Check-in 🌻</span>
                  <span className="text-sm md:text-md">+</span>
                </button>
              </div>
              {oneJournalAdayMsg && (
                <p className="text-red mt-2 transition delay-1000	 duration-300 ease-in-out">
                  We offer one journal entry a day, you welcome to <b>update</b>{" "}
                  your today entry
                </p>
              )}
              <div className="py-10">
                <p className="text-center mb-6 text-xl">
                  Microdosing for {user.intention}
                </p>
              </div>
              <JournalEntriesView
                entries={journalEntries}
                openJournalTextEditor={openJournalTextEditor}
                editEntrie={(e) => editEntrie(e)}
              />
            </div>
          )}
          {openCheckIn && (
            <CheckIn user={user} setOpenCheckIn={() => setOpenCheckIn(false)} />
          )}
        </div>
      )}
    </>
  );
};

export default JournalEntries;
