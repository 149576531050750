import moment from "moment";

export const getCurrentWeek = () => {
  const currentDate = moment();

  const weekStart = currentDate.clone().startOf("isoWeek");
  const weekEnd = currentDate.clone().endOf("isoWeek");

  const days = [];

  for (var i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, "days"));
  }
  return days;
};

export const getArrayOfMonthDates = (startingDate: string) => {
  const startOfMonth = moment(startingDate).startOf("month");
  const endOfMonth = moment().add(2, "months").endOf("month");

  var days = [];
  var day = startOfMonth;

  while (day <= endOfMonth) {
    days.push(day.toDate());
    day = day.clone().add(1, "d");
  }
  return days;
};
