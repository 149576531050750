import { Widget } from "@typeform/embed-react";
import { useState } from "react";
import Loading from "../UiElements/Loading";
const ENV = process.env.NODE_ENV;
interface TypeFormOnBoardingProps {
  email: string;
  user_id: string;
  userRef: string;
  onSubmit: () => void;
}

const TypeFormOnBoarding = ({
  email,
  user_id,
  onSubmit,
  userRef,
}: TypeFormOnBoardingProps) => {
  const [boardingFormIsReady, setBoardingFormIsReady] = useState(false);
  let formId = ENV === "development" ? "rikfJyzA" : "rQNHy9A7";
  if (userRef === "withpatoo") {
    formId = "orOvLi3e";
  }
  return (
    <>
      {!boardingFormIsReady && (
        <div className="justify-center flex mt-10">
          <Loading loading={!boardingFormIsReady} />
        </div>
      )}
      <Widget
        className="h-full w-full h-[77vh]"
        key="TypeFormOnBoarding"
        id={formId || "rikfJyzA"}
        hidden={{
          email: email,
          user_id: user_id,
        }}
        onEndingButtonClick={() => onSubmit()}
        onReady={() => {
          window.analytics.track("started_tf_onboarding");
          setBoardingFormIsReady(true);
        }}
      />
    </>
  );
};

export default TypeFormOnBoarding;
