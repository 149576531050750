import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { supabase } from "../../lib/supabaseClient";
import { UserType } from "../../types";
import HexagonLoading from "../UiElements/Loading/HexagonLoading";
import { useAuth } from "../../contexts/AuthProvider";

interface JourneysType {
  user_id: string;
  story: string;
  title: string;
  text: string;
  public: boolean;
  image: string;
  id: number;
}

interface JourneyModalProps {
  journey: JourneysType | null;
  open: boolean;
  setOpenJourneyModal: () => void;
}
const JourneyModal = ({
  journey,
  open,
  setOpenJourneyModal,
}: JourneyModalProps) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setOpenJourneyModal()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-xl font-medium">
                  {journey?.title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-gray text-xs">Your journey</p>
                  <p className="">{journey?.text}</p>
                  {journey?.story && (
                    <div className="mt-4">
                      <p className="text-gray text-xs">The journey story</p>
                      <p className="">{journey?.story}</p>
                    </div>
                  )}
                  <img
                    src={journey?.image}
                    alt={journey?.title ?? "journey image"}
                    className="mt-6"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const Journeys = () => {
  const navigate = useNavigate();
  const { user, isPaused } = useAuth();
  const [step, setStep] = useState<string | null>("start");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [openStoryTextarea, setOpenStoryTextarea] = useState(false);
  const [story, setStory] = useState("");
  const [image, setImage] = useState<any>();
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [selectedJourney, setSelectedJourney] = useState<JourneysType | null>(
    null
  );
  const [openJourneyModal, setOpenJourneyModal] = useState(false);
  const [journeys, setJourneys] = useState<JourneysType[]>([]);
  const [isPublic, setIsPublic] = useState(false);

  useEffect(() => {
    getJourneys();
  }, []);
  const create = async () => {
    setLoading(true);
    setTimeout(() => {
      setMessage(true);
    }, 3000);
    const { data, error } = await supabase.functions.invoke("journeys", {
      body: {
        userId: user?.id,
        title,
        text,
        is_public: isPublic,
        story,
      },
    });
    setLoading(false);
    setMessage(false);
    getJourneys();
    if (data) setImage(data?.image);
  };

  const getJourneys = async () => {
    const { data, error } = await supabase
      .from("journeys")
      .select("*")
      .eq("user_id", user?.id);
    setJourneys(data as JourneysType[]);
  };

  return (
    <>
      {user && (
        <div className="relative h-full p-4 md:p-8">
          {loading && (
            <div className="flex justify-center items-center absolute w-full h-full rounded z-10 flex-col">
              <div className="bg-[#bebebeba] w-full h-full absolute rounded-2xl" />
              {message && (
                <p className="z-10 transition duration-600 ease-in-out text-white">
                  Your image is getting created ...
                </p>
              )}
              <HexagonLoading loading={loading} />
            </div>
          )}
          {step === "start" && (
            <div className="flex flex-col justify-between">
              <p className="text-eastern-blue text-center text-xl mb-3">
                My Journeys
              </p>
              <hr className="border-gray-light mb-8" />
              {journeys.length < 2 && (
                <p>
                  Create unforgettable experiences with Naya journeys -
                  transform your psychedelic journeys into stunning visual
                  masterpieces. Let Naya take you on a mesmerizing visual
                  adventure, where every moment becomes a work of art.
                </p>
              )}
              <button
                onClick={() => {
                  if (isPaused) navigate("/journal/account#subscription");
                  setStep("form");
                }}
                className="text-primary rounded-md md:text-[18px] py-[8px] m-auto px-4 mt-4 border border-primary"
              >
                Share your journey
              </button>
              <div className="mt-10 text-center">
                <p className="pb-2">Your last journeys</p>
                <hr className="border-gray-light py-3" />
                {!journeys.length && (
                  <p className="text-gray">You don't have journeys yet</p>
                )}
                <div className="grid grid-cols-2 gap-6 relative">
                  {journeys.map((j) => (
                    <div
                      onClick={() => {
                        setSelectedJourney(j);
                        setOpenJourneyModal(true);
                      }}
                      key={j.id}
                      className="max-w-sm rounded overflow-hidden shadow-lg relative cursor-pointer"
                    >
                      <img
                        className="brightness-75"
                        src={j.image}
                        alt={j.title}
                      />
                      <span className="text-white absolute top-2 left-2">
                        {j.title}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {step === "form" && (
            <div>
              <button
                onClick={() => {
                  setStep("start");
                  setImage(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  className="h-5 w-5"
                >
                  <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>
              </button>
              <p className="text-eastern-blue text-center text-xl">Form</p>
              <div className="flex flex-col">
                {!image ? (
                  <div className="mt-4 relative">
                    <input
                      value={title}
                      onChange={(v) => setTitle(v.target.value)}
                      placeholder="Title"
                      className="px-2 bg-[#FAF6EF] w-full rounded-md py-2 focus:outline-none focus:border-sky-500 mb-6 border-none"
                    />
                    <textarea
                      value={text}
                      onChange={(v) => setText(v.target.value)}
                      placeholder="In several words describe your journey"
                      rows={3}
                      className="px-2 bg-[#FAF6EF] w-full rounded-md py-2 focus:outline-none focus:border-sky-500 mb-2 border-none"
                    />
                    <div className="mb-2">
                      <button
                        className="text-sm"
                        onClick={() => setOpenStoryTextarea(true)}
                      >
                        + Add the story of your journey
                      </button>
                      {openStoryTextarea && (
                        <div className="absolute w-full h-full top-0 p-3 bg-white flex flex-col rounded gap-2">
                          <textarea
                            value={story}
                            onChange={(v) => setStory(v.target.value)}
                            placeholder="Write your full experience"
                            className="px-2 bg-[#FAF6EF] h-full w-full rounded-md py-2 focus:outline-none focus:border-sky-500 mb-2 border-none"
                          />
                          <button
                            onClick={() => setOpenStoryTextarea(false)}
                            className="bg-primary text-white rounnded rounded py-1"
                          >
                            Done
                          </button>
                        </div>
                      )}
                    </div>
                    <label className="flex items-center text-sm cursor-pointer w-auto">
                      <input
                        type="checkbox"
                        className="mr-2 w-3 h-3"
                        placeholder=""
                        checked={isPublic}
                        onChange={(v) => setIsPublic(v.target.checked)}
                      />
                      Make it public
                    </label>
                    <button
                      onClick={() => create()}
                      className="bg-primary text-white rounded-md md:text-[18px] py-[8px] m-auto px-4 mt-6"
                    >
                      Create
                    </button>
                  </div>
                ) : (
                  <div className="mt-5">
                    <p className="text-center mb-2">Your Journey image</p>
                    {image && <img src={image} className="w-2/3 m-auto" />}
                  </div>
                )}
              </div>
            </div>
          )}
          <JourneyModal
            journey={selectedJourney}
            open={openJourneyModal}
            setOpenJourneyModal={() => setOpenJourneyModal(false)}
          />
        </div>
      )}
    </>
  );
};

export default Journeys;
