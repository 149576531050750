import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { capitalize } from "lodash";
import { toast } from "react-toastify";
import SelectTime from "../Forms/SelectTime";
import { supabase } from "../../lib/supabaseClient";
import { returnDoseBySubstance } from "../../utils/nayaUtils";
import { ProtoccolSelection } from "./Protocol/ProtoccolSelection";
import SelectInput from "../Forms/SelectInput";
import { useAuth } from "../../contexts/AuthProvider";

export type editArray = {
  notification_time: false;
  protocol: false;
};
interface MyPracticeProps {
  setIsSelectProtocol: (selected: boolean) => void;
}

const MyPractice = ({ setIsSelectProtocol }: MyPracticeProps) => {
  const { user } = useAuth();
  const [editArray, setEditArray] = useState({} as editArray);
  const [showDialog, setShowDialog] = useState(false);
  const [notificationTime, setNotificationTime] = useState<string>(
    user?.notification_time ?? "10:00"
  );
  const [protocol, setProtocol] = useState<string>(user?.protocol ?? "fadiman");

  const toggleEditArray = (name: string) => {
    const _editArray: Record<string, boolean> = { ...editArray };
    _editArray[name] = !_editArray[name];
    setEditArray(_editArray as editArray);
  };

  const saveNotificationTime = async () => {
    const { data, error } = await supabase
      .from("users")
      .update({ notification_time: notificationTime })
      .eq("id", user?.id);
    toggleEditArray("notification_time");
    setNotificationTime(notificationTime);
    toast("Reminder time updated");
  };

  const selectProtocol = async () => {
    setIsSelectProtocol(true);
    setShowDialog(true);
  };
  const saveProtocol = async () => {
    toggleEditArray("protocol");
  };

  return (
    <div className="">
      {user && (
        <div className="w-full">
          {showDialog ? (
            <ProtoccolSelection
              user={user}
              close={() => setShowDialog(false)}
            />
          ) : (
            <div>
              <p className="text-eastern-blue text-center text-xl mb-3">
                My Practice
              </p>
              <hr className="border-gray-light mb-8" />
              <div className="mx-auto w-full rounded-2xl p-2">
                <Disclosure defaultOpen={true}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between items-center py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span className="text-xl">My Method</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 fill-sky-light rotate-90`}
                        >
                          <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                        </svg>
                      </Disclosure.Button>
                      <Disclosure.Panel className="pt-4 pb-2 text-gray-500">
                        <div className="mb-4">
                          <p className="text-xl">
                            {user.state?.protocol_day ?? 0}
                          </p>
                          <span className="text-sm text-gray">
                            Microdosing days
                          </span>
                          <hr className="border-gray-light" />
                        </div>
                        <div className="mb-4">
                          <p className="text-xl">
                            {returnDoseBySubstance(
                              user.dose,
                              user.medicine_type
                            )}
                          </p>
                          <span className="text-sm text-gray">Last dosage</span>
                          <hr className="border-gray-light" />
                        </div>
                        <div className="mb-4">
                          <div className="flex justify-between mb-5">
                            {editArray["protocol"] ? (
                              <div className="w-2/3">
                                <SelectInput
                                  items={["fadiman", "two_fixed_days"]}
                                  value={protocol}
                                  setValue={(v) => setProtocol(v)}
                                />
                              </div>
                            ) : (
                              <div>
                                <p className="text-xl">
                                  {capitalize(user.protocol).replace(
                                    "_",
                                    " "
                                  ) ?? "Fadiman"}
                                </p>
                                <span className="text-sm text-gray">
                                  Protocol
                                </span>
                              </div>
                            )}
                            <button
                              className="underline text-gray hover:text-eastern-blue text-sm"
                              onClick={() => selectProtocol()}
                            >
                              Change
                            </button>
                          </div>
                          <hr className="border-gray-light" />
                        </div>
                        <div className="mb-4">
                          <div className="flex justify-between mb-3">
                            {editArray["notification_time"] ? (
                              <div>
                                <div className="mr-2 flex flex-col max-w-[150px]">
                                  <SelectTime
                                    value={notificationTime}
                                    setValue={(v) => setNotificationTime(v)}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <p className="mr-2 text-xl">
                                  {notificationTime ?? "10:00"}
                                </p>
                                <span className="text-sm text-gray">
                                  Reminder time
                                </span>
                              </div>
                            )}
                            <button
                              className="underline text-gray hover:text-eastern-blue text-sm"
                              onClick={() =>
                                editArray["notification_time"]
                                  ? saveNotificationTime()
                                  : toggleEditArray("notification_time")
                              }
                            >
                              {editArray["notification_time"]
                                ? "Save"
                                : "Change"}
                            </button>
                          </div>
                          <hr className="border-gray-light" />
                        </div>
                        <div className="mb-4">
                          <p className="text-xl">{user.medicine_type}</p>
                          <span className="text-sm text-gray">Substance </span>
                          <hr className="border-gray-light" />
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between items-center py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span className="text-xl">My Intention </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 fill-sky-light rotate-90`}
                        >
                          <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                        </svg>
                      </Disclosure.Button>
                      <Disclosure.Panel className="pt-4 pb-2 text-gray-500">
                        <div className="mb-4">
                          <p className="text-xl">{user.intention}</p>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between items-center py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span className="text-xl">My Benefits </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 fill-sky-light rotate-90`}
                        >
                          <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                        </svg>
                      </Disclosure.Button>
                      <Disclosure.Panel className="pt-4 pb-2 text-gray-500">
                        <div className="mb-4 gap-4">
                          <p className="text-md">{user.benefit1}</p>
                          <p className="text-md">{user.benefit2}</p>
                          <p className="text-md">{user.benefit3}</p>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyPractice;
