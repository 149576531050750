import { useState, Fragment, useEffect } from "react";
import CheckoutForm from "./CheckoutForm";
import { UserType } from "../../types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Dialog, Transition } from "@headlessui/react";
import stripe from "../../assets/img/stripe.png";
import logo from "../../assets/img/logo.png";

const { REACT_APP_STRIPE_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_KEY ?? "");

const PLANS: any = {
  monthly: {
    key: "monthly",
    title: "14 days free trial",
    subTitle: "Then $9.99 billed every month",
    price: 9.99,
    trail: true,
  },
};

interface CheckoutProps {
  user: UserType;
  changeStatus: (status: any) => void;
  userRef?: string;
}

const Checkout = ({ user, changeStatus }: CheckoutProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = {
    mode: "subscription" as "subscription",
    amount: 999,
    currency: "usd",
    paymentMethodCreation: "manual" as "manual",
  };

  return (
    <>
      <button
        onClick={() => {
          window.analytics.track("pressd_free_trial_button");
          setIsOpen(true);
        }}
        className={`w-3/4 md:1/3 bg-white border border-primary text-white rounded-md text-primary text-[18px] px-4 py-[8px]`}
      >
        Subscribe for $9.99/Month
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto m-1">
            <div className="flex min-h-full items-center justify-center p-2 md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="absolute pt-6 md:pt-0 md:relative min-h-screen md:min-h-full w-full max-w-xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <button
                    className="absolute right-4 top-5 md:top-5 border-0 order-10 z-10"
                    onClick={() => setIsOpen(false)}
                  >
                    <span className="h-24 w-24 md:h-8 md:w-8 text-4xl md:text-3xl">
                      ×
                    </span>
                  </button>

                  <div className="order-0 px-2 md:px-6 flex gap-2 md:gap-4 pb-2 md:py-5 items-center flex-col md:flex-row relative justify-center text-center md:text-left md:justify-start">
                    <img
                      src={logo}
                      className="w-1/3 md:w-[20%] order-0 flex"
                      alt="logo"
                    />
                    <div className="border-l border-none md:border-gray-light pl-5">
                      <p className="text-[26px] md:text-2xl">
                        Monthly Subscription
                      </p>
                      <p className="text-md md:text-xl">
                        Subscribe for $9.99/month
                      </p>
                    </div>
                  </div>

                  <div className="bg-gray-light p-3 md:p-6 h-ull">
                    <Elements stripe={stripePromise} options={options}>
                      <CheckoutForm
                        email={user.email}
                        user_id={user.id}
                        stripeCustomerId={user.customer.stripe_customer_id}
                        plan={"monthly"}
                        name={user.user_name}
                        phone={user.phone ?? ""}
                        changeStatus={(s: any) => {
                          setIsOpen(false);
                          changeStatus(s);
                        }}
                      />
                    </Elements>
                    <div className="flex justify-end mt-2 md:mt-5">
                      <img src={stripe} className="w-[90px]" />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Checkout;
