import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CheckoutModal from "../Payment/CheckoutModal";
import NayaListOfBenefits from "../UiElements/NayaListOfBenefits";
import { supabase } from "../../lib/supabaseClient";
import InlineEdit from "../Forms/InlineEdit";
import SelectInput from "../Forms/SelectInput";
import ConfirmationModal from "../UiElements/Modals/ConfirmationModal";
import { UserType } from "../../types";
import { useAuth } from "../../contexts/AuthProvider";
import MyPractice from "./MyPractice";

interface AccountProps {
  user: UserType;
}

export type editArray = {
  remindTime: false;
  protocol: false;
  teacher: false;
  amount: false;
  frequency: false;
  userName: false;
  timeZone: false;
  phone: false;
};

const Profile = ({ user }: AccountProps) => {
  const { setUser } = useAuth();
  const [userName, setUserName] = useState(user.user_name);
  const [phone, setPhone] = useState(user.phone);
  const [code, setCode] = useState("");
  const [enterCode, setEnterCode] = useState(false);
  const [timeZone, setTimeZone] = useState<string>(
    user.time_zone
      ? user.time_zone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [editArray, setEditArray] = useState({} as editArray);

  const toggleEditArray = (name: string) => {
    const _editArray: Record<string, boolean> = { ...editArray };
    _editArray[name] = !_editArray[name];
    setEditArray(_editArray as editArray);
  };

  const saveUserName = async () => {
    await supabase
      .from("users")
      .update({ user_name: userName })
      .eq("id", user.id);
    toggleEditArray("userName");
    setUserName(userName);
    setUser({ ...user, user_name: userName });
    toast("User name updated");
  };

  const saveTimeZone = async () => {
    await supabase
      .from("users")
      .update({ time_zone: timeZone })
      .eq("id", user.id);
    toggleEditArray("timeZone");
    setTimeZone(timeZone);
    toast("Time Zone updated");
  };

  const updatePhone = async () => {
    await supabase.functions.invoke("user-action", {
      body: {
        method: "update_auth_user_phone",
        phone,
        user_id: user.id,
      },
    });
    const { data, error } = await supabase.auth.updateUser({
      phone,
    });
    await supabase.auth.refreshSession();
    console.log(error);
    setEnterCode(true);
  };
  return (
    <div>
      <p className="text-eastern-blue text-center text-xl mt-2">Profile</p>
      <hr className="border-gray-light my-4" />
      <div>
        <div className="flex justify-between mb-5">
          {editArray["userName"] ? (
            <div>
              <div className="mr-2 flex flex-col max-w-[150px]">
                <InlineEdit value={userName} setValue={(v) => setUserName(v)} />
              </div>
            </div>
          ) : (
            <div>
              <p className="mr-2 text-xl">{userName}</p>
              <span className="text-xs text-gray">Name</span>
            </div>
          )}
          <button
            className="underline text-gray hover:text-eastern-blue text-sm"
            onClick={() =>
              editArray["userName"]
                ? saveUserName()
                : toggleEditArray("userName")
            }
          >
            {editArray["userName"] ? "Save" : "Change"}
          </button>
        </div>
        <div className="flex justify-between mb-5">
          <div>
            <p className="mr-2 text-xl">
              {user.email ? user.email : "No email enter yet"}
            </p>
            <span className="text-xs text-gray">Email</span>
          </div>
        </div>
        <div className="flex justify-between mb-5">
          {editArray["timeZone"] ? (
            <div>
              <div className="flex flex-col">
                <SelectInput
                  value={timeZone}
                  items={moment.tz.names()}
                  setValue={(v) => setTimeZone(v)}
                />
              </div>
            </div>
          ) : (
            <div>
              <p className="mr-2 text-xl">{timeZone}</p>
              <span className="text-xs text-gray">Time zone</span>
            </div>
          )}
          <button
            className="underline text-gray hover:text-eastern-blue text-sm"
            onClick={() =>
              editArray["timeZone"]
                ? saveTimeZone()
                : toggleEditArray("timeZone")
            }
          >
            {editArray["timeZone"] ? "Save" : "Change"}
          </button>
        </div>
        {/* <div className="flex justify-between mb-5">
          <div>
            {!user.phone && (
              <>
                {!enterCode ? (
                  <div className="flex gap-2">
                    <Phone value={phone} setValue={(v) => setPhone(v)} />
                    <button
                      onClick={async () => updatePhone()}
                      className="bg-blue text-white text-md px-2 rounded w-full text-sm"
                    >
                      Add +
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2">
                    <input
                      value={code}
                      placeholder="Enter your code"
                      onChange={(e) => setCode(e.target.value)}
                      className="h-8 p-2 text-sm"
                    />
                    <button
                      onClick={async () => {
                        const { error, data } = await supabase.auth.verifyOtp({
                          phone,
                          token: code,
                          type: "phone_change",
                        });
                        if (!error) setUser({ ...user, phone });
                      }}
                      className="bg-blue text-white text-md p-1 rounded w-full"
                    >
                      Verify
                    </button>
                  </div>
                )}
              </>
            )}
            <p className="mr-2 text-xl">{user.phone}</p>
            <span className="text-xs text-gray">Phone number</span>
          </div>
        </div> */}
      </div>
      <p className="text-eastern-blue text-center text-xl mt-10">
        My Intention
      </p>
      <hr className="border-gray-light my-4" />
      <p className="text-xl">{user.intention}</p>
      <p className="text-eastern-blue text-center text-xl mt-10">My Benefits</p>
      <hr className="border-gray-light my-4" />
      <div className="">
        <p className="text-xl">{user.benefit1}</p>
        <p className="text-xl">{user.benefit2}</p>
        <p className="text-xl">{user.benefit3}</p>
      </div>
    </div>
  );
};

const Subscription = ({ user }: AccountProps) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { getUser, isActive } = useAuth();
  const cancelSubscription = async () => {
    setLoading(true);
    const { data, error } = await supabase.functions.invoke(
      "subscription-actions",
      {
        body: {
          reqMethod: "cancel",
          stripeCustomerId: user.customer?.stripe_customer_id,
          userId: user.id,
          subscriptionId: user.customer?.subscription_id,
        },
      }
    );
    setLoading(false);
    if (error)
      setError(
        "There's an issue cancel your subscription, please connect support"
      );
    setError("");
  };
  const trailEnd = moment
    .unix(Number(user.customer?.trial_end))
    .format("MM/DD/YYYY");
  const currentPeriodEnd = user.customer?.current_period_end;
  const nextBillingCycle = moment
    .unix(Number(currentPeriodEnd))
    .format("MM/DD/YYYY");

  const changeStatus = async (status: any) => {
    console.log(status);
    await getUser();
  };

  return (
    <div>
      <p className="text-eastern-blue text-center text-xl">Subscription</p>
      <hr className="border-gray-light my-4" />
      {!isActive ? (
        <>
          <p className="text-center mb-6 text-white bg-blue p-1 rounded">
            {moment(moment()).isAfter(trailEnd)
              ? `Your trail ended on ${trailEnd}`
              : `Your trail will end on ${trailEnd}`}
          </p>
          <NayaListOfBenefits />
          <div className="text-center m-auto">
            <CheckoutModal user={user} changeStatus={changeStatus} />
          </div>
        </>
      ) : (
        <>
          <p className="text-center mb-8">My Membership</p>
          <div>
            <div className="flex justify-between">
              <p className="mr-2 text-xl">
                {user.customer?.plan === "free" ? "Naya Free" : "Naya+"}
              </p>
              <span className="rounded-md bg-sky-light p-2 text-white text-xs">
                {user.customer?.status?.toUpperCase()}
              </span>
            </div>
            <span className="text-xs text-gray">Plan</span>
          </div>
          <hr className="border-gray-light" />
          <div className="mt-5">
            <div className="flex justify-between">
              <p className="mr-2 text-xl">{nextBillingCycle}</p>
            </div>
            <span className="text-xs text-gray">Next billing cycle</span>
          </div>
          <hr className="border-gray-light" />
          <div className="my-5">
            <div className="flex justify-between">
              <p className="mr-2 text-xl">
                {user.customer?.plan === "free"
                  ? "Free"
                  : `$${user.customer?.next_billing_amount}`}
              </p>
            </div>
            <span className="text-xs text-gray">Next billing amount</span>
          </div>
          {user.customer?.plan !== "free" && (
            <>
              <p className="text-center mb-4">Payment Method </p>
              <div className="w-full bg-amber rounded p-4 flex justify-center items-center">
                {user.customer?.brand === "visa" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    className="w-[25px] h-[25px]"
                  >
                    <path d="M470.1 231.3s7.6 37.2 9.3 45H446c3.3-8.9 16-43.5 16-43.5-.2.3 3.3-9.1 5.3-14.9l2.8 13.4zM576 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48zM152.5 331.2L215.7 176h-42.5l-39.3 106-4.3-21.5-14-71.4c-2.3-9.9-9.4-12.7-18.2-13.1H32.7l-.7 3.1c15.8 4 29.9 9.8 42.2 17.1l35.8 135h42.5zm94.4.2L272.1 176h-40.2l-25.1 155.4h40.1zm139.9-50.8c.2-17.7-10.6-31.2-33.7-42.3-14.1-7.1-22.7-11.9-22.7-19.2.2-6.6 7.3-13.4 23.1-13.4 13.1-.3 22.7 2.8 29.9 5.9l3.6 1.7 5.5-33.6c-7.9-3.1-20.5-6.6-36-6.6-39.7 0-67.6 21.2-67.8 51.4-.3 22.3 20 34.7 35.2 42.2 15.5 7.6 20.8 12.6 20.8 19.3-.2 10.4-12.6 15.2-24.1 15.2-16 0-24.6-2.5-37.7-8.3l-5.3-2.5-5.6 34.9c9.4 4.3 26.8 8.1 44.8 8.3 42.2.1 69.7-20.8 70-53zM528 331.4L495.6 176h-31.1c-9.6 0-16.9 2.8-21 12.9l-59.7 142.5H426s6.9-19.2 8.4-23.3H486c1.2 5.5 4.8 23.3 4.8 23.3H528z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 576 512"
                    className="w-[25px] h-[25px]"
                  >
                    <path d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z" />
                  </svg>
                )}

                <span className="text-md ml-2">
                  {user.customer?.brand} ending *** {user.customer?.last4}
                </span>
              </div>

              {error && <p className="text-center text-red pt-2">{error}</p>}
              <div className="text-center my-4">
                <ConfirmationModal
                  buttonText="Cancel my subscription"
                  title="Confirm cancellation"
                  text="Your Subscription will be cancelled at the end of your billing period"
                  actionText="Yes cancel my subscription"
                  buttonType="text"
                  handler={() => cancelSubscription()}
                  loading={loading}
                />
              </div>
            </>
          )}
          <div>
            {" "}
            {user.customer?.plan === "free" && (
              <div className="text-center m-auto">
                <p className="text-xl mb-2">Get Naya+</p>
                <CheckoutModal user={user} changeStatus={changeStatus} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const Delete = ({ user }: AccountProps) => {
  const { signOut } = useAuth();
  const deleteAccount = async () => {
    try {
      await supabase.functions.invoke("subscription-actions", {
        body: {
          reqMethod: "cancel",
          customerId: user.customer?.stripe_customer_id,
          userId: user.id,
          subscriptionId: user.customer?.subscription_id,
        },
      });
      const { error } = await supabase.from("users").delete().eq("id", user.id);
      window.analytics.track("deleted_data_web");
      if (error) throw error;
      signOut();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <p className="text-eastern-blue text-center text-xl mb-5">
        Delete your information?
      </p>
      <p>
        Your practice, your data.
        <br />
        Are you sure you want to delete all of your information?
        <br />I will have no way to restore this information.
      </p>
      <div className="flex justify-center gap-4 mt-8 text-center">
        <ConfirmationModal
          buttonText="Delete"
          title="Delete my account"
          text="Are you sure you want to delete your account"
          actionText="Yes"
          handler={() => deleteAccount()}
        />
      </div>
    </div>
  );
};

const Restart = ({ user }: AccountProps) => {
  return (
    <div>
      <p className="text-eastern-blue text-center text-xl mb-5">
        Restart your program?
      </p>
      <p>
        To support consistent results, we track <b>the same benefits</b>{" "}
        throughout your microdosing journey. <br />
        If you prefer to track something different, you will need to restart
        your program and erase all your current progress and data. <br />
        Do you want to continue?
      </p>
    </div>
  );
};

const Account = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();
  const [slectedItem, setSlectedItem] = useState<string | null>(null);
  const [isSelectProtocol, setIsSelectProtocol] = useState(false);
  useEffect(() => {
    if (window.location.hash === "#subscription") {
      setSlectedItem("subscription");
    }
  }, []);

  return (
    <>
      {user && (
        <div className="p-8">
          {slectedItem && !isSelectProtocol && (
            <button onClick={() => setSlectedItem(null)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="h-5 w-5"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
              </svg>
            </button>
          )}
          {!slectedItem && (
            <div>
              <p className="text-eastern-blue text-center text-xl mb-5">
                Account Settings
              </p>
              <hr className="border-gray-light my-4" />
              <div>
                <button
                  onClick={() => setSlectedItem("profile")}
                  className="flex w-full justify-between items-center py-2 text-left text-sm focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                >
                  <p className="text-xl ont">Profile</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="rotate-90 transform h-5 w-5 fill-sky-light"
                  >
                    <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                  </svg>
                </button>
                <hr className="border-gray-light my-4" />
              </div>
              <div>
                <button
                  onClick={() => setSlectedItem("practice")}
                  className="flex w-full justify-between items-center py-2 text-left text-sm focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                >
                  <p className="text-xl ont">My Practice</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="rotate-90 transform h-5 w-5 fill-sky-light"
                  >
                    <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                  </svg>
                </button>
                <hr className="border-gray-light my-4" />
              </div>
              <div>
                <button
                  onClick={() => {
                    setSlectedItem("subscription");
                    navigate("/journal/account#subscription");
                  }}
                  className="flex w-full justify-between items-center py-2 text-left text-sm focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                >
                  <p className="text-xl ont">Subscription</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="rotate-90 transform h-5 w-5 fill-sky-light"
                  >
                    <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                  </svg>
                </button>
                <hr className="border-gray-light my-4" />
              </div>
              <p className="text-eastern-blue text-center text-xl mt-10">
                Progress
              </p>
              <hr className="border-gray-light my-4" />
              <div>
                <button
                  onClick={() => setSlectedItem("restart")}
                  className="flex w-full justify-between items-center py-2 text-left text-sm focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                >
                  <p className="text-xl">Re-start my program </p>
                  <span className="text-[#66B9CF] text-xs">Naya+</span>
                </button>
                <hr className="border-gray-light my-4" />
              </div>
              <div>
                <button
                  onClick={() => setSlectedItem("delete")}
                  className="flex w-full text-xl justify-between items-center py-2 text-left focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                >
                  Delete my account
                </button>
                <hr className="border-gray-light my-4" />
              </div>
            </div>
          )}
          {slectedItem === "profile" && <Profile user={user} />}
          {slectedItem === "subscription" && <Subscription user={user} />}
          {slectedItem === "practice" && (
            <MyPractice
              setIsSelectProtocol={(selected) => setIsSelectProtocol(selected)}
            />
          )}
          {slectedItem === "restart" && <Restart user={user} />}
          {slectedItem === "delete" && <Delete user={user} />}
        </div>
      )}
    </>
  );
};

export default Account;
