import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import Logo from "../../assets/img/logo.png";
import PatooLogo from "../../assets/img/patoo/logo.png";
interface SideNavProps {
  tab?: string;
  setOpenTab: (tab: string) => void;
  isUserActive: boolean;
  userRef?: string;
  onBoardingStep?: string | null;
}

const Links = [
  { name: "Home", key: "" },
  { name: "Chat", key: "chat" },
  { name: "Journal", key: "entries" },
  // { name: "Practice", key: "my_practice" },
  // { name: "Journeys", key: "journeys" },
];

const SettingLinks = [
  { name: "Account", key: "account" },
  { name: "Contact Support", key: "support" },
  // { name: "Invite a friend", key: "invite" },
];
interface OnBoardingStepsProps {
  currentStep: string | null | undefined;
}
const OnBoardingSteps = ({ currentStep }: OnBoardingStepsProps) => {
  const steps = [
    { label: "Getting to know you", key: "onboarding" },
    { label: "Matching protocol", key: "finished_onboarding" },
    { label: "Start microdosing", key: "protocol_on_boarding" },
  ];
  const currentIndex = steps.findIndex((s) => s.key === currentStep);
  console.log(currentIndex, currentStep);
  return (
    <div className="flex flex-col gap-10">
      {steps.map((step, index) => (
        <div key={step.key} className="relative">
          {index > 0 && index < steps.length - 1 && (
            <div
              className={`absolute w-0.5 h-32 top-2 bg-gray transition-all duration-500 ${
                currentIndex <= index ? "bg-gray" : "bg-green"
              }`}
              style={{
                transform: `translate(-50%, -50%)`,
                left: "10px",
              }}
            />
          )}
          <div className="flex items-center space-x-3">
            <div
              className={`relative z-10 grid place-items-center w-5 h-5 rounded-full font-bold transition-all duration-300 bg-gray ${
                currentIndex > index || currentStep === step.key
                  ? "bg-green"
                  : " bg-gray text-white"
              }`}
            >
              {currentIndex > index && (
                <svg
                  className="fill-white w-3 h-3"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              )}
            </div>
            <div
              className={`${
                currentIndex > index || currentStep === step.key
                  ? " text-green"
                  : "text-gray"
              }`}
            >
              {step.label}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const SideNav = ({
  tab,
  setOpenTab,
  isUserActive,
  userRef,
  onBoardingStep,
}: SideNavProps) => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Parse URL and set active tab
  //   const pathname = window.location.pathname;
  //   const hash = window.location.hash;
  //   const linksObject = [...Links, ...SettingLinks].reduce((acc, link) => {
  //     acc[link.name] = link.key;
  //     return acc;
  //   }, {});
  //   linksObject[]
  // }, []);

  return (
    <div
      className={`${
        !isUserActive && "backdrop-blur-md"
      } bg-bone rounded-none md:rounded-2xl py-6 px-5 flex justify-between flex-col h-full`}
    >
      <div className="flex pb-6 justify-between items-center">
        <img className="w-[140px] md:w-1/2" src={Logo} alt="" />
        {userRef === "withpatoo" && (
          <img className="w-[120px] md:w-1/3 mr-2" src={PatooLogo} alt="" />
        )}
      </div>
      <div className=" flex justify-between flex-col h-full">
        {isUserActive ? (
          <div className="">
            <hr className="border-gray-light pb-6" />
            <ul className="px-2">
              {Links.map((l) => (
                <li
                  className="flex justify-between pb-7 items-center font-light"
                  key={l.key}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/journal/${l.key}`);
                      setOpenTab(l.key);
                    }}
                    className={`${tab === l.key && "!text-eastern-blue"} 
                ${!isUserActive && "!text-gray pointer-events-none"}
                  text-xl text-primary hover:text-eastern-blue`}
                  >
                    {l.name}
                  </a>
                </li>
              ))}
            </ul>
            <hr className="border-gray-light pb-5" />
            <ul className="px-2">
              {SettingLinks.map((l) => (
                <li
                  className="flex justify-between pb-7 font-light"
                  key={l.key}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/journal/${l.key}`);
                      setOpenTab(l.key);
                    }}
                    className={`${tab === l.key && "!text-eastern-blue"} 
                ${!isUserActive && "!text-gray pointer-events-none"}
                  text-xl text-primary hover:text-eastern-blue`}
                  >
                    {l.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <OnBoardingSteps currentStep={onBoardingStep} />
        )}
        <div className="text-center pt-24">
          <p>Have a great day 😊</p>
          <a
            href="#"
            className="text-md md:text-sm text-blue underline pointer"
            onClick={() => signOut()}
          >
            Sign Out
          </a>
        </div>
      </div>
    </div>
  );
};
