import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import HexagonLoading from "../components/UiElements/Loading/HexagonLoading";
import Logo from "../assets/img/logo-w.png";
import cloudRight from "../assets/img/cloud-right.png";
import cloudleft from "../assets/img/cloud-left.png";

export default function NayaChat() {
  const [isLoading, setIsLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const formLoaded = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <main className="bg-sky relative overflow-hidden min-h-screen mx-auto flex justify-center items-center flex-col gap-4">
        <nav>
          <AnimatePresence mode="wait">
            {showMessage && (
              <motion.p
                key="message"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ delay: 0.5, duration: 1.5, ease: "easeInOut" }}
                className="textt-center md:text-left font-serif bg-white p-2 rounded top-0 right-0 rounded-br rounded-tr-none md:rounded-r-none drop-shadow-md rounded-tl-none z-20"
              >
                Embark on a Transformative Journey with Naya's Microdosing
                Experience{" "}
                <a className="text-green font-bold" href="/auth">
                  Join today
                </a>
              </motion.p>
            )}
          </AnimatePresence>
        </nav>
        <img
          src={cloudRight}
          alt="Naya-cloud"
          className="absolute right-0 z-0 hidden md:block"
        />
        <img
          src={cloudleft}
          alt="Naya-cloud"
          className="absolute left-0 z-0  hidden md:block"
        />

        <div className="h-full z-10 relative justify-center items-center w-full p-2 md:p-0">
          <AnimatePresence mode="wait">
            {isLoading && (
              <motion.div
                key="loading"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex justify-center items-center min-h-screen"
              >
                <HexagonLoading loading={isLoading} />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence mode="wait">
            <motion.iframe
              key="form"
              src="https://formless.ai/c/zOK0UlpPPPfd"
              width="100%"
              style={{ border: 0 }}
              allow="microphone"
              onLoad={() => formLoaded()}
              className="rounded-2xl w-full md:w-[500px] mx-auto h-[600px]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          </AnimatePresence>
        </div>
        <div className="mt-2">
          <img src={Logo} alt="naya logo" className="w-2/3 mx-auto" />
        </div>
      </main>
    </>
  );
}
