import React, { useState } from "react";
interface HexagonLoadingProps {
  loading: boolean;
}

const HexagonLoading = ({ loading }: HexagonLoadingProps) => {
  if (!loading) <div />;
  return (
    <div className="hexagon" aria-label="hexagonal loading">
      <div className="hexagon__group">
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
      </div>
      <div className="hexagon__group">
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
      </div>
      <div className="hexagon__group">
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
      </div>
      <div className="hexagon__group">
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
      </div>
      <div className="hexagon__group">
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
      </div>
      <div className="hexagon__group">
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
        <div className="hexagon__sector"></div>
      </div>
    </div>
  );
};

export default HexagonLoading;
