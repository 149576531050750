const BenefitsKeyMap = {
  creativity: "creative",
  focus: "focused",
  growth: "growth",
  energy: "energy",
  sociability: "social",
  mindful: "mindful",
  depression: "depression",
  anxiety: "anxiety",
  ptsd: "ptsd",
  oneness: "oneness",
  dependencies: "dependency",
  nature: "nature",
};

export const getUserBenefitNameFromMap = (b: string) => {
  const benefitsNames = Object.keys(BenefitsKeyMap);
  const benefit = b
    .split(" ")
    .filter((word) => benefitsNames.includes(word))[0];
  return BenefitsKeyMap[benefit as keyof typeof BenefitsKeyMap];
};

interface UserBenefitsFromMap {
  benefit1: string | undefined;
  benefit2: string | undefined;
  benefit3: string | undefined;
}
export const getUserBenefitsFromMap = ({
  benefit1,
  benefit2,
  benefit3,
}: UserBenefitsFromMap) => {
  const benefitsNames = Object.keys(BenefitsKeyMap);
  const array = [];
  const benefit_one = benefit1
    ?.split(" ")
    .filter((word) => benefitsNames.includes(word))[0];
  benefit_one &&
    array.push(BenefitsKeyMap[benefit_one as keyof typeof BenefitsKeyMap]);
  const benefit_two = benefit2
    ?.split(" ")
    .filter((word) => benefitsNames.includes(word))[0];
  benefit_two &&
    array.push(BenefitsKeyMap[benefit_two as keyof typeof BenefitsKeyMap]);
  const benefit_three = benefit3
    ?.split(" ")
    .filter((word) => benefitsNames.includes(word))[0];
  benefit_three &&
    array.push(BenefitsKeyMap[benefit_three as keyof typeof BenefitsKeyMap]);
  return array;
};
