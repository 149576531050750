import React, { useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { motion, AnimatePresence } from "framer-motion";
import Recap from "../Recap";
import musroomsSmall from "../../../assets/img/musrooms-small.png";
import { UserState, StageTracking } from "../../../types";
type Days = { [key: string]: string };

interface MonthlyProps {
  protocol?: string;
  startingDate: string;
  daysType: Days;
  userState: UserState;
}

const Monthly = ({ daysType, startingDate, userState }: MonthlyProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDayState, setSelectedDayState] =
    useState<null | StageTracking>(null);
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1); // Current month (1-12)
  const [currentYear, setCurrentYear] = useState(moment().year());
  const year = new Date().getFullYear();

  const startingMonth = moment(startingDate).month() + 1; // Starting month (1-12)
  const isStartingMonth =
    currentMonth === startingMonth && currentYear === year;

  // function to check and grey out previous & next months visible dates
  const isExtraDays = (week: number, date: number) => {
    if (week === 0 && date > 10) {
      return true;
    } else if (week === 5 && date < 10) {
      return true;
    } else if (week === 4 && date < 10) {
      return true;
    } else {
      return false;
    }
  };

  // function to get all days by week
  const getDate = () => {
    var calendar = [];

    const startDate = moment([currentYear, currentMonth - 1])
      .startOf("month")
      .startOf("isoWeek"); // Start of the month, starting on Monday
    const endDate = moment([currentYear, currentMonth - 1])
      .endOf("month")
      .endOf("isoWeek"); // End of the month, ending on Sunday

    const day = startDate.clone().subtract(1, "day");

    // looping a month by a week
    while (day.isBefore(endDate, "day")) {
      calendar.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, "day").clone().format("YYYY-MM-DD"))
      );
    }
    if (calendar.length > 0) {
      return calendar.map((week, index) => (
        <div className="flex justify-between" key={index}>
          {week.map((day: string) => {
            const isMicrodosingDay = daysType[day];
            const isToday = moment(day).isSame(moment(), "day");
            return (
              <motion.div
                key={day + index}
                className={` ${
                  isToday && "bg-[#bddce2] px-1"
                } text-center py-2 rounded-[1.5rem] text-xs`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {isExtraDays(index, Number(moment(day).format("DD"))) ? (
                  <div
                    className="gap-2 flex flex-col cursor-pointer"
                    onClick={() => selectDay(day)}
                  >
                    {isMicrodosingDay ? (
                      <div className="relative">
                        <img
                          src={musroomsSmall}
                          alt="musrooms"
                          className="absolute -bottom-1 opacity-[0.4]"
                        />
                        <div className="bg-[#c5ebd2] w-[24px] h-[24px] rounded-full m-auto mb-1" />
                      </div>
                    ) : (
                      <div className="bg-gray-light w-[24px] h-[24px] rounded-full m-auto mb-1"></div>
                    )}

                    <span className="text-gray">
                      {moment(day).format("DD")}
                    </span>
                  </div>
                ) : (
                  <div
                    className="gap-2 flex flex-col cursor-pointer"
                    onClick={() => selectDay(day)}
                  >
                    {isMicrodosingDay ? (
                      <div className="relative">
                        <img
                          src={musroomsSmall}
                          alt="musrooms"
                          className="absolute -bottom-1"
                        />
                        <div className="bg-[#c5ebd2] w-[24px] h-[24px] rounded-full m-auto mb-1"></div>
                      </div>
                    ) : (
                      <div className="bg-gray w-[24px] h-[24px] rounded-full m-auto mb-1"></div>
                    )}
                    <span className="text-blue">
                      {moment(day).format("DD")}
                    </span>
                  </div>
                )}
              </motion.div>
            );
          })}
        </div>
      ));
    }
  };

  const goToNextMonth = () => {
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
    const nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;
    setCurrentMonth(nextMonth);
    setCurrentYear(nextYear);
  };

  const goToPreviousMonth = () => {
    if (!(currentMonth === startingMonth && currentYear === year)) {
      const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
      setCurrentMonth(previousMonth);
      setCurrentYear(previousYear);
    }
  };

  const goToCurrentMonth = () => {
    setCurrentMonth(moment().month() + 1);
    setCurrentYear(moment().year());
  };

  const selectDay = (day: string) => {
    const selected = { ...userState.stage_tracking[day] };
    setSelectedDayState({ ...selected, date: day });
    setIsDialogOpen(true);
  };

  return (
    <>
      <div className="text-center mb-4">
        <p className="text-lg font-medium">
          {moment()
            .month(currentMonth - 1)
            .format("MMMM")}{" "}
          {currentYear}
        </p>
      </div>
      <div className="text-center">
        <div className="flex justify-between text-sm text-center pb-3">
          <span>Mon</span>
          <span>Tue</span>
          <span>Wed</span>
          <span>Thu</span>
          <span>Fri</span>
          <span>Sat</span>
          <span>Sun</span>
        </div>
        <AnimatePresence mode="sync">{getDate()}</AnimatePresence>
      </div>
      <div className="flex justify-between py-3 items-center">
        <button onClick={goToPreviousMonth}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 448 512"
          >
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
          </svg>
        </button>
        <button
          className="text-xs bg-primary text-white px-2 py-1 rounded-xl"
          onClick={goToCurrentMonth}
        >
          Today Month
        </button>
        <button onClick={goToNextMonth}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 448 512"
          >
            <path d="M438.6 278.6c12.5-12.5-12.5-32.8-0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
          </svg>
        </button>
      </div>
      <Recap
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        selectedDayState={selectedDayState}
      />
    </>
  );
};

export default Monthly;
