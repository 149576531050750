import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";

import stickyImage from "../assets/img/top-sticky-bg.png";
import bgPattern from "../assets/img/bg-pattern.png";

export default function Terms() {
  return (
    <>
      <Navbar />
      <main className="bg-floral bg-right-top">
        <div className="absolute right-0 grid-cols-10 hidden md:grid px-8 md:px-0">
          <div className="col-span-4"></div>
          <img src={stickyImage} alt="" className="col-span-6 pt-24" />
        </div>
        <div className="px-8 md:px-0 relative pt-8 md:pt-32 pb-12 content-center items-center justify-center md:min-h-screen-75">
          <div className="container md:mt-[100px] mx-auto z-10">
            <div className="col-span-2 md:col-span-1">
              <h1 className="text-5xl md:leading-[106px] mt-2 md:mt-[28px] font-serif text-primary md:text-left">
                Terms & Conditions
              </h1>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="text-justify">
              <p>
                Please read on to learn the rules and restrictions that govern
                your use of the website(s), products, services and applications
                (the “Services”) designed, marketed and sold by the Company (as
                defined below).{" "}
              </p>
              <p>
                ANY USE OF AND/OR ACCESS TO THE SERVICES IS SUBJECT TO THE TERMS
                AND CONDITIONS SET FORTH HEREIN; IF YOU DO NOT AGREE TO ALL OF
                THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY
                MANNER.
              </p>
              <p>
                If you have any questions, comments, or concerns regarding these
                terms or the Services, please contact us at{" "}
                <a className="text-red" href="mailto: support@withnaya.com">
                  support@withnaya.com
                </a>
                .
              </p>
              <p className="text-2xl font-bold mt-6">Disclaimer</p>
              <p>
                MICRODOSING IS A PERSONAL CHOICE AND INDIVIDUAL EXPERIENCE, AND
                AYA DOES NOT PROVIDE MEDICAL ADVICE OR REPLACE THE ADVICE OF A
                HEALTHCARE PROFESSIONAL. AYA’S SERVICES ARE FOR INFORMATIONAL
                PURPOSES ONLY AND ARE NOT INTENDED TO DIAGNOSE, TREAT, OR CURE
                ANY DISEASE OR MEDICAL CONDITION. YOU SHOULD CONSULT A
                HEALTHCARE PROFESSIONAL BEFORE STARTING ANY NEW MEDICATION
                REGIMEN OR MAKING ANY CHANGES TO THEIR EXISTING MEDICATION OR
                TREATMENT OR BEFORE USING ANY OF THE SERVICES. THE SERVICES ARE
                NOT DESIGNED TO BE, AND SHOULD NOT BE, USED IN CONJUNCTION WITH
                ANY ILLEGAL DRUGS OR ACTIVITIES. AYA DOES NOT ENDORSE, ENCOURAGE
                OR PROMOTE THE USE OF ANY SUBSTANCES IN ANY JURISDICTION IN
                WHICH THEY ARE ILLEGAL. AYA DO NOT GUARANTEE THE ACCURACY OF THE
                INFORMATION PROVIDED ON ITS WEBSITE OR SERVICES AND YOU SHOULD
                USE YOUR OWN JUDGEMENT WHEN USING THE SERVICES. AYA WILL NOT
                ASSUMES LABILITY FOR ANY ADVERSE EFFECTS OR CONSEQUENCES
                RESULTING FROM THE USE OF THE SERVICES OR THE INFORMATION
                PROVIDED IN CONNECTION THEREWITH. YOU SHOULD ALWAYS FOLLOW THE
                LAW AND RESPECT THE RIGHTS OF OTHERS WHEN USING THE SERVICES.
              </p>
              <p>
                The Services have been designed to provide you with certain
                information about techniques that can be used to potentially
                enhance, improves, assist, measure and track the quality of your
                own volitional consciousness-expanding activities.
                Consciousness-expanding activities can be achieved through
                several techniques and activities, such as meditation,
                breathwork, sound as well as the use of legal or medically
                prescribed or controlled substances, medication, drugs, or
                natural supplements, including where legal, the use of
                substances which are commonly called psychedelics or entheogens.{" "}
              </p>
              <p>
                You should always seek the advice of a medical physician or
                other qualified mental healthcare provider before starting,
                stopping, or modifying any consciousness expansion activities,
                including substances commonly called psychedelics or entheogens.{" "}
              </p>
              <p>
                ENTHEOGENS, PSYCHEDELIC AND/OR CONSCIOUSNESS-EXPANDING
                TECHNIQUES CARRY INHERENT RISKS OF MENTAL AND PHYSICAL INJURY OR
                DEATH AND ARE NOT SUITABLE FOR EVERYONE.
              </p>
              <p>
                By using the Services, you hereby agree to assume all known and
                unknown risks associated with any activities you chose to
                participate in, even if caused in whole or in part by the
                Services. You expressly agree to release the Company, it’s
                members, directors, officers, shareholders, employees, advisors,
                contractors, service providers, partners, agents, and affiliates
                (the “Representatives”) from any and all liability, including
                without limitation, for physical, mental, personalized,
                emotional, cognitive, social, occupational or any other health
                issues or injuries that occur as a result of participation in
                activity related to or used in association with the Services. 
              </p>
              <p className="text-2xl font-bold mt-6">Terms and Conditions</p>
              <p>
                The Services are owned and operated by Naya Technologies Inc. A
                corporation incorporated under the laws of the State of Delaware
                (the <b>“Company”</b>, <b>“Naya”</b>,<b>“we”</b>, <b>“us”</b> or{" "}
                <b>“our”</b>).
              </p>
              <p>
                These terms of use (the “Terms”) are a binding contract between
                you and Naya Technologies Inc. The Terms describe both your
                rights and your obligations in connection with your use of the
                Services. It is important that you read and understand these
                Terms, as they are legally binding. By accessing or using the
                Services in any way, or by registering a user account with us,
                you agree to be bound by these Terms and you represent and
                approves that you have read and understood them.{" "}
              </p>
              <p>
                If you do not agree to the Terms, you may not use the Services
                in any way. These Terms will remain in effect while you use the
                Services. These Terms include the provisions in this document,
                as well as those in our privacy policy. Please note that some of
                the Services will not be available where you live.
              </p>
              <p>
                Please note that certain Services may require that you agree to
                additional terms prior to using them (“Additional Terms”). In
                that event, those Additional Terms will be considered to form
                and be a part of these Terms, and in case of any conflict
                between these Terms and any Additional Terms, the Additional
                Terms shall prevail with regard to the particular Service(s)
                they are associated with.
              </p>
              <p>
                These Terms apply to the use of the Services and any content
                available therein by any means (including smartphones, tablets,
                personal computers, other mobile devices etc.).
              </p>
              <p>
                The Use of the Services may be also subject to additional terms,
                conditions and policies (including any privacy policies) that
                govern the use of mobile devices, such as the terms governing
                the use of Apple devices, Android devices, the Apple’s “App
                Store”, Google’s Google Play store, any other application or
                marketplace or the terms governing the use of immediate
                messaging applications such as Telegram or Discord, or the terms
                governing the use of internet or telecommunications network
                providers. You bear the sole and exclusive responsibility for
                complying with any such additionally applicable terms,
                conditions and policies.
              </p>
              <p>
                Some of the features and functionality of the Services may
                requires an active plan with a mobile network provider with
                cellular data plan between your personal phone or mobile device
                and the communication service provider or to set a user in
                another platform, such as Telegram or Discord. You will not be
                able to access and use certain functionalities of the ServiceS
                without such a connection. You are solely responsible for all
                costs and expenses of such connection, as specified in your
                subscriber plan or contract with your communication service
                provider.
              </p>
              <p>
                Some of the features and functionality of the Services may
                require an online connection (Wi-Fi, cellular data, broadband)
                between your personal computer or mobile device and the
                Internet. You will not be able to access and use the Services
                without such a connection. You are solely responsible for all
                costs and expenses of such connection, as specified in your
                subscriber plan or contract with your communication service
                provider. Some of the features within the Services may be
                dependent on your wireless service and the wireless coverage
                within the area in which you are located at that time.
              </p>
              <p>
                When subscribing for the Services and opening a user account
                with us, you will be required to provide your name, email
                address and cellular phone number and in some cases, your user
                name or account name with a third party service provider (such
                as Telegram) and for some functionality of the Services, select
                a password and a user name (“User Identifying Credentials”). The
                Company will then send you messages using the internet, email,
                Telegram or your communication network provider and will adapt
                the content and timing of such messages based on your answers
                and preferences.{" "}
              </p>
              <p>
                You represent that you will provide us with accurate, complete,
                and updated registration information. You may not select as your
                user name a name that you don’t have the right to use, or
                another person’s name with the intent to impersonate that
                person. You may not transfer your account to anyone else without
                our prior written permission. We reserve the right to terminate
                an account if we find that you are younger than the minimum age
                specified below, or if you have violated these Terms.{" "}
              </p>
              <p className="text-2xl font-bold mt-6">About the Services </p>
              <p>
                Aya is a fully digital and automated assistant and journal
                designed to support you in your conscious-expanding activities
                via a Telegram (the “Telegram-enabled Product”) and the
                web-based application (“Web-enabled Product”). Aya is not, and
                is not intended as, a substitute for in-presence, qualified
                professional diagnosis, advice, guidance, or treatment by a
                medical professional. You should always seek the advice of a
                medical professional and qualified healthcare provider,
                therapist, or a physician for any medical, mental, psychological
                or health related issues. In any case of a diagnosed medical or
                mental condition, you should immediately stop using the Services
                until you consult your healthcare provider.
              </p>
              <p>
                The Services generally includes two parts: (1) gathering User
                Generated Content (as defined in our Privacy Policy) and (2)
                providing personalized reminders, summaries and journaling
                prompts based on the information gathered.{" "}
              </p>
              <p>
                Any and all information and/or materials provided through the
                Services or on the Company website are provided for
                informational purposes only and are subject to the{" "}
                <b>Warranty Disclaimer</b> set forth below.
              </p>
              <p className="text-2xl font-bold mt-6">WARNING</p>
              <p>
                THE SERVICES SHOULD NEVER BE USED AS A SUBSTITUTE FOR EMERGENCY
                CARE. IN CASE OF A MEDICAL EMERGENCY, CALL YOUR EMERGENCY
                DISPATCH CENTER IMMEDIATELY OR YOUR LOCAL SUICIDE PREVENTION
                LIFELINE FOR CONFIDENTIAL SUPPORT FOR PEOPLE IN DISTRESS.
              </p>
              <p>
                IF YOU ARE IN A LIFE-THREATENING SITUATION, FEEL THAT YOU ARE A
                DANGER TO YOURSELF OR OTHERS, OR ARE HAVING THOUGHTS OF SUICIDE,
                PLEASE CALL THE 24-HR NATIONAL SUICIDE PREVENTION LIFELINE AT
                <b>1.800.273.8255</b> AND NOTIFY THE RELEVANT AUTHORITIES.{" "}
              </p>
              <p>
                YOUR CALL WILL BE ROUTED TO THE CRISIS CENTER NEAR YOU. IF YOUR
                ISSUE IS AN EMERGENCY, CALL 911 IMMEDIATELY OR GO TO YOUR
                NEAREST EMERGENCY ROOM. YOU ACKNOWLEDGE AND UNDERSTAND THAT YOU
                SHOULD NEVER DELAY SEEKING TREATMENT FROM YOUR PRIMARY CARE
                PHYSICIAN OR OTHER QUALIFIED HEALTHCARE PROFESSIONAL.
              </p>
              <p>
                The Services are in no way published or declared by the Company
                or any of its Representatives as medical, therapeutic, or
                healthcare services in any jurisdiction. None of the Services
                provided by the Company or any of its Representatives are
                considered, nor shall be considered as medical or healthcare
                advice or services or constitute or form a physician-patient
                relationship between you and the Company or any of its
                Representatives. 
              </p>
              <p>
                You hereby acknowledge that you are using the services based on
                your own personal volition and free will and at your own risk.
                You further acknowledge that you were not solicited, encouraged
                or persuaded by anyone within the Company to use the Service.
                YOU AGREE THAT BEFORE USING THE SERVICES YOU WILL CONSULT A
                MEDICAL AND/OR MENTAL HEALTHCARE PROFESSIONAL IN THE
                JURISDICTION IN WHICH YOU RESIDE AND CHECK THE LEGAL STATUS OF
                ANY SUBSTANCES YOU PLAN ON WORKING WITH. 
              </p>
              <p className="text-2xl font-bold mt-6">Age restriction</p>
              <p>
                The Children’s Online Privacy Protection Act (“<b>COPPA</b>”)
                requires that online service providers obtain parental consent
                before they knowingly collect personally identifiable
                information online from children who are under 13.
                Notwithstanding the COPAA, you must be over 21 and in order to
                use the Services. We do not knowingly collect or solicit
                personally identifiable information from anyone under the age of
                21; if you are under 21, please do not attempt to register for
                the Services or send any personal information about yourself to
                us. If we learn we have collected personal information from
                anyone under 21, we will delete that information as quickly as
                possible upon learning as such. If you believe that anyone under
                21 may have provided us with personal information, please
                contact us immediately at{" "}
                <a className="text-red" href="mailto: support@withnaya.com">
                  support@withnaya.com
                </a>
                .
              </p>
              <p>
                We reserve the right to terminate an account if we find that you
                are younger than the minimum age specified above. We may request
                additional information to confirm your age at any time.
              </p>
              <p className="text-2xl font-bold mt-6">License</p>
              <p>
                Subject to these Terms, we hereby grant you a worldwide,
                limited, revocable, non-exclusive, personal, non-sub-licensable,
                non-transferable, non-assignable right and license, until the
                termination or expiration of these Terms or the termination of
                your User Account, to use the Services in accordance with these
                Terms, for personal and noncommercial use only, subject to the
                payment of the fees as described in the “Fees and Payments”
                section below.
              </p>
              <p className="text-2xl font-bold mt-6">Fees and payments</p>
              <p>
                The Services are subject to fees at the rates and schemes as
                posted when you register to the Service.{" "}
              </p>
              <p>
                We may change our fees and payment policies at any time by
                providing you a 14-days in advance notification. If you continue
                to use the Service following the lapse of the period specified
                in the notice it will be considered as your consent to such
                change.
              </p>
              <p>
                Service Fees can only be paid through the payment methods we
                establish from time to time. At this time, fees are charged and
                collected through a third party payment processing service
                (“Third Party Payment Methods”). We are not responsible for the
                terms, rules and practices of these Third-Party Payment Methods.
                They are subject to their own terms, which you should carefully
                read. We may also, at any time and in our sole discretion, offer
                free-of-charge features or change a free-of-charge feature to a
                fee-based feature, in which case we will inform you about it in
                advance as mentioned above.
              </p>
              <p>
                By providing the details of your chosen payment method you
                confirm and declare that you may use the chosen payment method
                for the purpose of paying for the Service. We may, from time to
                time and without notice to you, add the option to use additional
                payment methods or to discontinue the use of a previously used
                payment method. We may require additional information from you
                before completing a transaction involving payment. You undertake
                to keep your payment details updated, complete, and accurate and
                to notify us immediately in any case of a change in the details
                of the chosen payment method that you have provided to us. In
                the event of a delay in payment for the Services, we may stop
                providing the Service until your payment obligation is settled
                and the full consideration is paid.
              </p>
              <p>
                The fees are charged through the Third Party Payment Methods in
                the currency determined by them. In accordance with the rules of
                these Third-Party Payment Methods, you may be able to pay
                through a different currency, at your choice.
              </p>
              <p>
                Cancellation of a transaction for a time-based package, monthly
                by-monthly, yearly or other subscription to the Service will be
                in accordance with the provisions of any applicable law and
                regulations. In any case of conflict between the provisions of
                the applicable law and regulations and the cancellation policy
                described below, the provisions of the applicable law and
                regulations shall prevail.
              </p>
              <p>
                You may cancel your subscription at any time by sending a
                cancellation notice to{" "}
                <a className="text-red" href="mailto: support@withnaya.com">
                  support@withnaya.com
                </a>
                . Your subscription will be canceled at the end of the ordinary
                cycle for which you paid and will not be renewed to the next
                subscription cycle. You will not be entitled to any refund for
                the fees paid for the then current payment cycle.
              </p>
              <p>
                You are solely responsible for paying all applicable fees for
                the Services, whether or not you actually used, enjoyed, or
                otherwise benefited from them.
              </p>
              <p className="text-2xl font-bold mt-6">
                Your representations and warranties
              </p>
              <p>
                Before accessing or using the Services, you hereby agree,
                represents, warrants and acknowledges that:{" "}
              </p>
              <p>
                you have independently researched, understands and will be in
                compliance with the laws in your jurisdiction in respect to any
                substance, including entheogens, psychedelic and/or
                consciousness-expanding substances, whether natural or lab-made
                products, the risks of using such substances and products, and
                the potential interactions with other medications you are
                taking.{" "}
              </p>
              <p>
                you consulted a licensed or certified medical and/or mental
                healthcare professional prior to beginning use of or modifying
                any of the Services.
              </p>
              <p>you are over 21 years of age.</p>
              <p>you are individual of legal age to form a binding contract.</p>
              <p>
                You use the Services only for your own internal, personal,
                non-commercial use, and not on behalf of or for the benefit of
                any third party, and only in a manner that complies with all
                laws that apply to you. If the use of the Services is prohibited
                by laws applicable to you, then you are not authorized to use
                the Services. We can’t and won’t be responsible for any use of
                the Services in a way that results in a violation of the law.
              </p>
              <p>
                You will not share your User Identifying Credentials with
                anyone, and you must protect the security of your User
                Identifying Credentials. You’re solely responsible for
                maintaining the confidentiality of your User Identifying
                Credentials for restricting access to your devices, and you
                agree that you are solely responsible for any activity
                associated with your account.
              </p>
              <p>
                You represent, warrant, and agree that you will not contribute
                any Content or User Generated Content (each of those terms in
                our privacy policy) or otherwise use the Services or interact
                with the Services in a manner that:
              </p>
              <p>
                Infringes or violates the intellectual property rights or any
                other rights of anyone third party (including the Company) or
                removes any copyright or other proprietary notices on or in
                relation to the Content or any part thereof;
              </p>
              <p>
                Violates any local, provincial, state, national or international
                law or regulation, including any applicable export control laws
                or controlled substances laws, or violates any court order,
                arbitral order, or lawful order of any governmental authority;
              </p>
              <p>
                Is harmful, fraudulent, deceptive, threatening, harassing,
                defamatory, obscene, or otherwise objectionable;
              </p>
              <p>
                Jeopardizes the security of your account or anyone else’s (such
                as allowing someone else to log in to the Services as yo
              </p>
              <p>
                Attempts, in any manner, to disable or circumvent any access
                control or related process or procedure established with respect
                to the Services, or attempt to gain unauthorized access to any
                Services or obtain the password, account, or other security
                information from any other user;
              </p>
              <p>
                Violates the security of any computer network, or cracks any
                passwords or security encryption codes;
              </p>
              <p>
                Runs Maillist, Listserv, any form of auto-responder or “spam” on
                the Services, or any processes that run or are activated while
                you are not logged into the Services, or that otherwise
                interfere with the proper working of the Services (including by
                placing an unreasonable load on the Services’ infrastructure);
              </p>
              <p>
                “Crawls,” “scrapes,” “spiders,” mines or extracts any page,
                data, or portion of or relating to other users, the Services or
                Content (through use of manual or automated means);
              </p>
              <p>
                Copies or stores any significant portion of the Content, make
                Content available to anyone, permits anyone to access Content,
                or license, sublicense, sell, resell, publish, republish,
                transfer, assign, distribute, rent, lease, time-share, copy or
                otherwise commercially exploit the Services or Content in any
                way except in accordance with these Terms; 
              </p>
              <p>
                Would expose Company or any of our users to harm, or encourage
                any user of the Services to act in violation of these Terms; or 
              </p>
              <p>
                Decompiles, reverse engineers, tamper with or otherwise attempts
                to obtain the source code or underlying ideas or information of
                or relating to the Services.
              </p>
              <p>
                A violation of any of the foregoing is grounds for immediate
                termination without notice or any liability to the Company of
                your right to use or access the Services.
              </p>
              <p className="text-2xl font-bold mt-6">Confidentiality</p>
              <p>
                You agree to regard and retain as confidential and not divulge
                to any third party, or use for any unauthorized purposes, any
                Confidential Information, as defined below, that you acquire
                during your access and use of the Service, without the written
                approval consent of the Company. Without limiting the scope of
                this duty, you agree not to design or develop any products or
                services, which incorporate any Confidential Information. All
                Confidential Information remains the property of the Company and
                no license or other rights in the Confidential Information is
                granted hereby.
              </p>
              <p>
                “Confidential Information” shall include, but will not be
                limited to, information regarding research and development
                related to the Service, inventions, whether patentable or
                non-patentable, discoveries, innovations, designs, drawings,
                sketches, diagrams, formulas, computer files, computer programs,
                hardware, App screenshots, software, manuals, selection
                processes, data, planning processes, trade secrets, business
                secrets, business plans, copyrights, proprietary information,
                processes, formulae, know-how, improvements and techniques and
                any other data related to the Service and its users.
                Confidential Information will include information in written,
                oral and/or any other form of communication.
              </p>
              <p>
                We do not claim ownership over user submitted content that you
                share or otherwise make available through the service.
              </p>
              <p className="text-2xl font-bold mt-6">
                Intellectual Property Rights{" "}
              </p>
              <p>
                The materials displayed or performed or available on or through
                the Services, including, but not limited to, text, graphics,
                data, articles, photos, images, illustrations, User Generated
                Content, and so forth (all of the foregoing, the “Content”) are
                protected by copyright and/or other intellectual property laws.
                You agree to abide by all copyright notices, trademark rules,
                information, and restrictions contained in any Content you
                access through the Services, and you won’t use, copy, reproduce,
                modify, translate, publish, broadcast, transmit, distribute,
                perform, upload, display, license, sell or otherwise exploit for
                any purpose any Content not owned by you, (i) without the prior
                consent of the owner of that Content or (ii) in a way that
                violates someone else’s (including Company’s) rights.
              </p>
              <p>
                You understand that Company owns the Services. You won’t modify,
                publish, transmit, participate in the transfer or sale of,
                reproduce (except as expressly provided in this Section), create
                derivative works based on, or otherwise exploit any of the
                Services.
              </p>
              <p>
                The Services may allow you to copy or download certain Content,
                or share your information with others who are not using the
                Services. If you choose to share your information with third
                parties through social networks or any other third party
                platforms, then any activity or communications that you make
                with your contacts on such third party platforms and social
                networks are made strictly between you and such users/contacts.
                The Company is not a party and does not assume any
                responsibility or liability with respect to such communications
                and sharing. Your use of such third-party platforms is governed
                by their respective terms of service, not these Terms. You bear
                the sole and exclusive responsibility for complying with those
                other terms of service.{" "}
              </p>
              <p>
                Anything you post, upload, share, store, or otherwise provide
                through the Services is your “User Generated Content.” In its
                current form, no identifying personal information will be shared
                with third parties or other users. However, in the future,
                certain functionality may result in some of your User Generated
                Content to be viewable by other users. In order to display your
                User Generated Content on the Services, and to allow other users
                to enjoy them (where applicable), you grant us certain rights in
                those User Generated Content. Please note that all of the
                following licenses are subject to our Privacy Policy to the
                extent they relate to User Generated Content that are also your
                personally-identifiable information.{" "}
              </p>
              <p>
                For all User Generated Content, you hereby grant Company a
                license to translate, modify (for technical purposes, for
                example making sure your content is viewable on an iPhone as
                well as a computer) and reproduce and otherwise act with respect
                to such User Generated Content, in each case to enable us to
                operate the Services, as described in more detail below. You
                also provide your consent for the Company to use your personal
                information in an unidentified data or aggregated format in
                order to promote the Services and improve the products. We will
                never sell your personal information to any third party. This is
                a license only – your ownership in User Generated Content is not
                affected.
              </p>
              <p>
                If you store a User Generated Content in your own personal
                account in a manner that is not viewable by any other user
                except you (a “Personal User Content”) such as your web journal,
                you grant the Company the license above, as well as a license to
                display, perform, and distribute your Personal User Content for
                the sole purpose of making that Personal User Content accessible
                to you and providing the Services. You also provide your consent
                for the Company to use your Personal User Content in an
                unidentified format or in an aggregated format in order to
                improve and prompt the Services. We will never sell your
                Personal User Content to any third party. This is a license only
                – your ownership in User Generated Content is not affected.
              </p>
              <p>
                If you share a User Generated Content in a manner that only
                certain specified users can view (for example, a private message
                to one or more other users)(a “Limited Audience User Generated
                Content”), then you grant Company the licenses above, as well as
                a license to display, perform, and distribute your Limited
                Audience User Generated Content for the sole purpose of making
                that Limited Audience User Generated Content accessible to such
                other specified users, and providing the Services necessary to
                do so. Also, you grant such other specified users a license to
                access that Limited Audience User Generated Content, and to use
                and exercise all rights in it, as permitted by the functionality
                of the Services.
              </p>
              <p>
                If you share a User Generated Content publicly on the Services
                and/or in a manner that more than just you or certain specified
                users can view, or if you provide us (in a direct email or
                otherwise) with any feedback, suggestions, improvements,
                enhancements, and/or feature requests relating to the Services
                (each of the foregoing, a “Public User Generated Content”), then
                you grant Company the licenses above, as well as a license to
                display, perform, and distribute your Public User User Generated
                Content for the purpose of making that Public User Generated
                Content accessible to all Company users and providing the
                Services necessary to do so, as well as all other rights
                necessary to use and exercise all rights in that Public User
                Generated Content in connection with the Services and/or
                otherwise in connection with Company’s business. Also, you grant
                all other users of the Services a license to access that Public
                User Generated Content, and to use and exercise all rights in
                it, as permitted by the functionality of the Services.
              </p>
              <p>
                You agree that the licenses you grant are royalty-free,
                perpetual, sublicenseable, irrevocable, and worldwide.
              </p>
              <p>
                Finally, you understand and agree that Company, in performing
                the required technical steps to provide the Services to our
                users (including you), may need to make changes to your User
                Generated Content to conform and adapt those User Generated
                Content to the technical requirements of connection networks,
                devices, services, or media, and the foregoing licenses include
                the rights to do so.{" "}
              </p>
              <p>
                We respect others’ intellectual property rights, and we reserve
                the right to delete or disable Content alleged to be infringing,
                and to terminate the accounts of repeat alleged infringers. In
                accordance with the Digital Millennium Copyright Act (the
                “DMCA”), we’ve adopted the policy below toward copyright
                infringement.
              </p>
              <p>
                Procedure for Reporting Copyright Infringements.  If you believe
                that material or content residing on or accessible through the
                Services infringes your copyright (or the copyright of someone
                whom you are authorized to act on behalf of), please send a
                notice of copyright infringement containing the following
                information to the Company’s Designated Agent to Receive
                Notification of Claimed Infringement (our “Designated Agent,”
                whose contact details are listed below):
              </p>
              <p>
                A physical or electronic signature of a person authorized to act
                on behalf of the owner of the copyright that has been allegedly
                infringed;
              </p>
              <p>Identification of works or materials being infringed;</p>
              <p>
                Identification of the material that is claimed to be infringing
                including information regarding the location of the infringing
                materials that the copyright owner seeks to have removed, with
                sufficient detail so that Company is capable of finding and
                verifying its existence;
              </p>
              <p>
                Contact information about the notifier including address,
                telephone number and, if available, email address;
              </p>
              <p>
                A statement that the notifier has a good faith belief that the
                material identified in (1)(c) is not authorized by the copyright
                owner, its agent, or the law; and
              </p>
              <p>
                A statement made under penalty of perjury that the information
                provided is accurate and the notifying party is authorized to
                make the complaint on behalf of the copyright owner.
              </p>
              <p>
                Upon receipt of a proper notice of copyright infringement, we
                reserve the right to:
              </p>
              <p>remove or disable access to the infringing material;</p>
              <p>
                notify the content provider who is accused of infringement that
                we have removed or disabled access to the applicable material;
                and
              </p>
              <p>
                terminate such content provider’s access to the Services if he
                or she is a repeat offender. 
              </p>
              <p>
                Procedure to Supply a Counter-Notice to the Designated Agent. If
                the content provider believes that the material that was removed
                (or to which access was disabled) is not infringing, or the
                content provider believes that it has the right to post and use
                such material from the copyright owner, the copyright owner’s
                agent, or, pursuant to the law, the content provider may send us
                a counter-notice containing the following information to the
                Designated Agent:
              </p>
              <p>A physical or electronic signature of the content provider;</p>
              <p>
                Identification of the material that has been removed or to which
                access has been disabled and the location at which the material
                appeared before it was removed or disabled;
              </p>
              <p>
                A statement that the content provider has a good faith belief
                that the material was removed or disabled as a result of mistake
                or misidentification of the material; and
              </p>
              <p>
                Content provider’s name, address, telephone number, and, if
                available, email address, and a statement that such person or
                entity consents to the jurisdiction of the Federal Court for the
                judicial district in which the content provider’s address is
                located, or, if the content provider’s address is located
                outside the United States, for any judicial district in which
                Company is located, and that such person or entity will accept
                service of process from the person who provided notification of
                the alleged infringement.
              </p>
              <p>
                If a counter-notice is received by the Designated Agent, Company
                may, in its discretion, send a copy of the counter-notice to the
                original complaining party informing that person that Company
                may replace the removed material or cease disabling it in 10
                business days. Unless the copyright owner files an action
                seeking a court order against the content provider accused of
                committing infringement, the removed material may be replaced or
                access to it restored in 10 to 14 business days or more after
                receipt of the counter-notice, at Company’s discretion.
              </p>
              <p>
                Please contact Company’s Designated Agent at the following
                address:{" "}
                <a className="text-red" href="mailto: support@withnaya.com">
                  support@withnaya.com
                </a>
                .
              </p>
              <p className="text-2xl font-bold mt-6">Warranty Disclaimer</p>
              <p>
                Neither we, or our Representatives, successors, assignees,
                licensors or suppliers, make any representations or warranties
                concerning any Content contained in or accessed through the
                Services, and we will not be responsible or liable for the
                accuracy, copyright compliance, legality, or decency of material
                contained in or accessed through the Services. We make no
                representations or warranties, expressed or implied with respect
                to professional qualifications, expertise, quality of work,
                accuracy, truthfulness of the Content access through the
                Services and in no event shall we be liable to you for any
                decision made or action taken in reliance on any Content
                available through the Services. We, our Representatives, our
                successors, assignees, licensors and suppliers make no
                representations or warranties regarding suggestions or
                recommendations of the Services or any third party services or
                products offered or purchased through the Services.
              </p>
              <p>
                Products and services purchased or offered (whether or not
                following such recommendations and suggestions) through the
                Services are provided “AS IS” and without any warranty of any
                kind from Company or others (unless, with respect to such others
                only, provided expressly and unambiguously in writing by a
                designated third party for a specific product) and the Content
                is provided for informational purposes only. THE SERVICES AND
                CONTENT PROVIDED BY US ARE ON AN “AS-IS” BASIS, WITHOUT
                WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, AS TO THE
                OPERATION OF THE SERVICES OR THE ACCURACY INFORMATION, CONTENT,
                MATERIALS, OR PRODUCTS INCLUDING, WITHOUT LIMITATION, IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE
                UNINTERRUPTED OR ERROR-FREE. YOU EXPRESSLY AGREE THAT YOUR USE
                OF THE SERVICES IS AT YOUR SOLE RISK. SOME JURISDICTIONS DO NOT
                ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO SOME
                OR ALL OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. 
              </p>
              <p>
                You acknowledge that certain activities, including the use of
                herbal supplements, psychedelics, entheogens or legal
                prescription medications, involve risks, including risk of
                bodily injury or death, and you expressly acknowledge and agree
                that you solely assume those risks. You hereby acknowledge that
                you should consult a licensed, certified medical and/or mental
                healthcare professional prior to beginning or modifying any
                program that you undertake through the Services. The Services do
                not include any medical support, advice or care, medical
                assessments, psychotherapy, or any other type of professional
                service. Some portion of the Service may provide a reference to
                integration or support coach. You represent that you will
                independently research the quality of such third-party services
                and hereby release the Company from any and all liability
                related to such third party services. If you need non-emergency
                medical care or assistance, please contact a doctor or other
                medical professional. If you are seeking psychotherapy or any
                other professional mental health service, please contact a
                psychologist or other mental health professional. We make no
                representations and has no obligation with respect to (a) the
                appropriateness of your engaging in any activities related to
                the Services, including the practice microdosing; (b) the
                results (or lack of results) obtained by your use of the
                Services; and (c) any health-related matters arising in
                connection with your use the Services. The Services are NOT a
                substitution for medical or emergency services, if you think you
                or someone with you is experiencing a medical or psychiatric
                emergency, please call 911 or your local emergency number.{" "}
              </p>
              <p>
                None of the claims presented on the Services was validated by us
                and we do not have data to support any of the claims made
                available throughout the Services or on the website(s). We do
                not warrant that the Content is true and accurate, or that it
                was validated by us in any form, and we cannot guarantee that
                the Content will be free from non-accurate or misleading
                information, including the personal believes and opinions
                presented on the website, blog or social media accounts related
                to or accessed through the Services. You agree to use the
                Services at your own risk and that you evaluate and bear all
                risks associated with, the use of the Services, including
                without limitation any reliance on the accuracy, completeness,
                or usefulness of any content available through or in connection
                with the Services. We cannot control and have no duty to take
                any action regarding how you may interpret and use the Content
                or the Services or what actions you may take as a result of
                having been exposed to the Content. You hereby release us from
                all liability for you having taken any action as a result of
                being exposed to the Content or in connection with your use of
                the Services.
              </p>
              <p className="text-2xl font-bold mt-6">Third party links </p>
              <p>
                The Services may contain links or connections to third party
                websites or services that are not owned or controlled by us.
                When you access third party websites or use third party
                services, you accept that there are risks in doing so, and that
                we are not responsible for such risks. We encourage you to be
                aware when you leave the Services and to read the terms and
                conditions and privacy policy of each third party website or
                service that you visit or utilize. We do not have no control
                over, and assumes no responsibility for, the content, accuracy,
                privacy policies, or practices of or opinions expressed in any
                third-party websites or by any third party that you interact
                with through the Services. In addition, we will not and cannot
                monitor, verify, censor or edit the content of any third party
                site or service. By using the Services, you release and hold us
                harmless from any and all liability arising from your use of any
                third party website or service.
              </p>
              <p>
                Your interactions with organizations and/or individuals found on
                or through the Services, including payment and delivery of goods
                or services, and any other terms, conditions, warranties or
                representations associated with such dealings, are solely
                between you and such organizations and/or individuals. You
                should make whatever investigation you feel necessary or
                appropriate before proceeding with any online or offline
                transaction with any of these third parties. You agree that we
                will not be responsible or liable for any loss or damage of any
                sort incurred as the result of any such dealings.
              </p>
              <p>
                If there is a dispute between participants on this site, or
                between users and any third party, you agree that we are under
                no obligation to become involved. In the event that you have a
                dispute with one or more other users, you release the Company
                and its Representatives from claims, demands, and damages of
                every kind or nature, known or unknown, suspected or
                unsuspected, disclosed or undisclosed, arising out of or in any
                way related to such disputes and/or our Services.
              </p>
              <p>
                If you are a California resident, you shall, and hereby do,
                waive California Civil Code Section 1542, which says: “A general
                release does not extend to claims which the creditor does not
                know or suspect to exist in his or her favor at the time of
                executing the release, which, if known by him or her must have
                materially affected his or her settlement with the debtor.”
              </p>
              <p className="text-2xl font-bold mt-6">Limitation of Liability</p>
              <p>
                TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO
                CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT
                LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) WILL
                WE OR OUR REPRESENTATIVES (OR OUR LICENSORS OR SUPPLIERS) BE
                LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY DIRECT,
                INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL
                DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF
                GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER
                FAILURE OR MALFUNCTION, DAMAGE TO HARDWARE, SOFTWARE OR SYSTEMS,
                EVEN IF THE POTENTIAL FOR SUCH DAMAGE WAS PREVIOUSLY DISCLOSED
                OR REASONABLY FORESEEABLE IN THE CIRCUMSTANCES, OR (B) ANY
                AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100
                OR (II) THE AMOUNTS PAID BY YOU TO US IN CONNECTION WITH THE
                SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS
                APPLICABLE CLAIM, (C) ANY DEATH OR BODILY INJURY THAT YOU
                SUFFER, OR THAT YOU CAUSE TO ANY THIRD PARTY, IN CONNECTION WITH
                YOUR USE OF THE SERVICE OR ANY ACTIVITY YOU UNDERTAKE IN
                CONNECTION WITH YOUR USE OF THE SERVICE, OR (D) ANY MATTER
                BEYOND OUR REASONABLE CONTROL. SOME JURISDICTIONS DO NOT ALLOW
                THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL
                OF THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
              </p>
              <p className="text-2xl font-bold mt-6">Indemnity</p>
              <p>
                To the fullest extent allowed by applicable law, you agree to
                indemnify and hold us and our Representatives harmless from and
                against any and all claims, liabilities, damages (actual and
                consequential), losses and expenses (including attorneys’ fees)
                arising from or in any way related to any third party claims
                relating to:
              </p>
              <p>
                a) your use of the Services (including any actions taken by a
                third party using your account), and (b) your violation of these
                Terms. In the event of such a claim, suit, or action (“Claim”),
                we will attempt to provide notice of the Claim to the contact
                information we have for your account (provided that failure to
                deliver such notice shall not eliminate or reduce your
                indemnification obligations hereunder).
              </p>
              <p>
                You may not assign, delegate or transfer your Services account
                or your rights or obligations hereunder to anyone or your
                Services account, in any way (by operation of law or otherwise)
                without our prior written consent. We may transfer, assign, or
                delegate these Terms and our rights and obligations without
                consent.
              </p>
              <p className="text-2xl font-bold mt-6">
                Choice of Law; Arbitration
              </p>
              <p>
                These Terms are governed by and will be construed under the laws
                of the Province of Ontario, without regard to the conflicts of
                laws provisions thereof. Any dispute arising from or relating to
                the subject matter of these Terms shall be finally settled in
                Toronto, Ontario, in English, in accordance with the Streamlined
                Arbitration Rules and Procedures of Judicial Arbitration and
                Mediation Services, Inc. (“JAMS”) then in effect, by one
                commercial arbitrator with substantial experience in resolving
                intellectual property and commercial contract disputes, who
                shall be selected from the appropriate list of JAMS arbitrators
                in accordance with such Rules. Judgment upon the award rendered
                by such arbitrator may be entered in any court of competent
                jurisdiction. Notwithstanding the foregoing obligation to
                arbitrate disputes, each party shall have the right to pursue
                injunctive or other equitable relief at any time, from any court
                of competent jurisdiction. For all purposes of these Terms, the
                parties irrevocably consent to exclusive jurisdiction and venue
                in the courts located in the Province of Ontario. Any
                arbitration under these Terms will take place on an individual
                basis: class arbitrations and class actions are not permitted.
                YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU
                AND COMPANY ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO
                PARTICIPATE IN A CLASS ACTION.
              </p>
              <p className="text-2xl font-bold mt-6">Changes to the Terms</p>
              <p>
                We are constantly working on new products and services and
                trying to improve our Services. These Terms may be changed,
                modified or supplemented from time to time based on changes to
                the Services as well as regulatory changes. We reserve the right
                to change the Terms at any time, but if we do, we will bring it
                to your attention by placing a notice on on our website or
                within the application(s), by sending you an email, and/or by
                some other means. Revisions to these Terms will become effective
                on the date set forth in our notice. It is your responsibility
                to review the most current version of these Terms by visiting
                our website. 
              </p>
              <p>
                If you don’t agree with the new Terms, you are free to reject
                them; unfortunately, that means you will no longer be able to
                use the Services or visit our website. If you use the Services
                in any way after a change to the Terms is effective, that means
                you agree to all of the changes. Except for changes by us as
                described here, no other amendment or modification of these
                Terms will be effective unless in writing and electronically
                signed and acknowledged you.
              </p>
              <p>
                We take the privacy of our users very seriously. Our privacy
                policy is incorporated herein by reference.
              </p>
              <p>
                We’re always trying to improve the Services, so they may change
                over time. We may suspend or discontinue any part of the
                Services, or we may introduce new features or impose limits on
                certain features or restrict access to parts or all of the
                Services. We will make commercially reasonable efforts to
                provide you with notice if any material change to the Services
                would would adversely affect you. The Company further reserves
                the right to remove any Content from the Services at any time,
                for any reason (including, but not limited to, if someone
                alleges you contributed that Content in violation of these
                Terms), on its sole discretion, and without notice.
              </p>
              <p>
                Certain parts and features of the Services are free, but we may
                charge for some features of the Services and reserve the right
                to charge for certain additional features Services in the
                future. We will notify you before any Services you are then
                using begin carrying a fee, and if you wish to continue using
                such Services, you must pay all applicable fees for such
                Services. Before using any portions of the Service that are
                available for a fee, you will be required to select a payment
                plan and provide Company information regarding your credit card
                or other payment instrument. You represent and warrant to
                Company that such information is true and that you are
                authorized to use the payment instrument. You will promptly
                update your account information with any changes (for example, a
                change in your billing address or credit card expiration date)
                that may occur. You agree to pay the amount specified in your
                applicable payment plan. You hereby authorize us to bill your
                payment instrument in advance on a periodic basis in accordance
                with the terms of the applicable payment plan until you
                terminate your account, and you further agree to pay any charges
                so incurred. If you dispute any charges you must let us know
                within sixty (60) days after the date that Company charges you.
                Prices may change over time and the Company reserves the right
                to do so by providing you with s notice of the change on our
                site, in the Services, or in an email to you, at our option, at
                least 30 days before the change will take effect. Your continued
                use of the Services after the price change shall constitute your
                agreement to pay the changed prices. You shall be responsible
                for all taxes associated with the Services other than U.S. taxes
                based on Company’s net income.
              </p>
              <p className="text-2xl font-bold mt-6">
                User Account Suspension and Termination{" "}
              </p>
              <p>
                You could delete your account at any time by typing ‘DELETE’ on
                our Telegram-enabled Product, if available, the settings tab on
                your Web-enabled Product or by sending us an email to{" "}
                <a className="text-red" href="mailto: support@withnaya.com">
                  support@withnaya.com
                </a>
                . Please refer to our privacy policy, as well as the licenses
                above, to understand how we treat information you provide to us
                after you have stopped using our Services.
              </p>
              <p>
                We may terminate (or suspend access to) your license to use the
                Services or your account for any reason in our sole discretion,
                including if we believe that you breached of these Terms. We
                further reserve the right to decide whether you are in violation
                of any of the restrictions set forth in these Terms.
              </p>
              <p>
                Account deletion or termination may result in destruction and
                deletion of any or all of the Content associated with such
                account, so keep that in mind before you decide to terminate
                your account.
              </p>
              <p>
                Provisions that, by their nature, should survive termination of
                these Terms shall survive the termination of your account with
                us. By way of example, all of the following will survive
                termination: any obligation you have to pay us or indemnify us,
                any limitations on our liability, any terms regarding ownership
                or intellectual property rights, and terms regarding disputes
                between us.
              </p>
              <p className="text-2xl font-bold mt-6">
                Electronic Communications
              </p>
              <p>
                When you visit our website, interact with our applications, or
                send e-mails to us, you are communicating with us
                electronically. You consent to receive communications from us
                electronically for the purpose of providing the Services,
                including soliciting feedback or advising you of any new or
                Additional Services. You agree that all agreements, notices,
                disclosures, or other communications that we provide to you
                electronically satisfy any legal requirement that such
                communications be in writing. 
              </p>
              <p className="text-2xl font-bold mt-6">Miscellaneous</p>
              <p>
                Whenever used in these Terms, the term “Including”, whether
                capitalized or not, means without limitation to the preceding
                phrase. All examples and e.g. notations are illustrative, not
                exhaustive. These Terms constitute the entire agreement between
                you and us concerning the subject matter herein, and supersede
                all prior and contemporaneous negotiations and oral
                representations, agreements and statements. No waiver,
                concession, extension, representation, alteration, addition or
                derogation from the Terms by us, or pursuant to the Terms, will
                be effective unless consented to explicitly and executed in
                writing by our authorized representative. Failure on our part to
                demand performance of any provision in the Terms shall not
                constitute a waiver of any of our rights under the Terms. You
                may not assign or delegate these Terms or any of your rights,
                performances, duties or obligations hereunder. Any purported
                assignment or delegation, in contravention of the above, will be
                null and void. In the event of a Company merger, acquisition,
                change of control or the sale of all or substantially all of our
                equity or assets, we may, upon notice to you and without
                obtaining your consent, assign and delegate these Terms, in
                their entirety, including all of our rights, performances,
                duties, liabilities and obligations contained herein, to a third
                party. With such assignment, transfer and delegation, we are
                irrevocably and fully released from all rights, performance,
                duties, liabilities and obligations under these Terms. The
                section headings in the Terms are included for convenience only
                and shall take no part in the interpretation or construing of
                the Terms. The following sections shall survive any termination,
                or expiration of the Terms: User Account Suspension and
                Termination, Privacy, Intellectual Property, Limitation of
                Liability, Indemnity, Choice of Law; Arbitration, Miscellaneous
                Intellectual Property. If any provision of the Terms is held to
                be illegal, invalid, or unenforceable by a competent court, then
                the provision shall be performed and enforced to the maximum
                extent permitted by law to reflect as closely as possible, the
                original intention of that provision, and the remaining
                provisions of the Terms shall continue to remain in full force
                and effect. You will be responsible for paying, withholding,
                filing, and reporting all taxes, duties, and other governmental
                assessments associated with your activity in connection with the
                Services, provided that the we may, in our sole discretion, do
                any of the foregoing on your behalf or for itself as it sees
                fit. You hereby acknowledge and agree that you are not an
                employee, agent, partner, or joint venture of the Company, and
                you do not have any authority of any kind to bind us in any
                respect whatsoever. you agree there are no third party
                beneficiaries intended under these Terms.
              </p>
              <p></p>
              <p className="text-center text-2xl my-8">🍄 🍄 🍄</p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
