import React from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import bgPattern from "../assets/img/bg-pattern.png";

export default function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <main className="bg-floral bg-right-top">
        <div className="absolute right-0 grid-cols-10 hidden md:grid px-8 md:px-0">
          <div className="col-span-4"></div>
        </div>
        <div className="px-8 md:px-0 relative pt-8 md:pt-32 pb-12 content-center items-center justify-center md:min-h-screen-75">
          <div className="container md:mt-[100px] mx-auto z-10">
            <div className="grid grid-cols-2 gap-10">
              <div className="col-span-2 md:col-span-1">
                <h1 className="text-5xl md:leading-[106px] mt-2 md:mt-[28px] font-serif text-primary text-center md:text-left">
                  Privacy Policy
                </h1>
              </div>
            </div>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Last updated: January 1, 2023
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Welcome to Naya Technologies Inc.. Naya Technologies Inc. offers
              several services, including Naya Technologies Inc. personal helper
              &ndash; a fully digitized reminder and web-based journal designed
              to assist you with your microdosing practice (the &ldquo;
              <strong>Services</strong>&rdquo;). The Service is owned and
              operated by The Conscious Collective LLC (the &ldquo;
              <strong>Company</strong>&rdquo;, &ldquo;<strong>we</strong>
              &rdquo;, &ldquo;<strong>us</strong>&rdquo; and &ldquo;
              <strong>our</strong>&rdquo;). This Privacy Policy (the &ldquo;
              <strong>Policy</strong>&rdquo;) explains the privacy practices of
              our Services. It also describes the rights and options available
              to you with respect to your information.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>LEGAL DISCLAIMER</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Your privacy is very important to us. To protect your information,
              we have adopted this privacy policy (&ldquo;
              <strong>Privacy Policy</strong>&rdquo; or &ldquo;
              <strong>Policy</strong>&rdquo;). The Policy applies to all
              information you submit in connection with your access to and/or
              use of the Services. By visiting the website or using the Services
              or using any of the information or Services, as defined in the
              Terms of Service, you acknowledge that you have read, and agree to
              be bound by, the terms and conditions, representations,
              warranties, disclaimers and policies published on the website
              &mdash;including but not limited to the provisions of this Legal
              Disclaimer, the{" "}
              <a
                href="https://ayamicrodosing.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "#0000ff" }}>Terms of Use</span>
              </a>{" "}
              and Privacy Policy, all of which are expressly incorporated here
              by reference.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Any capitalized terms not defined in our Privacy Policy, have the
              meaning as specified in our Terms of Service accessible at{" "}
              <a
                href="https://ayamicrodosing.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "#0000ff" }}>Terms of Use</span>
              </a>
              .
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>DEFINITIONS</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Data Controller - </strong>Data Controller means a natural
              or legal person who (either alone or jointly or in common with
              other persons) determines the purposes for which and how any
              personal data is, or is to be, processed. For the purpose of this
              Privacy Policy, we are a Data Controller of your data.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Data Processors (or Service Providers)</strong> - Data
              Processor (or Service Provider) means any natural or legal person
              who processes the data on behalf of the Data Controller. We may
              use the services of various Service Providers in order to process
              your data more effectively.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Data Subject</strong> - Data Subject is any living
              individual who is the subject of Personal Data.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>User</strong> - The User is the individual using our
              Service. The User corresponds to the Data Subject, who is the
              subject of Personal Data.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>The Service</strong> - the means by which the Personal
              Data of the User is collected and processed.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>PERSONAL DATA WE COLLECT</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              While using our Services, we may ask you to provide us with
              certain personally identifiable information that can be used to
              contact or identify you (&ldquo;Personal Data&rdquo;).
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Personally-identifiable information may include but is not limited
              to: Email address, Name, Address, State, Province, ZIP/Postal
              code, City, Cookies, and Usage Data.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt-out of receiving any, or all,
              of these communications from us by following the unsubscribe link
              or instructions provided in any email we send.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Certain current (and possibly future) features and areas of the
              Services may require you to register or create an account with us,
              which may require the submission of additional information by you
              to us. Consequently, before you register, please read this Privacy
              Policy carefully, because the use of the Services, including the
              website, means that you understand and are consenting to the terms
              of the Privacy Policy (and other applicable terms and conditions).
              If you do not agree to abide by this Privacy Policy (and other
              applicable terms and conditions), then please do not use the
              Services. By using the Services, and submitting information to us,
              you are accepting the practices described in this Privacy Policy
              and other applicable terms and conditions.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              You always have the option to not provide information by choosing
              not to use the Services, or by not using a feature for which
              information is being collected.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Usage Data</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Regitration</strong>. We collect your name, telephone
              number and email when you first access our Services. You may start
              using our Services after you send us an message via Telegram and
              accept our{" "}
              <a
                href="https://ayamicrodosing.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "#0000ff" }}>Terms of Use</span>
              </a>{" "}
              and this Privacy Policy. Once approved, you are registered to the
              Services. We then have access to your name and phone number, which
              we obtain and save in order to provide you with the Services. You
              may also provide us with your preferred name or nickname for
              future communications. We refer to this data as &ldquo;
              <strong>Registration Information</strong>&rdquo;.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Financial</strong>. We are using third party providers to
              process payments . Such third-parties policies, including their
              privacy policy that refers to end users, as may be updated from
              time to time, is hereby incorporated herein by reference. We refer
              to this entire data as &ldquo;
              <strong>Financial Information</strong>&rdquo;.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Support</strong>. When you contact us for customer support
              requests, we will collect your contact details and the information
              included in your request and in any follow-up interactions between
              you and our team. We refer to this entire data as &ldquo;
              <strong>Support Information</strong>&rdquo;.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Content</strong>. We collect the content you provide us
              when you use Naya Technologies Inc.. We collect the content you
              upload when using Naya Technologies Inc. and your answers to its
              questions, which may include personal data and in certain
              jurisdictions may include data that is considered personal medical
              information. This information is provided by you and collected for
              us to personalize the Services and provide you with our
              recommendations. We refer to this entire data as &ldquo;
              <strong>Content Information</strong>&rdquo;.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              You do not have a legal duty to provide us with any of the above
              information. However, note that you will not be able to sign up to
              and use the Services, or contact us for support, without providing
              the above information, and we will not be able to provide you with
              the Services without collecting such information.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Analytics</strong>. We collect information relating to
              your use of the Services and your interaction with our website,
              email and/or messages. When you are using the Services, we will
              record and collect certain information in relation with your use
              of the Services and your interaction with our text and email
              messages, including your IP address (and the general location
              corresponding to the address). We refer to this entire information
              as &ldquo;<strong>Analytics Information</strong>&rdquo;.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Additional data we are collecting</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Personally-identifiable information (PII) </strong>&mdash;
              such as your name, email, or address&mdash;which we may use to
              contact you with newsletters, marketing, or promotional materials.
              You can always opt-out of receiving these communications by
              unsubscribing. If you&rsquo;re having trouble unsubscribing,
              contact us at support@withnaya.com (include the word
              &ldquo;Unsubscribe&rdquo; in your subject line). If you&rsquo;d
              like to access your PII, correct errors, or remove your PII from
              our systems, email us at support@withnaya.com.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Usage data</strong> (non-personally identifying
              information such as your IP address or domain name, country of
              origin, the mobile platform you use, your browser, the time you
              spend on the site, or the pages you visit). We may use this
              information to improve our site or provide it to third parties,
              including advertisers, on an aggregated and non-personally
              identifiable basis.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>Cookies - </strong>We may collect information through
              &ldquo;cookies&rdquo;, which are alphanumeric identifiers inserted
              and stored by your web browser, on your hard drive, or via mobile
              IDs. This may allow us to track and store information about you
              for purposes such as: recognizing when you return to the site,
              tailoring the site to your needs or preferences, and personalizing
              content so you enjoy your visit. Cookies can also be used to
              control the display of ads, track how you use the site, and record
              registration information. Some browsers allow you to prevent
              cookies from being downloaded on your computer; this may limit
              your ability to use certain portions of our site.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>HOW WE USE YOUR PERSONAL DATA</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We use the information provided by you, including your personal
              information and PII, in order to operate the Services and provide
              its features and functionality. We also use the information
              provided by you to prevent fraudulent use in the Services, improve
              the Services and Promote the Services.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We use your Registration Information to verify your identity and
              enable you to access the Services and to operate the Services and
              provide you with its features and functionality. We also use it to
              facilitate our internal managerial, archival, administrative and
              audit activities. Providing us with your Registration Information
              to help us prevent fraud and unlawful use of the Services.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We use your Registration and Support Information to provide you
              with customer support and handle your requests, questions or
              inquiries effectively.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We use Financial Information to process your payments and fees for
              the use of the Services.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We use your Content Information to enable you to use the Services
              features and functionality. We also use the Content Information to
              analyze your answers and determine personalization of our
              recommendations as well as for general product development.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We use Content and Analytics Information to:
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              1.train, develop and enhance the Services. We also use such
              information for general business administration and marketing
              purposes as well as for understanding how users interact with the
              Services so that we can further train, improve, develop and
              enhance it.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              2.adjust the Services and our communications to your preferences.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              3.understand the market in which we operate in;
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              4.for managerial reporting and business planning; and for
              marketing purposes such as publishing study reports for investors
              and other third parties.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              When we use your Content and Analytics Information to train and
              improve the Service we will do so only after removing all of
              personal information that directly identifies you.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              When we process Content and Analytics Information for the purpose
              of marketing, we will use only aggregated de-identified
              information and we will not be able to directly identify the
              person who uses the Services.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We may also use your information to:
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              1.enforce this privacy policy
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              2.contact you or respond to you inquiries
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              3.process transactions
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              4.maintain your account
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              5.notify you about changes to our services
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              6.customize content
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              7.provide customer support
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              8.maintain the security and integrity of our site
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              9.conduct research or surveys
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              10.&nbsp; improve our site
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              11.&nbsp; monitor how our services are used
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              12.&nbsp; share information that may interest you
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              13.&nbsp; troubleshoot problems and address technical issues
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              14.&nbsp; detect and protect against error or fraud
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              15.&nbsp; enable third parties to provide services, such as
              product billing
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              16.&nbsp; respond to legal requests from government authorities
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>WHEN AND HOW WE SHARE PERSONAL DATA WITH OTHERS</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              WE DO{" "}
              <span style={{ color: "#c00000" }}>
                <strong>NOT</strong>
              </span>
              SELL YOUR PERSONAL INFORMATION TO THIRD PARTIES.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              WE WILL{" "}
              <span style={{ color: "#c00000" }}>
                <strong>NOT</strong>
              </span>
              SHARE YOUR INFORMATION WITH ANY THIRD PARTIES, EXCEPT IN THE
              EVENTS LISTED BELOW OR WHEN YOU PROVIDE US YOUR EXPLICIT AND
              INFORMED CONSENT:
            </p>
            <ol>
              <li>
                We may share your information with service providers that we use
                to operate the Service and our business, including consultants
                and third party software providers (such as analytics, user
                registration, database management, ecommerce, payment
                processing). These third parties will only have access to your
                personal data to perform these specific tasks. If such a
                platform service works through geographically distributed
                servers, it may be difficult to determine the actual location
                where your personal information is stored. There are a variety
                of third-party systems we may use to offer services, process
                data, track site activity, send out messages, conduct analytics,
                process payments, optimize traffic, display third party content,
                or protect against SPAM. These may include data processing
                solutions, database management systems, analytics services,
                payment processors, content distributors, and SPAM protection
                services. Each of these organizations will have their own
                privacy policies. For a list of the services we use and a link
                to their privacy policies, see our long-form privacy policy.
              </li>
              <li>
                We may process your information by using services rendered to us
                by third party service providers in which we use to run the
                internal operations of the Services and our business. These
                companies are authorized to use your personal information only
                as necessary to provide such services to us and{" "}
                <span style={{ color: "#c00000" }}>not </span>for their own
                commercial or promotional purposes.
              </li>
              <li>
                We will share aggregated de-identified information with
                investors and other third parties for marketing purposes.
              </li>
              <li>
                We will only share your information with investors and other
                third parties by publishing reports containing aggregated
                de-identified statistics of users&rsquo; use of the Services.
                Such reports will not directly identify you.
              </li>
              <li>
                We will share your information if we are legally required to do
                so and with any relevant authority if we are obligated to so by
                law.
              </li>
              <li>
                We will share your information if necessary to defend our
                Company from any legal claims.
              </li>
              <li>
                We may share your information with the relevant authorities and
                with third parties (such as legal counsels and advisors), if
                necessary to defend our Company from legal claims or if you have
                breached the Service&rsquo;s{" "}
                <a
                  href="https://ayamicrodosing.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span style={{ color: "#0000ff" }}>Terms of Use</span>
                </a>
                , this Policy or any other agreement you have with us, for the
                purpose of handling of the violation or breach.
              </li>
              <li>
                We will share your information if the operation of the Service
                is organized within a different framework or through another
                legal structure or entity (such as due to a merger or
                acquisition), provided that those entities agree to be bound by
                the provisions of this Policy, with reasonable necessary changes
                taken into consideration.
              </li>
              <li>
                We will share your information in case of an emergency
                concerning you.
              </li>
              <li>
                If we need to act immediately to protect the personal safety and
                health of our customers or the public.
              </li>
            </ol>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>WHEN AND HOW WE SHARE PERSONAL DATA WITH OTHERS</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We may store and process your personal information outside your
              territory.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The Service, by its nature as an online Service, may store and
              process information and content in various locations throughout
              the globe, including through cloud services. If you are a resident
              in a jurisdiction where transfer of your personal information to
              another jurisdiction requires your consent, then by subscribing to
              the service and approving this policy, you hereby provide your
              express and unambiguous consent to such cross-border transfer. If
              you are an EU resident and we transfer your personal information
              to a jurisdiction out of the EU, we will do so by using adequate
              safeguards determined by the GDPR.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>SECURITY AND DATA RETENTION</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We retain your personal information for:
            </p>
            <ol>
              <li>
                as long as we need it to operate the Service and interact with
                users, and thereafter as needed for record-keeping matters; and
              </li>
            </ol>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              the duration needed to support our ordinary business activities
              operating the Service and interacting with users.
            </p>
            <ol>
              <li>
                Thereafter, we will continue to retain your personal information
                as necessary to comply <strong>with</strong> our legal
                obligations, resolve disputes, establish and defend legal claims
                and enforce our{" "}
                <a
                  href="https://sleep.docbotic.care/terms.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
                , this Policy and other agreements we have with our users.
              </li>
            </ol>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              We implement security measures to secure your Information and to
              reduce the risks of damage, loss of information and unauthorized
              access or use of your personal information. These measures do not
              provide absolute information security. Therefore, although efforts
              are made to secure your personal information, it is not
              guaranteed, and you cannot expect that the Service will be immune
              from information security risks.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>SECURITY AND DATA RETENTION</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The Company is the data controller for the purposes of the
              personal data we collect via the Service, in relation to the
              Service and for the performance of the Service. We retain your
              personal data for as long as we need it to perform the purpose it
              was collected for, or for a longer period if required by law. Once
              the retention period expires, all personal data is deleted.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The Company is incorporated in the State of Delaware (United
              States).
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Our registered address is:
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              LegalInc 651 N. BROAD ST. SUITE 201 MIDDLETOWN, DE 19709
            </p>
            <p>Attn: Naya Technologies Inc.</p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Although we take security seriously and upgrade our security
              technology as required, no method of transmission or storage is
              100% secure, so we can&rsquo;t guarantee your data&rsquo;s
              absolute security. If you ever discover inaccuracies in our data
              or your PII, or become aware of your information or PII being made
              available online without authorization, we urge you to notify us
              immediately.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>LEGAL BASIS FOR PROCESSING UNDER THE GDPR</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The legal basis for collecting and processing Registration,
              Financial, Content, and Support and Analytics Information is our{" "}
              <a
                href="https://ayamicrodosing.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "#0000ff" }}>Terms of Use</span>
              </a>{" "}
              agreement with you.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The legal basis for collecting and processing Financial
              Information and Content Information for the purpose of processing
              payments and fees for the use of the Services is our legal
              interests in receiving payments for our services.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The legal basis for collecting and processing Analytics
              Information and Content Information for the purpose of training
              and improving the Services is our legal interests in developing,
              improving and enhancing the Services, its features and
              functionality.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The legal basis for collecting and processing Analytics
              Information and Content Information for the purpose of publishing
              experimental reports is our legitimate interests in developing and
              promoting our business.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The legal basis for processing your information for the purpose of
              handling instances of abusive use of the Services is our
              legitimate interests in defending and enforcing against violations
              and breaches of our{" "}
              <a
                href="https://sleep.docbotic.care/terms.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>{" "}
              as well as other breaches that are harmful to the Services or our
              our business.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The legal basis for processing your information where we are
              legally required to share it, is our legitimate interests in
              complying with mandatory legal requirements imposed on us.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The legal basis for processing your information in the event of a
              change in our corporate structure is our legitimate interests in
              our business continuity.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              You have the right to access, update or delete your personal
              information, obtain a copy of your personal information, withdraw
              your consent and object or restrict certain data processing
              activities.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              If you are an individual in the EU you have the following rights
              under the GDPR, subject to the limitations that the GDPR attaches
              to these rights:
            </p>
            <ol>
              <li>
                Right to Access your personal information that we process and
                receive a copy of it.
              </li>
              <li>
                Right to Rectify inaccurate personal information we have
                concerning you and to have incomplete personal information
                completed.
              </li>
              <li>
                Right to Data Portability, that is, to receive the personal
                information that you provided to us, in a structured, commonly
                used and machine-readable format. You have the right to transmit
                this data to another service provider. Where technically
                feasible, you have the right that your personal information be
                transmitted directly from us to the service provider you
                designate.
              </li>
              <li>
                Right to Object, based on your particular situation, to using
                your personal information on the basis of our legitimate
                interest. However, we may override the objection if we
                demonstrate compelling legitimate grounds, or for the
                establishment, exercise or defense of legal claims. You may also
                object at any time to the use of your personal information for
                direct marketing purposes.
              </li>
              <li>
                Right to Restrict the processing of your personal information
                (except for storing it) if you contest its accuracy, for a
                period enabling us to verify its accuracy; if you believe that
                the processing is unlawful and you opposes the erasure of the
                personal information and requests instead to restrict its use;
                if we no longer need the personal information for the purposes
                outlined in this Policy, but you require it to establish,
                exercise or defense relating to legal claims, or if you object
                to processing, pending the verification whether our legitimate
                grounds for processing override yours.
              </li>
              <li>
                Right to be Forgotten. Under certain circumstances, such as when
                the personal information is no longer necessary for the purposes
                for which they were processed, you have the right to ask us to
                erase your personal information. However, we may still process
                your personal information if it is necessary to comply with a
                legal obligation which we are subject to under laws in the EU
                Member State, or for the establishment, exercise or defense of
                legal claims.
              </li>
            </ol>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              If the legal basis for processing your personal information is
              your consent, you may Withdraw Your Consent at any time. If you do
              that, we will still process certain information on a legal basis
              other than the personal information in relation to which you
              withdraw your consent, as described in the Policy. Withdrawing
              your consent will not affect the lawfulness of data processing we
              carried out based on your consent before such withdrawal.If you
              wish to exercise any of the abovementioned rights, contact us at{" "}
              <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                support@withnaya.com
              </span>
              . We reserve the right to ask for reasonable evidence to verify
              your identity before we provide you with information. Where we are
              not able to provide you with information that you have asked for,
              we will explain the reason for this.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              You have a right to submit a complaint to the relevant supervisory
              data protection authority.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Subject to applicable law, you have the right to lodge a complaint
              with your local data protection authority. If you are in the EU,
              then according to Article 77 of the GDPR, you can lodge a
              complaint to the supervisory authority, in particular in the
              Member State of our residence, place of work or of an alleged
              infringement of the GDPR. For a list of supervisory authorities in
              the EU,{" "}
              <a
                href="http://ec.europa.eu/newsroom/article29/document.cfm?action=display&amp;doc_id=50061"
                target="_blank"
                rel="noopener noreferrer"
              >
                click here
              </a>
              .
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>MINORS</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              IF YOU ARE UNDER THE AGE OF 21 YOU MAY NOT USE THE SERVICE.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              You must be at least 21 years of age to use the Service. The
              Service is not intended for minors under the age of 21. We do not
              knowingly or intentionally collect information about minors under
              the age of 21.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              The Children&rsquo;s Online Privacy Protection Act
              (&ldquo;COPPA&rdquo;) requires that online service providers
              obtain parental consent before they knowingly collect personally
              identifiable information online from children who are under 13. We
              do not knowingly collect or solicit personally identifiable
              information from children under 13 if you are a child under 13,
              please do not attempt to register for the Services or send any
              personal information about yourself to us. If we learn we have
              collected personal information from a child under 13, we will
              delete that information as quickly as possible. If you believe
              that a child under 13 may have provided us personal information,
              please contact us at{" "}
              <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                support@withnaya.com
              </span>
              .
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>CHANGES TO THIS PRIVACY POLICY</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              If we change this Policy, we will provide you with notice of such
              change.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              From time to time, we may change this Policy, in which case we
              will inform you of the updated Policy through the Service&rsquo;s
              interface or via the email address you provided us. The latest
              version of the Policy will always be accessible through the
              Service.
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              <strong>CONTACT US</strong>
            </p>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              If you have any inquiries, complaints, requests or suggestions,
              you may contact us at{" "}
              <span style={{ color: "#0000ff", textDecoration: "underline" }}>
                support@withnaya.com
              </span>
              . We will do our best to resolve your issue in a timely manner.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
