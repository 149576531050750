const { REACT_APP_NAYA_API_URL, REACT_APP_VAPID_PUBLIC_KEY } = process.env;
console.log(REACT_APP_NAYA_API_URL);
export function subscribeUser(user: any) {
  if ("serviceWorker" in navigator && "PushManager" in window) {
    navigator.serviceWorker.ready.then(async (registration) => {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: REACT_APP_VAPID_PUBLIC_KEY,
      });

      // Send the subscription data to the backend
      await fetch(`${REACT_APP_NAYA_API_URL}/api/subscribe`, {
        method: "POST",
        body: JSON.stringify({ subscription, user }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const options = {
        body: "You will now be able to receive push notifications.",
        icon: "/naya-icon-192x192.png",
        vibrate: [100, 50, 100],
        data: {
          dateOfArrival: Date.now(),
          primaryKey: 1,
          linkUrl: "https://withnaya.com/journal",
        },
      };
      // Show a notification to the user
      await registration.showNotification("Naya", options);
    });
  }
}
