import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { StripeElements } from "@stripe/stripe-js";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { useDebouncedCallback } from "use-debounce";
import { useAuth } from "../../contexts/AuthProvider";
import Loading from "../UiElements/Loading";
import { supabase } from "../../lib/supabaseClient";
const { REACT_APP_CLIENT_URL } = process.env;
interface CheckoutFormInterface {
  name: string;
  email: string;
  user_id: string;
  plan: string;
  phone: string;
  stripeCustomerId: string;
  changeStatus: (s: any) => any;
}

type StripeError = {
  code: string;
  raw: {
    message: string;
  };
};

export default function CheckoutForm({
  name,
  user_id,
  email,
  plan,
  changeStatus,
  phone,
  stripeCustomerId,
}: CheckoutFormInterface) {
  // stripe items
  const stripe = useStripe();
  const elements = useElements() as StripeElements | any;
  const { isCanceled } = useAuth();
  const [promotionCode, setPromotionCode] = useState("");
  const [promotionCodePrice, setPromotionCodePrice] = useState<null | string>(
    null
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const subscribe = async (event: any) => {
    event.preventDefault();
    if (!stripe) return;
    setIsLoading(true);
    setErrorMsg("");

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setIsLoading(false);
      setErrorMsg(submitError.message);
      return;
    }
    const { error: paymentMethodError, paymentMethod } =
      await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name,
          },
        },
      });
    if (paymentMethodError) throw paymentMethodError;

    try {
      let resData;
      if (isCanceled) {
        console.log("Here");
        const { data, error: functionError } = await supabase.functions.invoke(
          "subscription-actions",
          {
            body: {
              userId: user_id,
              stripeCustomerId,
              promotionCode,
              paymentMethodId: paymentMethod?.id,
              reqMethod: "createAndSubscribe",
            },
          }
        );
        if (functionError) throw functionError;
        resData = data;
      } else {
        const { data, error: functionError } = await supabase.functions.invoke(
          "subscription-actions",
          {
            body: {
              name,
              email,
              plan,
              userId: user_id,
              stripeCustomerId,
              promotionCode,
              phone,
              paymentMethodId: paymentMethod?.id,
              env: process.env.NODE_ENV,
              userAgent: window.navigator.userAgent,
              reqMethod: "subscribe",
            },
          }
        );
        if (functionError) throw functionError;
        resData = data;
      }
      window.analytics.identify(email, {
        subscription: {
          type: plan,
          price: "$9/month, billed monthly",
        },
      });

      const resServerResponse = await handleServerResponse(resData);
      setIsLoading(false);
      changeStatus(resData);
      window.analytics.track("completed_checkout");
    } catch (err: any) {
      console.log(err);
      if (err.context) {
        const e = await err.context.json();
        setErrorMsg(e.error);
      } else if (err.message) {
        setErrorMsg(err.message);
      } else if (err) {
        setErrorMsg(err);
      } else {
        setErrorMsg(
          "There is an error please check the data you entered or try again"
        );
      }
      setIsLoading(false);
    }
  };

  const handleServerResponse = async (response: any) => {
    if (response?.error) {
      throw response.error;
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required next action
      const result = await stripe?.handleNextAction({
        clientSecret: response.clientSecret,
      });

      if (result?.error) {
        throw result?.error.message ?? "";
      }
    } else {
      return true;
    }
    return true;
  };

  const debounced = useDebouncedCallback(async () => {
    setErrorMsg("");
    setPromotionCodePrice(null);
    setIsLoading(true);
    const { data, error } = await supabase.functions.invoke(
      "subscription-actions",
      {
        body: {
          promotionCode,
          method: "check_promotion_code",
        },
      }
    );
    setIsLoading(false);
    if (!data) return setErrorMsg("Coupon code is not valid");
    const newPrice = (9.99 - (9.99 * data.percent_off) / 100).toFixed(2);
    setPromotionCodePrice(newPrice);
  }, 1200);

  return (
    <>
      <form onSubmit={subscribe}>
        <p className="text-sm pb-2">Email</p>
        <input
          type="text"
          defaultValue={email}
          readOnly
          className="w-full md:w-2/3 border-0 px-4 py-3 md:py-[10px] text-[18px] rounded-r-none placeholder-blueGray-300 bg-white rounded-sm"
        />
        <div className="my-2 md:my-3 min-h-[100px]">
          <PaymentElement onReady={() => setIsLoading(false)} />
        </div>
        <div className="mb-3">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg">
                  <span className="text-sm text-gray">Have a coupon code?</span>
                </Disclosure.Button>
                <Disclosure.Panel className="pt-2 pb-2 text-sm text-gray-500">
                  <input
                    type="text"
                    placeholder="Type your code"
                    className="h-8 p-2 text-sm"
                    value={promotionCode}
                    onChange={(v) => {
                      setPromotionCode(v.target.value);
                      debounced();
                    }}
                  />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          {promotionCodePrice && (
            <div className="flex justify-between items-center">
              <span className="text-green text-sm">
                Success! Your coupon worked!
              </span>
              <span>
                Next billing after trial ends: <b>{promotionCodePrice}</b>
              </span>
            </div>
          )}
        </div>
        <div className="text-center pt-1 md:pt-4">
          <p className="text-red my-3">{errorMsg}</p>
          <button
            disabled={!stripe && isLoading}
            className={`${
              isLoading && "opacity-50 cursor-not-allowed"
            } w-1/3 bg-primary text-white rounded-md md:text-[18px] py-[8px] m-auto`}
          >
            Subscribe
          </button>
        </div>
      </form>
    </>
  );
}
