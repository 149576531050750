import { useEffect, useRef } from "react";

const OutsideClickDetector = (handler: any) => {
  let domNode = useRef<any>(null);

  useEffect(() => {
    const func = (e: any) => {
      if (domNode.current && !domNode.current?.contains(e.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", func);

    return () => {
      document.removeEventListener("mousedown", func);
    };
  });

  return domNode;
};

export default OutsideClickDetector;
