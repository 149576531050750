import { AuthProvider, useAuth } from "../contexts/AuthProvider";
import Dashboard from "../components/Portal/index";
import cloudRight from "../assets/img/cloud-right.png";
import cloudleft from "../assets/img/cloud-left.png";
import { useEffect } from "react";

export default function Journal() {
  const { user, loading, isPaused, isCanceled, getUser } = useAuth();
  useEffect(() => {
    const getUserAuth = async () => {
      await getUser();
    };
    if (!user) {
      getUserAuth();
    }
  }, []);
  return (
    <>
      <AuthProvider>
        <main className="bg-sky relative overflow-hidden min-h-screen mx-auto">
          <img
            src={cloudRight}
            alt="Naya-cloud"
            className="absolute right-0 z-0 hidden md:block"
          />
          <img
            src={cloudleft}
            alt="Naya-cloud"
            className="absolute left-0 z-0  hidden md:block"
          />
          <Dashboard />
        </main>
      </AuthProvider>
    </>
  );
}
