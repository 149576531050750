import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

interface SelectTimeProps {
  value: string;
  setValue: (v: string) => void;
}
const SelectTime = ({ value, setValue }: SelectTimeProps) => {
  const [hour, setHour] = useState(value.split(":")[0]);
  const [minutes, setMinutes] = useState(value.split(":")[1]);
  const setTime = (type: string, value: string) => {
    if (type === "h") {
      setValue(`${value}:${minutes}`);
      setHour(value);
    } else {
      setValue(`${hour}:${value}`);
      setMinutes(value);
    }
  };
  return (
    <div className="relative inline-flex items-center border rounded-md shadow-lg p-2 justify-center">
      <Listbox value={hour} onChange={(v) => setTime("h", v)}>
        <div className="mt-1">
          <Listbox.Button className="px-2 outline-none appearance-none bg-transparent">
            <span className="block truncate">{hour}</span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 z-20 text-center">
              {[
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
              ].map((hour) => (
                <Listbox.Option
                  key={hour}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 ${
                      active ? "bg-eastern-blue text-white" : "text-gray-900"
                    }`
                  }
                  value={hour}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {hour}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <span className="px-2">:</span>
      <Listbox value={minutes} onChange={(v) => setTime("m", v)}>
        <div className="mt-1">
          <Listbox.Button className="px-2 outline-none appearance-none bg-transparent">
            <span className="block truncate ">{minutes}</span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 z-20 text-center">
              {["00", "15", "30", "45"].map((hour) => (
                <Listbox.Option
                  key={hour}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 ${
                      active ? "bg-eastern-blue text-white" : "text-gray-900"
                    }`
                  }
                  value={hour}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {hour}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default SelectTime;
