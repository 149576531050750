import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { subscribeUser } from "../../utils/handleSubscription";
import { useAuth } from "../../contexts/AuthProvider";

const InstallAppBanner = () => {
  const { user } = useAuth();
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState(
    window.matchMedia("(display-mode: standalone)").matches
  );

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener
      );
    };
  }, []);

  const handleSubscription = async () => {
    try {
      if (user) {
        await subscribeUser(user);
        console.log("Subscribed to push notifications after manual install.");
      }
    } catch (error) {
      console.error("Error subscribing to push notifications:", error);
    }
  };

  const handleManualInstallClick = async () => {
    console.log(deferredPrompt);
    if (!user) {
      console.log("User is not authenticated.");
      return;
    }

    if (/(iPhone|iPod|iPad)/i.test(navigator.userAgent) && !isAppInstalled) {
      setIsDialogOpen(true);
    }

    if (deferredPrompt) {
      // Show the installation prompt
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;

      if (choiceResult.outcome === "accepted") {
        // Installation was accepted
        console.log("App was installed.");

        // Subscribe the user to push notifications after manual install
        await handleSubscription();

        // Clear the deferred prompt after user interaction
        setDeferredPrompt(null);
      } else {
        // Installation was dismissed
        console.log("App installation was dismissed.");
      }
    }
  };

  return (
    <>
      {!isAppInstalled && (
        <div className="bg-eastern-blue text-white text-center p-2 rounded-b-lg md:w-full lg:w-2/3 mx-auto h-full relative">
          <p className="pb-2">
            Install naya on your home screen for a better experience!
          </p>

          <button
            className="bg-white p-2 drop-shadow-xl rounded text-primary text-sm"
            onClick={handleManualInstallClick}
          >
            Get naya on your phone
          </button>

          <button
            onClick={() => setIsAppInstalled(true)}
            className="absolute right-4 top-1 text-white text-2xl"
          >
            ×
          </button>
        </div>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="bg-white w-96 p-4 rounded-lg shadow-lg">
            <Dialog.Title className="text-lg font-semibold mb-2">
              Install Instructions
            </Dialog.Title>
            <p>To install the naya app on your device, follow these steps:</p>
            <ol className="list-decimal list-inside mt-2">
              <li>
                Tap the Share icon at the bottom of the screen (it looks like a
                square with an arrow pointing upward).
              </li>
              <li>Scroll down and tap on "Add to Home Screen."</li>
              <li>Customize the name if you'd like, then tap "Add."</li>
              <li>The app icon will now appear on your home screen.</li>
            </ol>
            <button
              className="mt-4 bg-primary text-white px-4 py-2 rounded"
              onClick={() => setIsDialogOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default InstallAppBanner;
