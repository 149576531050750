import mushroomsSmall from "../../assets/img/musrooms-small.png";

const NayaListOfBenefits = () => {
  return (
    <ul className="my-6 gap-4 md:gap-3 flex flex-col pb-2 px-0 text-sm md:text-md md:px-10">
      <li className="flex items-center gap-3">
        <img src={mushroomsSmall} className="w-[20px] md:w-[25px]" />
        <div>
          Learn the basics and start{" "}
          <span className="text-sky">microdosing in under 10 minutes</span>
        </div>
      </li>
      <li className="flex items-center gap-3 ">
        <img src={mushroomsSmall} className="w-[25px]" />
        <div>
          Access to Naya’s{" "}
          <span className="text-sky">step-by-step guidance </span>and
          microdosing knowledge base
        </div>
      </li>
      <li className="flex items-center gap-3">
        <img src={mushroomsSmall} className="w-[25px]" />
        <div>
          Never miss a dose with personalized{" "}
          <span className="text-sky">reminders </span>
        </div>
      </li>
      <li className="flex items-center gap-3">
        <img src={mushroomsSmall} className="w-[25px]" />
        <div>
          <span className="text-sky">Track</span> what matters to you and follow
          your progress
        </div>
      </li>
      <li className="flex items-center gap-3">
        <img src={mushroomsSmall} className="w-[25px]" />
        <div>
          <span className="text-sky">Access Naya Chat</span> our AI-driven data
          base where you can ask Naya anything
        </div>
      </li>
    </ul>
  );
};

export default NayaListOfBenefits;
