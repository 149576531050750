import { useState, Fragment, useEffect } from "react";
import { capitalize } from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import JournalEntry from "./JournalEntry";
import ReportMood from "./ReportMood";
import SelectInput from "../../Forms/SelectInput";
import CheckIn from "../Journal/CheckIn";
import { StageTracking, JournalEntrie } from "../../../types";
import ChevronButton from "../../UiElements/ChevronButton";
import { useAuth } from "../../../contexts/AuthProvider";
import { returnDoseBySubstance } from "../../../utils/nayaUtils";
import { supabase } from "../../../lib/supabaseClient";

interface RecapProps {
  isOpen: boolean;
  onClose: () => void;
  selectedDayState: StageTracking | null;
}
interface ActionItemProps {
  isOpen: boolean;
  onClose: () => void;
  selectedDayState: StageTracking | null;
}

const PlusCircleSvg = ({ fill }: { fill: string }) => {
  const fillColor = `${fill}`;
  return (
    <div
      className={`bg-${fill}-light rounded-full w-[23px] h-[23px] relative mr-3`}
    >
      <svg
        className={`fill-${fillColor} absolute right-0 w-[18px] h-[18px]`}
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
        fill={`url(#${fillColor})`} // Use the fill color here
      >
        <defs>
          <linearGradient id={fillColor}>
            <stop offset="0%" stopColor={fillColor} />
          </linearGradient>
        </defs>
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0-13.3-10.7 24-24 24s-24-10.7-24-24z" />
      </svg>
    </div>
  );
};

const CheckCircleSvg = ({ fill }: { fill: string }) => {
  const fillColor = `${fill}`;
  return (
    <div
      className={`bg-${fill}-light rounded-full w-[23px] h-[23px] relative mr-3`}
    >
      <svg
        className={`fill-${fillColor} absolute right-0 w-[18px] h-[18px]`}
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
        fill={`url(#${fillColor})`}
      >
        <defs>
          <linearGradient id={fillColor}>
            <stop offset="0%" stopColor={fillColor} />
          </linearGradient>
        </defs>
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
      </svg>
    </div>
  );
};

const ReportDose = () => {
  const [dose, setDose] = useState("0.1 g");
  const [substance, setSubstance] = useState("Psilocybin Mushrooms");
  return (
    <>
      <p className="text-green font-bold text-xl text-center">Report a Dose</p>
      <hr className="my-4 text-green-light" />
      <div className="flex  gap-4  items-center mb-4 relative z-20">
        <span className="text-green">Dosage</span>
        <SelectInput
          items={["0.1 g", "0.2 g", "0.3 g", "0.4 g"]}
          setValue={(v) => setDose(v)}
          value={dose}
          buttonClass="bg-green-light rounded-xl px-4 py-3 text-center shadow-none"
        />
      </div>
      <div className="flex gap-4 items-center mb-4">
        <span className="text-green">Substance</span>
        <SelectInput
          items={["Psilocybin Mushrooms", "LSD"]}
          setValue={(v) => setSubstance(v)}
          value={substance}
          buttonClass="bg-green-light rounded-xl px-4 py-3 text-center shadow-none"
        />
      </div>
      <button className="flex m-auto bg-green text-white rounded-lg px-4 py-1 mt-8">
        Add
      </button>
    </>
  );
};

const ActionItem = ({ isOpen, onClose, selectedDayState }: ActionItemProps) => {
  const { user } = useAuth();
  const [selectetdAction, setSelectetdAction] = useState<string | null>(null);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-20 overflow-y-auto ${
          isOpen ? "flex items-center justify-center" : "hidden"
        }`}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 flex items-center justify-center text-center w-4xl">
          {/* Background overlay */}
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          {/* Dialog content */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="w-[400px] bg-floral p-6 my-8 text-left flex flex-col  transition-all transform  shadow-xl rounded-2xl">
              {!selectetdAction ? (
                <div>
                  <div className="flex items-center min-h-10 pr-10">
                    <PlusCircleSvg fill="green" />
                    <button
                      className="bg-green-light rounded-xl p-2 text-sm w-full"
                      onClick={() => setSelectetdAction("dose")}
                    >
                      Report a dose
                    </button>
                  </div>
                  <hr className="text-gray-light my-6" />
                  <div className="flex items-center min-h-10 pr-10">
                    <PlusCircleSvg fill="purple" />
                    <button
                      className="bg-purple-light rounded-xl p-2 text-sm w-full"
                      onClick={() => setSelectetdAction("mood")}
                    >
                      Log mood
                    </button>
                  </div>
                  <hr className="text-gray-light my-6" />
                  <div className="flex items-center min-h-10 pr-10">
                    <PlusCircleSvg fill="blue" />
                    <button
                      className="bg-cyan-blue rounded-xl p-2 text-sm w-full"
                      onClick={() => setSelectetdAction("checkin")}
                    >
                      Benefits Checkin
                    </button>
                  </div>
                  <hr className="text-gray-light my-6" />
                  <div className="flex items-center min-h-10 pr-10">
                    <PlusCircleSvg fill="orange" />
                    <button
                      className="bg-orange-light rounded-xl p-2 text-sm w-full"
                      onClick={() => setSelectetdAction("journal")}
                    >
                      Add a journal entry
                    </button>
                  </div>
                </div>
              ) : (
                <div className="relative">
                  <div className="flex mb-8 justify-between">
                    <ChevronButton callback={() => setSelectetdAction(null)} />
                    <span className="flex justify-end text-sm ">
                      {moment(selectedDayState?.date).format("MMMM DD, YYYY")}
                    </span>
                  </div>
                  {selectetdAction === "dose" && <ReportDose />}
                  {selectetdAction === "mood" && <ReportMood />}
                  {selectetdAction === "checkin" && user && (
                    <CheckIn
                      user={user}
                      setOpenCheckIn={() => setSelectetdAction(null)}
                    />
                  )}
                  {selectetdAction === "journal" && <JournalEntry />}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const Recap = ({ isOpen, onClose, selectedDayState }: RecapProps) => {
  const { user } = useAuth();
  const [openActionItem, setOpenActionItem] = useState(false);
  const [journal, setJournal] = useState<JournalEntrie | null>(null);
  useEffect(() => {
    console.log(selectedDayState?.journal);
    setJournal(null);
    const getJournal = async () => {
      setJournal(
        user?.journals.find((j) => j.id === selectedDayState?.journal) ?? null
      );
    };
    if (selectedDayState?.journal) getJournal();
  }, [selectedDayState?.journal]);
  const isFirstDay =
    moment(user?.state.starting_date).format("YYYY-MM-DD") ===
    moment(selectedDayState?.date).format("YYYY-MM-DD");

  const isToday =
    moment().format("YYYY-MM-DD") ===
    moment(selectedDayState?.date).format("YYYY-MM-DD");

  const isFirstDayOrToday = isFirstDay || isToday;

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className={`fixed inset-0 z-20 overflow-y-auto ${
            isOpen ? "flex items-center justify-center" : "hidden"
          }`}
          onClose={onClose}
        >
          <div className="min-h-screen px-4 flex items-center justify-center text-center w-4xl">
            {/* Background overlay */}

            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* Dialog content */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block p-6 my-8 text-left align-middle transition-all transform bg-floral shadow-xl rounded-2xl min-w-full">
                {/* Your dialog content here */}

                <span className="flex justify-end text-sm text-eastern-blue">
                  {moment(selectedDayState?.date).format("MMMM DD, YYYY")}
                </span>
                <p className="text-xl font-bold">Recap</p>
                {selectedDayState?.dayType || isFirstDayOrToday ? (
                  <div className="mt-4">
                    <p>
                      {selectedDayState?.dayType ? (
                        <>
                          According to your protocol (
                          <b>{selectedDayState.protocol.replace("_", " ")}</b>),
                          this was a {capitalize(selectedDayState?.dayType)} day
                        </>
                      ) : (
                        <>
                          According to your protocol, this Your first
                          Microdosing day
                        </>
                      )}
                    </p>
                    <hr className="text-gray-light my-5" />
                    <div className="flex my-10 gap-6 items-center m-auto w-full">
                      <div className="text-left w-full">
                        <p className="text-xl mb-2">Practice</p>
                        {selectedDayState?.dose ? (
                          <p className="text-sm">
                            You’ve recored a microdose 🥳
                          </p>
                        ) : (
                          <span className="bg-green-light rounded-xl px-2 py-1 text-sm">
                            Microdose +
                          </span>
                        )}
                      </div>
                      <div className="w-full">
                        <p className="text-xl mb-2">Mood</p>
                        {selectedDayState?.mood ? (
                          <p className="text-sm">
                            You’ve recorded your mood 🙌
                          </p>
                        ) : (
                          <span className="bg-purple-light rounded-xl px-2 py-1 text-sm">
                            Mood +
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex my-10 gap-6 items-center m-auto w-full">
                      <div className="w-full">
                        <p className="text-xl mb-2">Benefits</p>
                        {selectedDayState?.checkin ? (
                          <p className="text-sm">You’ve recored a checkin</p>
                        ) : (
                          <span className="bg-cyan-blue rounded-xl px-2 py-1 text-sm">
                            Checkin +
                          </span>
                        )}
                      </div>
                      <div className="w-full">
                        <p className="text-xl mb-2">Journal</p>
                        {selectedDayState?.journal ? (
                          <p className="text-sm">
                            You added a journal entry ✍️
                          </p>
                        ) : (
                          <span className="bg-orange-light rounded-xl px-2 py-1 text-sm">
                            Journal +
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="bg-white p-6 flex flex-col min-h-10 drop-shadow-xl rounded-lg w-full">
                      <div className="flex items-center">
                        <PlusCircleSvg fill="gray" />
                        <button
                          className="bg-gray-light rounded-xl p-2 text-sm w-full md:w-2/3"
                          onClick={() => setOpenActionItem(true)}
                        >
                          Add to your day
                        </button>
                      </div>
                      {selectedDayState?.checkin && (
                        <div className="">
                          <hr className="text-gray-light my-5" />
                          <div className="flex items-center">
                            <CheckCircleSvg fill="blue" />
                            <span className="bg-blue-light rounded-xl p-2 text-sm w-full md:w-2/3 text-center">
                              Checkin
                            </span>
                          </div>
                        </div>
                      )}
                      {selectedDayState?.dose && (
                        <div className="">
                          <hr className="text-gray-light my-5" />
                          <div className="flex items-center gap-3">
                            <CheckCircleSvg fill="green" />
                            <span className="bg-green-light rounded-xl p-2 text-sm w-full md:w-2/3 text-center">
                              Microdose
                            </span>
                            <span className="bg-green-light rounded-xl p-2 text-sm w-full md:w-2/3 text-center">
                              {returnDoseBySubstance(
                                selectedDayState.dose,
                                selectedDayState.medicine
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {selectedDayState?.journal && (
                        <div className="">
                          <hr className="text-gray-light my-5" />
                          <div className="flex items-center gap-3">
                            <CheckCircleSvg fill="orange" />
                            <span className="bg-orange-light rounded-xl p-2 text-sm w-full md:w-2/3 text-center">
                              journal
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Journal */}
                    <div className="mt-6">
                      <p className="text-xl mb-4 text-center">Journal</p>
                      <hr className="text-gray-light my-5" />
                      <div className="bg-white p-6 flex flex-col min-h-10 drop-shadow-xl rounded-lg w-full">
                        {journal ? (
                          <div>
                            <p>{journal.text}</p>
                          </div>
                        ) : (
                          <p>No journal entries</p>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4">
                    <p>There is no recording of this day</p>
                  </div>
                )}
                {/* Close button */}
                <div className="mt-6 text-right">
                  <button
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-500"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <ActionItem
        isOpen={openActionItem}
        onClose={() => setOpenActionItem(false)}
        selectedDayState={selectedDayState}
      />
    </>
  );
};

export default Recap;
