import React, { useState } from "react";
import moment from "moment";
import { capitalize } from "lodash";
import { JournalEntrie } from "../../../types";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  return (
    <div className="flex items-center justify-center mt-6 text-sm">
      <button
        className={`px-2 py-1 text-white bg-blue rounded ${
          currentPage === 1
            ? "opacity-50 cursor-not-allowed"
            : "hover:bg-blue-600"
        }`}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Back
      </button>
      <span className="px-4 py-2">{`Page ${currentPage} of ${totalPages}`}</span>
      <button
        className={`px-2 py-1 text-white bg-blue rounded ${
          currentPage === totalPages
            ? "opacity-50 cursor-not-allowed"
            : "hover:bg-blue-600"
        }`}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

const JournalEntriesView = ({
  entries,
  openJournalTextEditor,
  editEntrie,
}: {
  entries: JournalEntrie[] | null;
  openJournalTextEditor: boolean;
  editEntrie: (e: JournalEntrie) => void;
}) => {
  const entriesPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * entriesPerPage;
  const endIndex = startIndex + entriesPerPage;

  // Sort the entries by date in descending order
  const sortedEntries = entries
    ? entries.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
    : [];

  // Get the entries for the current page
  const currentEntries = sortedEntries.slice(startIndex, endIndex);
  const totalPages = Math.ceil((entries?.length ?? 0) / entriesPerPage);

  const onPageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div>
      <p className="text-center">Journal entries</p>
      {!entries && (
        <p className="text-sm text-center">No journal entries yet</p>
      )}
      {currentEntries.map((entrie) => (
        <div
          className="md:max-w-xl px-6 py-4 overflow-hidden bg-[#FFFDFA] mx-auto mb-3"
          key={entrie.id}
        >
          <div className="flex gap-6 mb-2 text-xs items-center justify-between">
            <div className="gap-4 flex items-center">
              {entrie?.day_type && (
                <div className="bg-sky-light px-2 py-1 rounded text-white">
                  {capitalize(entrie?.day_type)}
                </div>
              )}
              {entrie.dose && (
                <div className="bg-sky-light px-2 py-1 rounded text-white">
                  {entrie.dose}
                </div>
              )}
              <svg
                onClick={() => editEntrie(entrie)}
                xmlns="http://www.w3.org/2000/svg"
                height="1.4em"
                className="fill-eastern-blue hover:fill-sky-light cursor-pointer"
                viewBox="0 0 576 512"
              >
                <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V285.7l-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" />
              </svg>
            </div>

            <span className="text-sky-light">
              {moment(entrie.created_at).format("MMM DD, YYYY")}
            </span>
          </div>
          <hr className="border-[#E0DCD7] mb-2" />
          <div className="flex justify-between">
            <p className="text-blue">{entrie.text}</p>
          </div>
        </div>
      ))}

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default JournalEntriesView;
