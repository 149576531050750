import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { capitalize } from "lodash";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";

interface SelectInputProps {
  items: string[];
  value: any;
  setValue: (v: any) => void;
  buttonClass?: string;
}

const SelectInput = ({
  value,
  setValue,
  items,
  buttonClass,
}: SelectInputProps) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const onChange = (v: string) => {
    setValue(v);
    setSelectedValue(v);
  };

  return (
    <Listbox value={selectedValue} onChange={(v) => onChange(v)}>
      <div className="relative mt-1 z-1 w-full">
        <Listbox.Button
          className={`${buttonClass} relative cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300`}
        >
          <span className="block truncate">
            {capitalize(selectedValue.replace(/_/g, " "))}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-auto overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {items.map((item) => (
              <Listbox.Option
                key={item}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 w-auto ${
                    active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                  }`
                }
                value={item}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {capitalize(item.replace(/_/g, " "))}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default SelectInput;
