import { ReactNode, FC } from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import bgPattern from "../assets/img/bg-pattern.png";

interface BrandProps {
  children?: ReactNode | undefined;
}

export const BrandLayout: FC<BrandProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      <main
        style={{ backgroundImage: `url(${bgPattern})` }}
        className="bg-amber bg-right-top"
      >
        {children}
      </main>
      <Footer />
    </>
  );
}
