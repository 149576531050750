import { BrandLayout } from "../layouts/Brand";
import calendar from "../assets/img/calendar.png";
import heritageLibraryMushrooms from "../assets/img/heritageLibraryMushrooms.png";

import clouds from "../assets/img/clouds.png";
import slide1 from "../assets/img/slide_1.png";
import slide2 from "../assets/img/slide_2.png";
import slide3 from "../assets/img/slide_3.png";
import botanical321 from "../assets/img/botanical321.png";
import { Link } from "react-router-dom";

export default function Landing() {
  return (
    <>
      <BrandLayout>
        <div className="px-3 relative pt-32 pb-20 content-center items-center justify-center md:min-h-screen-75">
          <div className="container relative md:mt-[100px] mx-auto z-1">
            <div className="grid grid-cols-2 gap-24">
              <div className="col-span-2 md:col-span-1">
                <h1 className="tracking-[-0.04em] text-[51px] md:leading-[56px] mt-2 md:mt-[28px] font-serif text-primary ">
                  What is microdosing?
                </h1>
                <p className="text-gray text-[24px] md:text-[28px] md:leading-[125%] mt-3 md:mt-[25px] ">
                  Microdosing is the practice of taking a portion of a
                  psychedelic substance based on scheduled protocols with the
                  intention to increase the quality of your life
                </p>
                <p className="text-lg leading-[125%] mt-[24px] ">
                  When done properly, the dose should be sub-perceptual, or
                  <span className="text-red"> non-hallucinogenic</span>,
                  maintaining the ability to function in daily life, work, and
                  school environments. Even though microdosing does not cause
                  psychedelic effects, it has been reported to improve
                  creativity, reduce stress, boost mood, and facilitate creative
                  flow. It is also said that the benefits are accumulative, and
                  grow over time.
                </p>
                <p className="text-base md:text-lg leading-[28px] mt-[24px] text-center md:text-left">
                  Check out our{" "}
                  <Link to={"/faq"}>
                    <a className="text-red">FAQs</a>
                  </Link>{" "}
                  to learn if microdosing is right for you.
                </p>
              </div>

              <div className="col-span-2 md:col-span-1">
                <img src={calendar} alt="" className="col-span-6" />
              </div>
            </div>
          </div>
        </div>

        <section
          className="h-[275px] relative lg:pt-0 bg-red mt-5 md:mt-0 px-8 md:px-3"
          style={{
            backgroundImage: `url(${heritageLibraryMushrooms})`,
            backgroundBlendMode: "overlay",
            backgroundSize: "cover",
          }}
        ></section>

        <section className="relative pb-20 mt-[110px] md:mt-32 z-2 px-3 md:px-3">
          <div className="container mx-auto z-10">
            <div className="grid md:grid-cols-2 md:flex-row flex-nowrap gap-24 md:gap-7 mb-24">
              <div className="px-4 mb-3 md:mx-0">
                <img
                  src={clouds}
                  alt=""
                  className="absolute left-0 z-[-1] max-width-[700px] top-[-150px] rotate-[180deg]"
                />
                <img src={slide1} alt="" className="" />
              </div>
              <div className="flex items-center">
                <div className="">
                  <h1 className="text-blue text-[38px] tracking-[-0.04em] leading-[110%] font-serif ">
                    Personal Growth
                  </h1>
                  <p className="mt-2 mb-6 font-bold text-lg md:text-lg ">
                    Ready to become a better version of yourself?
                  </p>
                  <p className="text-[#141414] text-lg  leading-[156%]">
                    Members of the global community report to have reached an
                    increased sense of{" "}
                    <span className="text-red">psychological well-being</span>{" "}
                    and <span className="text-red">emotional stability</span>,
                    an increased feeling of social connectedness, improved mood,
                    productivity, focus and creativity through the practice of
                    microdosing. Research has also shown that microdosing helped
                    achieve an increased sense of psychological well-being and
                    emotional stability. Naya is a personalized microdosing
                    experience that guides and supports you through your
                    practice of self improvement. Microdosing with Naya
                    increases the likelihood of identifying and releasing
                    limiting beliefs, removing mental blocks, and promoting
                    well-being.
                  </p>
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-2 md:flex-row flex-nowrap gap-24 md:gap-7 mb-24">
              <div className="flex items-center order-2 md:order-1">
                <div className="">
                  <h1 className="text-blue text-[38px] leading-[110%] tracking-[-0.04em] font-serif ">
                    Spiritual Connection
                  </h1>
                  <p className="mt-2 mb-6 font-bold text-lg md:text-lg ">
                    Looking for a sense of oneness and connection to the world?
                  </p>
                  <p className="text-[#141414] text-lg  leading-[156%]">
                    Microdosing has been reported to increase a sense of
                    spirituality by creating a greater{" "}
                    <span className="text-red">sense of connectedness</span> to
                    your environment, friends and your community. Whether you
                    practice a specific religion or consider yourself a
                    religious-less holistic spiritual practitioner, whether you
                    are simply curious or in a quest of self-realization, the
                    practice of microdosing has the potential to help you gain a
                    greater sense of oneness. Microdosing has also been reported
                    to increase a sense of spirituality, especially when
                    combined with other spiritual practices such as praying or
                    meditating. Naya is here to help you reflect on
                    transcendental questions of life, uncover your path of
                    self-awakening in a safe and supporting space. Are you ready
                    to be more ‘present’ in your life and experience your true
                    self?
                  </p>
                </div>
              </div>
              <div className="px-4 mb-3 md:mx-0 order-1 md:order-2">
                <img src={slide2} alt="" />
              </div>
            </div>

            <div className="grid md:grid-cols-2 md:flex-row flex-nowrap gap-24 md:gap-7 mb-24">
              <div className="px-4 mb-3 md:mx-0 order-1">
                <img src={slide3} alt="" className="w-full" />
              </div>
              <div className="flex items-center order-2">
                <div className="">
                  <h1 className="text-blue tracking-[-0.04em] text-[38px] leading-[110%] font-serif ">
                    Therapeutic Effects
                  </h1>
                  <p className="mt-2 mb-6 font-bold text-base md:text-lg ">
                    Looking for a non-pharmaceutical way to treat mental
                    disorders?
                  </p>
                  <p className="text-[#141414] text-lg  leading-[156%]">
                    Recent studies have shown that microdosing with psilocybin
                    as well as other substances, especially when combined with
                    psychotherapy, may{" "}
                    <span className="text-red">
                      reduce symptoms of depression
                    </span>
                    , OCD, migraine/cluster headaches, anxiety and some forms of
                    addictions. There has been no approval from the FDA for the
                    use of any psychedelic substance for medicinal purposes to
                    date, but many worldwide have reported that microdosing
                    created a life-changing effect that helped them decrease
                    their symptoms or even get better and live a life free from
                    their conditions. If you are considering microdosing for its
                    potential therapeutic effect, we strongly advise that you
                    consult a physician or a licensed therapist to support you
                    in your process and follow on the latest research available
                    online.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-7">
              <p className="text-xl text-[#2C2E2E]">
                <b>Disclaimer:</b> Naya does not provide medical advice or
                services. Make sure you read our terms of use before starting to
                use Naya.
              </p>
            </div>

            <div className="absolute right-0 bottom-0 hidden sm:block">
              <img src={botanical321} alt="" />
            </div>
          </div>
        </section>
      </BrandLayout>
    </>
  );
}
