import { BrandLayout } from "../layouts/Brand";
import stickyImage from "../assets/img/top-sticky-bg.png";
import quizSideMushroom from "../assets/img/quiz-side-mushroom.png";
import naturalist from "../assets/img/naturalist.png";
import checkmark from "../assets/img/checkmark.png";
import clouds from "../assets/img/clouds2.png";

export default function Protocols() {
  return (
    <>
      <BrandLayout>
        <div className="px-3 relative max-w-[1280px] mx-auto pt-32 pb-20 content-center items-center justify-center md:min-h-screen-75">
          <div className="flex flex-nowrap flex-col md:flex-row justify-between items-center gap-12 md:gap-6">
            <div className="container max-w-[560px]">
              <div className="">
                <h1 className="text-5xl md:text-5xl md:leading-[60px] mt-2 md:mt-[28px] font-serif text-primary md:text-left">
                  Protocols
                </h1>
              </div>
              <div className="mt-6">
                <p className="text-xl md:text-2xl text-primary"></p>
              </div>
            </div>
            <div className="w-full md:w-[500px] h-[400px] flex justify-center relative">
              <img
                src={clouds}
                alt="clouds"
                className="absolute left-[-148px] top-[60px]"
              />
              <img src={naturalist} alt="" className="absolute bottom-0" />
              <img
                src={stickyImage}
                alt="image"
                className="col-span-6 absolute z-2 bottom-0"
              />

              <img
                src={checkmark}
                alt=""
                className="col-span-6 absolute bottom-0 right-[30%] w-[100px] md:w-auto"
              />
            </div>
          </div>
          <div className="mt-20">
            <div className="mb-[50px] md:mb-[100px] flex flex-col md:flex-row justify-between gap-6 md:gap-10">
              <div className="w-full flex gap-6 items-start">
                <div>
                  <p className="font-serif text-[52px] md:text-[64px] text-red leading-[58px] tracking-[-0.04em]">
                    01
                  </p>
                </div>
                <div className="w-full flex flex-col md:flex-row justify-between gap-12 md:gap-6">
                  <div className="">
                    <div>
                      <h2 className="font-serif font-light text-[32px] md:text-[38px] text-primary leading-[42px] tracking-[-0.04em]">
                        Set your intention
                      </h2>
                    </div>

                    <div className="mt-6">
                      <p className="text-sans text-[#141414] text-base md:text-lg">
                        Whether you aim to be more creative, improve focus,
                        reduce depression or you are just curious - write your
                        intention down to make it clear for yourself exactly why
                        are you embarking on this journey.
                      </p>
                    </div>
                    <div className="mt-6">
                      <p className="font-sans text-base md:text-lg leading-[156%] text-[#141414]">
                        <span className="block mb-1 font-bold">
                          👉🏼 Naya’s tip:
                        </span>
                        <span>
                          Once you have it written, let it go. Intention is more
                          about the direction than the destination. If you get
                          attached to it, it becomes an expectation, which can
                          lead to disappointment. Instead, allow the universe to
                          surprise you.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full flex gap-6 items-start">
                <div>
                  <p className="font-serif text-[52px] md:text-[64px] text-red leading-[58px] tracking-[-0.04em]">
                    02
                  </p>
                </div>
                <div className="w-full flex flex-col md:flex-row justify-between gap-12 md:gap-6">
                  <div className="">
                    <div>
                      <h2 className="font-serif font-light text-[32px] md:text-[38px] text-primary leading-[42px] tracking-[-0.04em]">
                        Choose your medicine
                      </h2>
                    </div>

                    <div className="mt-6">
                      <p className="text-sans text-[#141414] text-base md:text-lg">
                        first, you will have to choose and find the substance
                        you intended to microdose with. You can check out The
                        Teachers section for some inspiration, or independently
                        search for trusted online resources.
                      </p>
                    </div>
                    <div className="mt-6">
                      <p className="font-sans text-base md:text-lg leading-[156%] text-[#141414]">
                        <span className="block font-bold">👉🏼 Naya’s tip:</span>
                        <span>
                          Do your research. There are different reports on
                          different substances at various dosages. Make sure
                          that you cross reference them with your intention
                          before you start your journey. If you can, check the
                          substances that you sourced to make sure it is indeed
                          what you think it is.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-[50px] md:mb-[100px] flex flex-col md:flex-row justify-between gap-8 md:gap-10">
              <div className="w-full flex gap-6 items-start">
                <div>
                  <p className="font-serif text-[52px] md:text-[64px] text-red leading-[58px] tracking-[-0.04em]">
                    03
                  </p>
                </div>
                <div className="w-full flex flex-col md:flex-row justify-between gap-12 md:gap-6">
                  <div className="">
                    <div>
                      <h2 className="font-serif font-light text-[32px] md:text-[38px] text-primary leading-[42px] tracking-[-0.04em]">
                        Choose your protocol
                      </h2>
                    </div>

                    <div className="mt-6">
                      <p className="text-sans text-[#141414] text-base md:text-lg">
                        one of the key aspects of microdosing is to follow a
                        specific protocol. This allows you to track and reflect
                        and learn what works best for you.
                      </p>
                    </div>
                    <div className="mt-6">
                      <p className="font-sans text-base md:text-lg leading-[156%] text-[#141414]">
                        <span className="block font-bold">👉🏼 Aya’s tip:</span>
                        <span>
                          If you are new to the practice, try Fadiman Protocol
                          for 8 weeks. Remember, changes are subtle and take
                          time. There is no magic other than reflecting and
                          being patient.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full flex gap-6 items-start">
                <div>
                  <p className="font-serif text-[52px] md:text-[64px] text-red leading-[58px] tracking-[-0.04em]">
                    04
                  </p>
                </div>
                <div className="w-full flex flex-col md:flex-row justify-between gap-12 md:gap-6">
                  <div className="">
                    <div>
                      <h2 className="font-serif font-light text-[32px] md:text-[38px] text-primary leading-[42px] tracking-[-0.04em]">
                        Choose your starting date{" "}
                      </h2>
                    </div>

                    <div className="mt-6">
                      <p className="text-sans text-[#141414] text-base md:text-lg">
                        That's it! You are all ready to micrdodose. This is a
                        good time to send Naya a message and let her do her
                        magic. She will be there for you, reminding and guiding
                        you along your journey.
                      </p>
                    </div>

                    <div className="mt-6">
                      <p className="font-sans text-base md:text-lg leading-[156%] text-[#141414]">
                        <span className="block font-bold">👉🏼 Aya’s tip:</span>
                        <span>
                          reflect, journal, stay accountable. The promise of
                          microdosing has the highest chance to manifest if you
                          follow your inner guide. Stick to your protocol and
                          amounts. Change protocol, dosage or substance or stop
                          the journey if you have adverse effects. Checkout FAQs
                          for some guidance on adverse effects.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="relative lg:pt-0 bg-red mt-5 md:mt-0 px-8 md:px-0">
          <div className="flex flex-nowrap flex-col md:flex-row justify-between items-center">
            <div className="w-full flex justify-center">
              <div className="max-w-[520px]">
                <div className="container mx-auto md:px-4 py-12">
                  <p className="text-amber font-serif text-[28px] md:text-[38px] leading-[110%]">
                    Need help deciding which protocol is right for you?
                  </p>
                  <p className="text-amber font-serif text-base md:text-lgleading-[110%] mt-6">
                    Take this short quiz! It only takes about a minute.
                  </p>
                  <button className="px-4 py-3 border-2 border-amber rounded-lg uppercase font-bold text-amber mt-6">
                    Coming soon
                  </button>
                </div>
              </div>
            </div>

            <div>
              <img alt="mushroom" src={quizSideMushroom} />
            </div>
          </div>
        </section>
      </BrandLayout>
    </>
  );
}
