import {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { Session, User } from "@supabase/supabase-js";
import { supabase } from "../lib/supabaseClient";
import { UserType, HeadersType } from "../types";
const { REACT_APP_CLIENT_URL, REACT_APP_SUPABASE_URL } = process.env;

export type AuthContextType = {
  signOut: () => void;
  signnUp: (email: string, password: string, ref?: string) => Promise<boolean>;
  signIn: (email: string) => Promise<boolean>;
  signInWithGoogle: () => Promise<boolean>;
  user: UserType | null;
  telegramToken: string | null;
  loading: boolean;
  setUser: (u: UserType) => void;
  getUser: () => Promise<UserType>;
  isPaused: boolean | null;
  isCanceled: boolean | null;
  isActive: boolean | null;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isPaused: false,
  isCanceled: false,
  isActive: false,
  signOut: () => {},
  signnUp: (email: string, password: string, ref?: string) =>
    new Promise((resolve, reject) => {
      resolve(false);
    }),
  signIn: (email: string) =>
    new Promise((resolve, reject) => {
      resolve(false);
    }),
  signInWithGoogle: () =>
    new Promise((resolve, reject) => {
      resolve(false);
    }),
  telegramToken: null,
  loading: false,
  setUser: () => {},
  getUser: () =>
    new Promise((resolve, reject) => {
      resolve({} as UserType);
    }),
});

interface AuthProviderProps {
  children?: ReactNode;
  // any props that come into the component
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserType | null>();
  const [userSession, setUserSession] = useState<Session | null>();
  const [telegramToken, setTelegramToken] = useState<string>();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const tokenParam = queryParameters.get("token") || "";
    getUser();
  }, []);

  const getUser = async () => {
    setLoading(true);
    const { data, error } = await supabase.auth.getSession();
    if (!data.session || error) {
      setLoading(false);
      navigate("/auth");
      return;
    }
    const { user } = data.session as Session;
    setAccessToken(data.session.access_token);
    let res = await supabase
      .from("users")
      .select(
        `*, customer:customers!user_id(*), state:user_state!user_id(*), journals!user_id(*),  surveys:survey!user_id(*)`
      )
      .eq("id", user.id)
      .single();

    if (res.error) {
      console.log(res.error);
      setLoading(false);
      navigate("/auth");
    } else if (res.data) {
      const user = {
        ...res.data,
        customer: res.data.customer[0],
        state: res.data.state ? res.data.state : {},
      };

      setUser(user);
      setLoading(false);
      return user;
    }
    setLoading(false);
    return null;
  };

  const signIn = async (email: string) => {
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: `${REACT_APP_CLIENT_URL}/journal`,
      },
    });
    if (error) throw error;
    return true;
  };

  const signInWithGoogle = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: `${REACT_APP_CLIENT_URL}/journal`,
      },
    });
    if (error) throw error;
    return true;
  };

  const signUp = async (email: string, password: string, ref?: string) => {
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        data: {
          ref,
        },
      },
    });
  };

  const value = {
    signOut: () => {
      setUser(null);
      setUserSession(null);
      supabase.auth.signOut();
      navigate("/auth");
    },
    isPaused: user?.customer?.status === "paused",
    isCanceled: user?.customer?.status === "canceled",
    isActive: user?.customer?.status === "active",
    signInWithGoogle,
    getUser,
    signIn,
    setUser,
    user,
    telegramToken,
    loading,
  };

  return (
    <AuthContext.Provider value={value as AuthContextType}>
      {children}
    </AuthContext.Provider>
  );
};

// export the useAuth hook
export const useAuth = () => useContext(AuthContext);
