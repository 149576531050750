import { useEffect, useState } from "react";
import StartForm from "../../Forms/StartForm";
import mobilePopUp from "../../../assets/img/mobile-popup.png";
import botanical321 from "../../../assets/img/botanical321.png";
import bgPattern from "../../../assets/img/bg-pattern.png";
import cloudLeft from "../../../assets/img/cloud-left.png";
import cloudRight from "../../../assets/img/cloud-right.png";

type StartModalProps = {
  className?: string;
  show?: boolean;
  withButton?: boolean;
  email?: string;
  setShowModal: (show: boolean) => void;
};

export default function StartModal({
  className,
  show,
  withButton,
  email,
  setShowModal,
}: StartModalProps) {
  const buttonClass = className
    ? className
    : "bg-primary w-2/3 text-white font-bold text-base md:text-sm w-full md:w-auto px-5 md:px-8 py-4 md:py-6 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 text-[18px]";
  return (
    <>
      {withButton && (
        <button
          className={buttonClass}
          type="button"
          onClick={() => setShowModal(true)}
        >
          Get started
        </button>
      )}
      {show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-2">
            <div
              onClick={() => setShowModal(false)}
              className="opacity-0 absolute h-screen w-screen"
            />
            <div className="relative w-auto md:my-4 mx-auto md:max-w-[900px] h-full md:h-auto flex items-center md:block">
              {/*content*/}
              <div className="rounded-xl  shadow-lg relative flex flex-col w-full  md:w-[90%] bg-white outline-none focus:outline-none">
                <button
                  className="absolute right-2 top-2 border-0 text-3xl leading-none font-semibold outline-none focus:outline-none z-[1000] block md:hidden"
                  onClick={() => setShowModal(false)}
                >
                  <span className="md:text-white h-8 w-8 text-3xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
                {/*body*/}
                <div className="relative grid md:grid-cols-5 ">
                  <div
                    style={{ backgroundImage: `url(${bgPattern})` }}
                    className="bg-amber bg-right-top px-4 md:px-10 py-4 md:py-8 col-span-3 md:col-span-3 flex flex-col  justify-center rounded-l-xl"
                  >
                    <StartForm from="Pop-up" email={email} />
                  </div>
                  {/* Right */}
                  <div className="w-full bg-sky-light col-span-2 relative justify-center overflow-hidden hidden md:flex md:min-h-[540px] rounded-r-xl">
                    <button
                      className="absolute right-4 top-2 border-0 text-3xl"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="text-white h-8 w-8 text-3xl">×</span>
                    </button>
                    <div className="absolute left-0 -top-[25%] max-w-[200px]">
                      <img src={cloudLeft} alt="Naya-cloud" />
                    </div>
                    <div className="absolute right-0 top-[25%] max-w-[200px]">
                      <img src={cloudRight} alt="Naya-cloud" />
                    </div>
                    <div className="flex flex-col items-center my-[60px] mx-auto mb-0 relative">
                      <h2 className="font-serif text-[28px] text-white text-center pb-8 leading-8">
                        It all starts with a conversation
                      </h2>
                    </div>
                    <div className="absolute bottom-0">
                      <img
                        src={mobilePopUp}
                        alt="Naya-app"
                        className="w-[90%]"
                      />
                    </div>
                    <div className="absolute right-0 bottom-0 w-1/3">
                      <img src={botanical321} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
