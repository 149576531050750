import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { ToastContainer, toast } from "react-toastify";
import InstallAppBanner from "../UiElements/InstallAppBanner";
import { useAuth } from "../../contexts/AuthProvider";
import { subscribeUser } from "../../utils/handleSubscription";
import Welcome from "./Welccome";
import HexagonLoading from "../UiElements/Loading/HexagonLoading";
import OnBoarding from "../OnBoarding";
import { SideNav } from "./SideNav";
import OutsideClickDetector from "../../hooks/OutsideClickDetector";
import "react-toastify/dist/ReactToastify.css";

export default function Dashboard() {
  const navigate = useNavigate();
  const { user, loading, isPaused, isCanceled, getUser } = useAuth();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [openTab, setOpenTab] = useState("home");
  const sideNavRef = OutsideClickDetector(() => setSideMenuOpen(false));

  useEffect(() => {
    if (!user?.active) identifyUser();
    const appInstalled = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
    if (appInstalled && !user?.web_subscription) {
      subscribeUser(user);
    }
  }, []);

  const identifyUser = () => {
    window.analytics.identify(user?.email, {
      email: user?.email,
      user_agent: window.navigator.userAgent,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      from: "start_onboarding",
    });
    const ref = user?.ref ?? "";
    window.analytics.track(
      ref === "withpatoo" ? "patoo_signup" : "website_signup"
    );
  };

  return (
    <>
      <ToastContainer />
      {!user ? (
        <div className="flex justify-center items-center min-h-screen">
          <HexagonLoading loading={loading} />
        </div>
      ) : (
        <div className="container mx-auto min-h-screen z-3 relative ">
          <InstallAppBanner />
          <div className="px-2 py-2 md:py-10 md:w-full lg:w-3/4 mx-auto h-full">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10 justify-normal md:justify-center h-full">
              <div className="md:col-span-1 hidden md:block">
                <SideNav
                  tab={openTab}
                  setOpenTab={(t) => setOpenTab(t)}
                  isUserActive={!!user?.active}
                  userRef={user?.ref}
                  onBoardingStep={user?.onboarding_step}
                />
              </div>
              <div className="flex flex-col gap-3 col-span-3 md:col-span-2 flex-1">
                {isPaused ||
                  (isCanceled && (
                    <div
                      onClick={() => navigate(`/journal/account#subscription`)}
                      className="bg-primary text-white flex justify-between items-center rounded-2xl p-4 md:flex-row flex-col cursor-pointer"
                    >
                      <div>
                        <p className="text">
                          {isCanceled
                            ? "Your subscription is canceled"
                            : "Your 14 day free trial has ended"}
                        </p>
                        <span className="text-sm">
                          Become a subscriber to continue your microdosing
                          journey with Naya
                        </span>
                      </div>
                      <span>SUBSCRIBE</span>
                    </div>
                  ))}
                <div className="rounded-2xl bg-bone px-4 pt-2">
                  <Welcome
                    user={user}
                    isUserActive={user.active}
                    setSideMenuOpen={() => setSideMenuOpen(true)}
                  />
                </div>
                {user.active ? (
                  <div className="rounded-2xl bg-bone flex-1">
                    <Outlet />
                  </div>
                ) : (
                  <div className="rounded-2xl bg-bone z-10">
                    <OnBoarding user={user} />
                  </div>
                )}
              </div>
              <Transition
                show={Boolean(sideMenuOpen)}
                enter="transition-all ease-in-out duration-400 delay-[100ms]"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-all ease-in-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className="fixed md:relative left-0 w-[90%] shadow-md top-0 h-[100vh] z-10 min-h-screen"
                  ref={sideNavRef}
                >
                  <SideNav
                    isUserActive={user.active}
                    tab={openTab}
                    setOpenTab={(t) => {
                      setOpenTab(t);
                      setSideMenuOpen(false);
                    }}
                  />
                </div>
              </Transition>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
