import { Widget } from "@typeform/embed-react";
import { useState } from "react";
import Loading from "../UiElements/Loading";
const ENV = process.env.NODE_ENV;
interface TypeFormPortocolProps {
  name: string;
  substance: string;
  protocol: string;
  platform: string;
  onSubmit: () => void;
}

const TypeFormPortocol = ({
  name,
  substance,
  protocol,
  platform,
  onSubmit,
}: TypeFormPortocolProps) => {
  const [formIsReady, setFormIsReady] = useState(false);

  return (
    <>
      {!formIsReady && (
        <div className="justify-center flex mt-10">
          <Loading loading={!formIsReady} />
        </div>
      )}
      <Widget
        className="w-full h-[77vh]"
        key="TypeFormPortocol"
        id="eycIP7Nf"
        hidden={{
          name,
          substance,
          protocol,
          platform,
        }}
        onEndingButtonClick={() => {
          window.analytics.track("finish_fadiman_tf_education_protocol");
          onSubmit();
        }}
        onReady={() => {
          window.analytics.track("click_start_tf_education_protocol");
          setFormIsReady(true);
        }}
      />
    </>
  );
};

export default TypeFormPortocol;
