import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaTelegramPlane } from "react-icons/fa";
import { BsSignal } from "react-icons/bs";
import StartModal from "../components/UiElements/Modals/StartModal";
import Slider from "../components/UiElements/Slider";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import Logo from "../assets/img/logo-blue.png";
import calendar from "../assets/img/calendar.png";
import mobileChat from "../assets/img/mobile_chat.png";
import flowersButterfly from "../assets/img/flowers-butterfly.png";
import maitake from "../assets/img/maitake.png";
import mobileProduct from "../assets/img/mobile-product.png";
import mushroomFly from "../assets/img/mushroom-fly.png";
import mobile from "../assets/img/mobile-new.png";
import group from "../assets/img/group.png";
import cloudLeft from "../assets/img/cloud-left.png";
import cloudRight from "../assets/img/cloud-right.png";
import cloudsMiddle from "../assets/img/clouds2.png";
import mescalineMolecule from "../assets/img/mescaline-molecule.png";
import bgPsilocybinMolecule from "../assets/img/bg-psilocybin-molecule.png";
import psilocybinMolecule from "../assets/img/psilocybin-molecule.png";

import lionsMane from "../assets/img/lions-mane.png";
import niacinMolecule from "../assets/img/niacin.png";
import mushroomsBottom from "../assets/img/naturalist-mushrooms.png";

const StartInput = ({ ...children }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError("");
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email.match(regex)) return setError("Invalid email address");
    navigate(`/auth?email=${email}`);
  };
  return (
    <>
      {error && <p className="text-red text-xs pb-1 w-full">{error}</p>}
      <div className="flex" {...children}>
        <input
          type="email"
          placeholder="Email Address"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          className="w-full md:w-3/5 border-0 px-4 py-[10px] text-[15px] md:text-[18px] md:py-[10px] rounded-l-md bg-white drop-shadow-xl "
        />
        <button
          onClick={submit}
          className="w-2/3 md:w-auto bg-[#05053C] text-white active:bg-blueGray-600 px-1 md:px-3 rounded-r-md text-[15px] md:text-[18px] py-[2px] md:py-[10px] drop-shadow-xl"
        >
          Get Started
        </button>
      </div>
      <div className="flex justify-center md:flex-start mt-4">
        <p className="text-[10px] text-[#585757] uppercase pt-4 md:pt-0 w-[90%] md:w-full font-bold tracking-widest	">
          By continuing, you agree to NAYA’S{" "}
          <a href="/terms" target="_blank">
            Terms & Conditions
          </a>{" "}
          and{" "}
          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </p>
      </div>
      <StartModal
        withButton={false}
        show={openModal}
        email={email}
        setShowModal={(show) => setOpenModal(show)}
      />
    </>
  );
};

export default function Landing() {
  const [under21, setUnder21] = useState(false);
  const [showAlert, setShowAlert] = useState(
    Boolean(!localStorage.getItem("disclaimer"))
  );
  const acceptDisclaimer = () => {
    setShowAlert(false);
    localStorage.setItem("disclaimer", "1");
  };

  return (
    <>
      <Navbar />
      <main className="bg-floral bg-right-top overflow-hidden">
        <div className="relative px-4 md:px-3 relative pt-32 pb-18 content-center items-center justify-center">
          <div className="container md:mt-[60px] mx-auto z-1">
            <div className="grid md:grid-cols-5 gap-10 mb-32 justify-center md:justify-start">
              <img src={cloudRight} className="absolute right-0 top-0" />
              <div className="col-span-3 relative">
                <img
                  src={cloudsMiddle}
                  className="absolute -bottom-24 left-40 z-0"
                />
                <div className="relative text-center md:text-left">
                  <p className="tracking-[-0.04em] text-red md:text-[28px] font-serif">
                    From preparation to integration
                  </p>
                  <h1 className="text-[42px] md:text-[80px] leading-10 md:leading-[90px] mt-2 md:mt-4 font-serif text-primary">
                    Journey with confidence
                  </h1>
                  <p className="text-lg md:text-[26px] font-light	 mt-3 md:mt-[25px]">
                    Meet Naya, your integrative medicine coach
                  </p>
                  <div className="pt-16">
                    <p className="text-blue pb-4 font-bold tracking-widest">
                      Start your free 14-day trial today
                    </p>
                    <StartInput />
                  </div>
                  <div className="md:w-[90%] w-full z-9">
                    <div className="flex md:hidden z-2">
                      <img src={mobile} alt="" className="pr-8 md:pr-0" />
                    </div>
                    <hr className="mt-12 mb-6 hidden md:block border-gray" />
                    <div className="flex flex-col md:flex-row justify-between gap-6">
                      <div className="w-full">
                        <p className="text-xl">
                          Naya meets you where you’re at
                        </p>
                        <p className="mt-1 text-sm">
                          Chat with Naya on{" "}
                          <span className="text-red">Telegram</span> or{" "}
                          <span className="text-red">Signal</span>. Your data is
                          secure and your privacy is guaranteed
                        </p>
                      </div>

                      <div className="flex gap-2 justify-center md:justify-between mt-4 md:mt-0 w-[170px] m-auto">
                        <span className="flex bg-[#66B9CF] rounded-md p-2 w-2/3 md:w-[68px] md:h-[68px] justify-center items-center">
                          <FaTelegramPlane className="text-white" size="38" />
                        </span>

                        <span className="flex bg-[#66B9CF] rounded-md p-2 relative w-2/3 md:w-[68px] md:h-[68px] overflow-hidden justify-center items-center">
                          <BsSignal className="text-white" size="38" />
                          <span className="absolute text-[12px] md:text-[10px] bg-black text-white w-[102px] md:w-[90px] text-center -left-[12px] top-6 -rotate-[15deg]">
                            Coming soon
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-2 hidden md:block -mt-[20px] z-2">
                <img src={mobile} alt="" className="" />
              </div>
            </div>

            <div className="p-6 md:p-10 justify-center relative text-center bg-primary rounded-xl relative">
              <p className="text-2xl md:text-3xl text-white mb-6">
                Discover Insights with Naya's. Chat Now!
              </p>
              <Link to="/chat">
                <button className="bg-bone drop-shadow-lg p-2 md:p-4 text-md  md:text-xl rounded-md hover:bg-opacity-90 hover:scale-105 transition duration-300">
                  Chat with naya
                </button>
              </Link>
            </div>

            <div className="mt-24 justify-center flex-col relative text-center">
              <p className="tracking-[-0.04em] text-red text-[20px] md:text-[28px] font-serif text-center mb-3">
                Microdosing. Simplified.
              </p>
              <h1 className="text-blue text-[36px] sm:text-[44px] md:text-[42px] leading-10 mb-24	md:mb-[28px] font-serif text-center">
                Learn the basics and start microdosing in <br />
                under 10 minutes
              </h1>
            </div>
          </div>
        </div>

        <section className="relative px-4 md:px-0">
          <img src={cloudRight} className="absolute right-0 z-0 top-[35%]" />
          <div className="container mx-auto relative z-2">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-24 justify-items-center items-center">
              <div className="col-span-2 md:col-span-1 order-2 md:order-1">
                <img
                  src={mobileChat}
                  alt="mobileChat"
                  className="max-w-[400px] md:w-full"
                />
              </div>
              <div className="col-span-2 md:col-span-1 order-1 md:order-2">
                <p className="tracking-[-0.04em] text-red text-[20px] md:text-[28px] font-serif mb-3">
                  The end to hours of online research
                </p>
                <h1 className="text-blue text-[36px] leading-10	sm:text-[44px] md:text-[42px] font-serif">
                  The most important <br className="hidden md:block" />
                  information, all in one
                  <br className="hidden md:block" /> place
                </h1>
                <p className="text-[18px] md:text-[20px] md:leading-[125%] mt-3 md:mt-[25px] ">
                  Naya teaches you the basics of microdosing in a couple of
                  minutes so you can{" "}
                  <span className="text-red">
                    {" "}
                    start microdosing with confidence
                  </span>
                  . With a simple process that removes uncertainties, you are
                  able to microdose at your own pace with ease. And the best
                  part, Naya is always available to answer your questions
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-24 mb-40 justify-items-center items-center">
              <div className="col-span-2 md:col-span-1 -mt-22 order-2 md:order-2">
                <img src={calendar} alt="" />
              </div>
              <div className="col-span-2 md:col-span-1 order-1 md:order-1">
                <p className="tracking-[-0.04em] text-red text-[20px] md:text-[28px] font-serif mb-3">
                  Never miss a microdosing day
                  <br />
                  Track what matters to you
                </p>
                <h1 className="text-blue text-[36px] leading-10 sm:text-[44px] md:text-[42px] font-serif">
                  Built in reminders and <br className="hidden md:block" />
                  checkins
                </h1>
                <p className="text-[18px] md:text-[20px] md:leading-[125%] mt-3 md:mt-[25px] ">
                  Microdosing is all about intention. Naya guides you to better
                  understand why you are microdosing and helps you stay
                  accountable. <span className="text-red">Reminders</span> and{" "}
                  <span className="text-red">check-ins</span> help you to
                  reflect on you goals, track the subtle changes, and witness
                  how the potential becomes reality!
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-24 mb-40 justify-items-center items-center">
              <div className="col-span-2 md:col-span-1 order-2 md:order-1">
                <img src={maitake} alt="maitake" className="w-[90%] m-auto" />
              </div>
              <div className="col-span-2 md:col-span-1 order-1 md:order-2">
                <p className="tracking-[-0.04em] text-red text-[20px] md:text-[28px] font-serif mb-3">
                  Never alone. Always supported.
                </p>
                <h1 className="text-blue text-[36px] sm:text-[44px] md:text-[42px] leading-10 font-serif">
                  Join a vibrant, like-
                  <br />
                  minded community
                </h1>
                <p className="text-[18px] md:text-[20px] md:leading-[125%] mt-3 md:mt-[25px] ">
                  Personal growth has been proven more effective when shared
                  with others. Like tapping into the tangles of mycelium roots,
                  when you microdose with Naya, you get access a global network
                  of microdosers to{" "}
                  <span className="text-red">share your experiences</span> and
                  receive <span className="text-red">support</span>
                </p>
              </div>
            </div>

            <div className="text-center w-full md:w-2/5 m-auto mb-10">
              <p className="mb-3 text-blue font-bold tracking-widest">
                Start your free 14-day trial today
              </p>
              <div className="justify-center">
                <StartInput />
              </div>
            </div>
          </div>
        </section>

        <section className="relative bg-[#539BAD] px-4 md:px-0 mb-24 md:mb-10 md:mt-24 overflow-hidden">
          <div className="overflow-hidden md:flex hidden z-1">
            <div className="absolute left-0 top-[15%] max-w-[400px]">
              <img src={cloudLeft} alt="Naya-cloud" />
            </div>
            <div className="absolute right-0 top-0 max-w-[400px]">
              <img src={cloudRight} alt="Naya-cloud" />
            </div>
          </div>
          <div className="container mx-auto relative pt-10">
            <div className="flex gap-24 justify-center items-center">
              <img
                src={mobileProduct}
                alt="mobileContent"
                className="z-9 w-1/5 hidden md:block"
              />
              <div className="pt-0 text-center md:text-left">
                <h1 className="text-[36px] text-white sm:text-[44px] md:text-[42px] leading-10 mb-[26px] font-serif">
                  A new way to get <br />
                  closer to yourself
                </h1>
                <p className="font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-white">
                  Be reminded on your favorite app <br />
                  Track what matters to you
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="px-4 md:px-0">
          <div className="container relative mx-auto">
            <div className="grid grid-cols-2 gap-24 mb-24 items-center justify-items-center">
              <div className="col-span-2 md:col-span-1">
                <p className="tracking-[-0.04em] text-red text-[20px] md:text-[28px] font-serif mb-3">
                  Self-paced microdosing programs
                </p>
                <h1 className="text-blue text-[36px] sm:text-[44px] md:text-[42px] leading-10	font-serif">
                  Designed to support
                  <br className="hidden md:block" /> all levels of experience
                </h1>
                <p className="text-[18px] md:text-[20px] md:leading-[125%] mt-3 md:mt-[25px] ">
                  Naya was developed to support you with all the information you
                  need to <span className="text-red">get started today</span>{" "}
                  and <span className="text-red">follow</span> through whether
                  your microdosing for the first time or been doing it for years
                </p>
              </div>
              <div className="col-span-2 md:col-span-1 -mt-22">
                <img src={flowersButterfly} alt="" />
              </div>
            </div>
            <div className="relative grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-24 mb-24 items-center justify-items-center">
              <div className="col-span-2 md:col-span-1 auto md:-mt-22 relative order-3 md:order-1">
                <img
                  src={mushroomFly}
                  alt="maitake"
                  className="relative z-2 w-[85%] m-auto"
                />
                <img
                  src={bgPsilocybinMolecule}
                  alt="bgPsilocybinMolecule"
                  className="absolute top-[25%] z-1 right-10 opacity-[0.1]"
                />
              </div>
              <div className="col-span-2 md:col-span-1 relative order-2 md:order-2">
                <p className="tracking-[-0.04em] text-red text-[20px] md:text-[28px] font-serif mb-3">
                  Your personal guide to
                </p>
                <h1 className="text-blue text-[36px] sm:text-[44px] md:text-[42px] leading-10	font-serif">
                  Unlock the full <br className="hidden md:block" />
                  potential of your <br className="hidden md:block" />
                  microdosing practice
                </h1>
                <p className="text-[18px] md:text-[20px] md:leading-[125%] mt-3 md:mt-[25px] ">
                  Access information. Get support. Join a community.
                </p>
                <div className="mt-3">
                  <p className="mb-3 text-blue font-bold tracking-widest">
                    Start your free 14-day trial today
                  </p>
                  <StartInput />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative px-4 md:px-0 mb-24">
          <div className="container md:mt-[60px] mx-auto z-1">
            <div className="mt-10 flex justify-center flex-col relative">
              <p className="tracking-[-0.04em] text-red text-[20px] md:text-[28px] font-serif text-center mb-3">
                The benefits
              </p>
              <h1 className="text-blue text-[36px] sm:text-[44px] md:text-[42px] leading-10	mb-[28px] md:mb-[72px] font-serif text-center">
                Why Microdosing?
              </h1>
              <div className="grid grid-cols-2 gap-2 md:gap-10">
                <div className="col-span-2 md:col-span-1 font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414]">
                  Microdosing is the practice of consuming a very small amount
                  of a psychedelic substance with the intention to{" "}
                  <span className="text-red">
                    improve the quality of your life.
                  </span>{" "}
                  Think of it as a holistic supplement. The effect is meant to
                  be “sub-perceptual,” or barely noticeable. And so the aim is
                  not to achieve a transcendental state but to support daily
                  activities within work, school, and social environments.
                </div>
                <div className="col-span-2 md:col-span-1 font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414]">
                  Microdosing has been reported to improve creativity, reduce
                  stress, boost mood, and facilitate flow states. It is also
                  noted that benefits are cumulative, and{" "}
                  <span className="text-red">increase over time</span>.
                  <br />
                  <br />
                  Check out our <a href="/faq">FAQs</a> to learn if microdosing
                  is right for you.
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section
          className="relative py-10 bg-red px-8 md:px-0 mb-10"
          style={{
            backgroundImage: `url(${waitingListSectionImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <div className="container mx-auto md:px-4">
            <div className="flex-auto mx-auto text-center relative">
              <h4 className="md:text-[24px] font-serif text-white">
                Not ready to start microdosing but want to learn more?
              </h4>
              <h1 className="text-[36px] text-white sm:text-[44px] leading-10 md:text-[38px] mb-[24px] font-serif">
                Join our newsletter
              </h1>
              <div className="md:max-w-[450px] m-auto">
                <StartForm from="Landing" />
              </div>
            </div>
          </div>
        </section> */}

        {/* Slider */}
        <section className="container px-4 mx-auto md:px-4">
          <Slider />
        </section>

        <section className="relative px-4 mx-auto md:px-4">
          <div className="hidden md:block md:absolute mx-auto bottom-[-30px] -right-10 mt-[48px] md:mt-auto mb-[80px] md:mb-auto w-[280px]">
            <img src={mushroomsBottom} alt="" className="w-full" />
          </div>
          <div className="container mx-auto relative">
            <div className="justify-between items-center mt-20 md:mt-32 text-center max-w-auto md:max-w-[740px] mx-auto">
              <h1 className="text-[36px] sm:text-[44px] md:text-[42px] font-serif text-center">
                The Guides
              </h1>
              <p className="text-[18px] md:text-[28px] leading-[125%] py-6 md:py-3">
                We call these substances - The Teachers - because they can serve
                as guides for healing and self-growth when used properly and
                responsibly.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-10 md:gap-20">
              <div className="col-span-2 md:col-span-1 text-center md:text-left">
                <div className="flex justify-between items-start mt-0 md:mt-12 md:py-12 gap-5 md:gap-10">
                  <div>
                    <img src={mescalineMolecule} alt="" className="mx-auto" />
                    <p className="text-[20px] md:text-[24px] font-semibold leading-[31.2px] mt-[24px] mb-4 text-[#141414]">
                      Mescaline
                    </p>
                    <p className="font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414]">
                      Mescaline is a naturally occurring psychoactive alkaloid
                      that is the main psychedelic substance in several cacti,
                      which are native to the Americas. Mescaline can be found
                      in Peyote and San Pedro. Cacti containing Mescaline have
                      been used by Native Americans and shamanic ceremonies for
                      thousands of years.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1 text-center md:text-left">
                <div className="flex justify-between items-start mt-10 md:mt-12 py-5 md:py-12 gap-10">
                  <div>
                    <img src={psilocybinMolecule} alt="" className="mx-auto" />
                    <p className="text-[20px] md:text-[24px] font-semibold leading-[31.2px] mt-[24px] mb-4 text-[#141414]">
                      Psilocybin
                    </p>
                    <p className="font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414]">
                      Psilocybin mushrooms are fungi that contain the
                      psychoactive compound psilocybin, a naturally occurring
                      psychedelic compound capable of producing powerful
                      hallucinations and mystical-type experiences, along with
                      other effects.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-10 md:gap-20">
              <div className="col-span-2 md:col-span-1 text-center md:text-left">
                <div className="flex justify-between items-start mt-0 md:mt-12 md:py-12 gap-5 md:gap-10">
                  <div>
                    <img src={lionsMane} alt="" className="mx-auto" />
                    <p className="text-[20px] md:text-[24px] font-semibold leading-[31.2px] mt-[24px] mb-4 text-[#141414]">
                      Lion’s Mane
                    </p>
                    <p className="font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414]">
                      Lion's mane mushroom, also known as Hericium erinaceus, is
                      a type of edible mushroom that is native to North America,
                      Europe, and Asia. The mushroom is named for its unique
                      appearance, which resembles a lion's mane or a cluster of
                      white icicles. Lion's mane mushroom is prized for its
                      medicinal properties and is believed to have potential
                      benefits for the immune system, heart health, and
                      cognitive function.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1 text-center md:text-left">
                <div className="flex justify-between items-start mt-10 md:mt-12 py-5 md:py-12 gap-10">
                  <div>
                    <img
                      src={niacinMolecule}
                      alt=""
                      className="h-[130px] mx-auto"
                    />
                    <p className="text-[20px] md:text-[24px] font-semibold leading-[31.2px] mt-[24px] mb-4 text-[#141414]">
                      Niacin
                    </p>
                    <p className="font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414]">
                      Niacin (B-3) is a B vitamin that's made and used by your
                      body to turn food into energy. It helps keep your nervous
                      system, digestive system and skin healthy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center w-full md:w-2/3 m-auto mb-10 justify-center">
              <p className="mb-3 text-blue font-bold tracking-widest">
                Start your free 14-day trial today
              </p>
              <StartInput className="flex justify-center" />
            </div>
            <div>
              <div className="flex flex-col-reverse md:flex-row flex-nowrap items-center relative">
                <div>
                  <hr className="border-[#8D9494]" />
                  <p className="py-10 px-3 md:px-0 max-w-auto md:max-w-[1130px]">
                    Disclaimer: The content on this website and Aya products is
                    for informational and educational purposes only, and does
                    not substitute professional medical advice or consultation
                    with healthcare professionals. If you are seeking medical
                    advice, diagnose, or treatment, we advise you to consult a
                    medical professional or healthcare provider. Equally, we
                    cannot help with nor promote the sourcing of illegal
                    substances.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      {showAlert ? (
        <>
          <div className="bg-bone  text-white fixed drop-shadow-lg inset-0 z-50 overflow-y-auto w-[90%] h-[90%] md:w-[60%] md:h-[60%] m-auto border-white border-2 rounded-md">
            <div className="flex flex-col text-center mx-auto gap-4">
              <button
                className="bg-transparent text-5xl text-right outline-none focus:outline-none"
                onClick={acceptDisclaimer}
              >
                <span>×</span>
              </button>
              <img src={Logo} alt="Naya logo" className="w-[140px] mx-auto" />
              {under21 ? (
                <p className="text-2xl font-serif text-eastern-blue mb-6">
                  Sorry, you are not old <br />
                  enough to enter this website
                </p>
              ) : (
                <p className="text-2xl font-serif text-eastern-blue mb-6">
                  Please verify your age
                </p>
              )}
              <p className="font-serif text-eastern-blue text-xl">
                You must be 21 years or older to enter
              </p>
              {!under21 && (
                <>
                  <button
                    className="bg-eastern-blue rounded p-2 w-1/3 mx-auto z-3"
                    onClick={acceptDisclaimer}
                  >
                    I’m 21+
                  </button>
                  <button
                    onClick={() => setUnder21(true)}
                    className="bg-sky rounded p-2 w-1/3 mx-auto z-3"
                  >
                    I’m under 21
                  </button>
                </>
              )}
              <div className="w-1/2 text-center mx-auto">
                <p className="text-[10px] text-[#585757] uppercase font-bold tracking-widest	">
                  By continuing, you agree to NAYA’S{" "}
                  <a href="/terms" target="_blank">
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
            <img
              src={group}
              alt="group"
              className="absolute top-[50%] left-[50%] z-0"
            />
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
