import { useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import Recap from "../Recap";
import { UserState, StageTracking } from "../../../types";
import { Days } from "./";
import { getCurrentWeek } from "../../../utils/dates";
import musroomsSmall from "../../../assets/img/musrooms-small.png";
import { motion } from "framer-motion";

interface WeekDaysProps {
  protocol: string;
  daysType: Days;
  startingDate: string;
  userState: UserState;
}

const WeekDays = ({
  protocol,
  daysType,
  startingDate,
  userState,
}: WeekDaysProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDayState, setSelectedDayState] = useState<StageTracking>();
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeek());

  // Convert the startingDate to a Moment object
  const startDate = moment(startingDate);

  const moveWeek = (forward: boolean) => {
    const newWeekStart = forward
      ? moment(currentWeek[0]).add(1, "week")
      : moment(currentWeek[0]).subtract(1, "week");

    // Check if the new week start is after the starting date
    if (newWeekStart.isAfter(startDate, "day")) {
      setCurrentWeek(getWeekDays(newWeekStart));
    }
  };

  const getWeekDays = (weekStart: moment.Moment) => {
    const week = [];
    for (let i = 0; i < 7; i++) {
      const day = moment(weekStart).add(i, "days");
      week.push(day);
    }
    return week;
  };

  const selectDay = (day: moment.Moment) => {
    const parseDay = moment(day).format("YYYY-MM-DD");
    const selected = { ...userState.stage_tracking[parseDay] };
    setSelectedDayState({ ...selected, date: parseDay });
    setIsDialogOpen(true);
  };

  const currentMonthYear = moment(currentWeek[0]).format("MMMM YYYY");
  return (
    <>
      <div className="mb-3 text-center text-lg">{currentMonthYear}</div>
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 20 }}
        transition={{ duration: 0.3 }}
        className="flex justify-between"
      >
        {currentWeek.map((day) => {
          const isToday = day.isSame(moment(), "day");
          const d = moment(day).format("ddd");
          const isMicrodosingDay = daysType[moment(day).format("YYYY-MM-DD")];
          return (
            <div
              onClick={() => selectDay(day)}
              className={` ${
                isToday && "bg-[#bddce2]"
              } text-center p-2 rounded-[1.5rem] cursor-pointer`}
              key={d}
            >
              <div className="week-day text-sm pb-5">{d}</div>
              {isMicrodosingDay ? (
                <div className="relative">
                  <img
                    src={musroomsSmall}
                    alt="musrooms"
                    className="absolute -bottom-1"
                  />
                  <div className="bg-[#c5ebd2] w-[24px] h-[24px] rounded-full m-auto"></div>
                </div>
              ) : (
                <div className="bg-gray w-[24px] h-[24px] rounded-full m-auto"></div>
              )}

              <p className="text-xs pt-3">{moment(day).format("DD")}</p>
            </div>
          );
        })}
      </motion.div>
      <div className="flex justify-between py-5 items-center">
        <button onClick={() => moveWeek(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 448 512"
          >
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
          </svg>
        </button>
        <button
          className="text-xs bg-primary text-white px-2 py-1 rounded-xl"
          onClick={() => setCurrentWeek(getCurrentWeek())} // Go to current week
        >
          Today Week
        </button>
        <button onClick={() => moveWeek(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 448 512"
          >
            <path d="M438.6 278.6c12.5-12.5-12.5-32.8-0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
          </svg>
        </button>
      </div>
      <Recap
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        selectedDayState={selectedDayState ?? null}
      />
    </>
  );
};

export default WeekDays;
