/*eslint-disable*/
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import StartModal from "../UiElements/Modals/StartModal";
import logo from "../../assets/img/logo.png";
import logoWhite from "../../assets/img/logo-w.png";
import { IoMenu } from "react-icons/io5";

interface NavbarProps {
  transparent?: boolean;
  logoW?: boolean;
}
export default function Navbar(props: NavbarProps) {
  const locatoin = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const userParams = locatoin.pathname ? locatoin.pathname?.split("/") : [];
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <>
      <nav className="top-0 absolute z-3 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="h-[75px] z-8 w-full relative top-2">
          <div className="container mx-auto h-full flex justify-between items-center">
            <div className="logo flex items-center">
              <Link to={"/"}>
                <img
                  className="w-[128px]"
                  src={props.logoW ? logoWhite : logo}
                  alt="naya-logo"
                />
              </Link>
            </div>

            <div className="right-side">
              <ul className="items-center text-sm tracking-wide space-x-[24px]  hidden md:flex">
                <li>
                  <Link to={"/about-us"}>
                    <a
                      className={
                        userParams[1] == "about-us"
                          ? "text-red"
                          : "text-primary"
                      }
                    >
                      About Us
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to={"/faq"}>
                    <a
                      className={
                        userParams[1] == "faq" ? "text-red" : "text-primary"
                      }
                    >
                      FAQ
                    </a>
                  </Link>
                </li>

                <li>
                  <Link to={"/chat"}>
                    <a
                      className={
                        userParams[1] == "chat" ? "text-red" : "text-primary"
                      }
                    >
                      Chat
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to={"/journal"}>
                    <a
                      className={
                        userParams[1] == "journal" ? "text-red" : "text-primary"
                      }
                    >
                      Log In
                    </a>
                  </Link>
                </li>
                <li className="pl-4">
                  <Link to={"/auth"}>
                    <a className="px-4 py-3 border-2 border-primary rounded-lg uppercase font-bold text-primary">
                      Get Started
                    </a>
                  </Link>
                </li>
              </ul>

              {/* Mobile menu */}
              <div className="md:hidden pr-3">
                <button>
                  <IoMenu
                    size={32}
                    onClick={() => setNavbarOpen(!navbarOpen)}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {navbarOpen && (
          <div className="absolute z-10 md:relative top-[0px] pt-10 left-0 md:left-auto md:top-auto w-full shadow-xl bg-amber">
            {/* Menu items */}
            <div className="logo flex items-center pb-10 justify-center">
              <Link to={"/"}>
                <img className="w-[128px]" src={logo} alt="naya-logo" />
              </Link>
            </div>
            <ul className="text-xl text-center tracking-wide space-y-8 flex flex-col pt-18 pb-5 w-full  md:hidden">
              <Link to={"/about-us"}>
                <a className="text-primary">About Us</a>
              </Link>
              <li>
                <Link to={"/faq"}>
                  <a className="text-primary">FAQ</a>
                </Link>
              </li>
              <li>
                <Link to={"/journal"}>
                  <a className="text-primary">Log In </a>
                </Link>
              </li>
              <li className="py-5">
                <Link to={"/auth"}>
                  <a className="bg-primary w-full text-white font-bold text-base md:text-sm px-8 py-4 rounded">
                    Get started
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </>
  );
}
