import { UserType } from "../../../types";
import { useState } from "react";
import { SelectedProtocol } from "./SelectedProtocol";
import everyOtherDay from "../../../assets/img/protocol/every_other_day.svg";
import fadiman from "../../../assets/img/protocol/fadiman.svg";
import nayaProtocol from "../../../assets/img/protocol/naya_protocol.svg";
import stacking from "../../../assets/img/protocol/stacking.svg";

interface Protocol {
  key: string;
  name: string;
  info: string;
  img?: string;
  commingSoon?: boolean;
}

const Protocols: Protocol[] = [
  {
    key: "fadiman",
    name: "The Fadiman Protocol",
    info: "This protocol was designed by psychologist Dr. James Fadiman ️who has been researching psychedelics since the sixties It is one of the most common microdosing protocolsIn short, when following this protocol you will microdose one day, and then rest for two days",
    img: fadiman,
  },
  {
    key: "other_day",
    name: "Every Other Day Protocol",
    info: "The 'Every Other Day' protocol alternates between a microdosing day and a transition day. While some believe it optimizes benefits and reduces tolerance, there's no definitive proof of such results. Despite its intuitive nature, its shifting dosing days might not align with everyone's lifestyle, much like the Fadiman protocol. However, for those who embrace its rhythm, it offers a promising avenue to explore the potential benefits of microdosing",
    img: everyOtherDay,
  },
  {
    key: "naya",
    name: "The Naya Protocol",
    info: "The Naya protocol offers a consistent microdosing schedule by designating two fix days each week for dosing, seamlessly integrating into your weekly rhythm and lifestyle for enhanced well-being Chosen by many Naya users, it balances microdosing benefits with daily life predictability",
    img: nayaProtocol,
    commingSoon: true,
  },
  {
    key: "stamets",
    name: "Stamets Stacking Protocol",
    info: "The 'Stamets Stacking' protocol blends psilocybin mushrooms, lion's mane, and niacin for four microdosing days, followed by three rest days. Aimed at boosting neuroplasticity, its benefits, though promising, await clinical validation. It's a compelling choice for those seeking a deeper dive into microdosing",
    img: stacking,
    commingSoon: true,
  },
];

export const ProtoccolSelection = ({
  user,
  close,
}: {
  user: UserType;
  close: () => void;
}) => {
  const [selectedProtocol, setSelectProtocol] = useState<null | Protocol>();

  return (
    <>
      <button
        onClick={() => (selectedProtocol ? setSelectProtocol(null) : close())}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          className="h-5 w-5"
        >
          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
      </button>
      {selectedProtocol ? (
        <SelectedProtocol protocol={selectedProtocol} close={close} />
      ) : (
        <div className="text-center w-2/3 justify-center m-auto">
          <p className="text-eastern-blue text-center text-xl mb-3">
            My Microdosing Protocol
          </p>
          <hr className="border-gray-light mb-8" />
          <p className="text-primary mb-2 font-bold text-center">
            My current Protocol
          </p>
          <div className=" rounded-xl px-4 py-3 justify-between items-center text-primary bg-cyan-blue text-center">
            {Protocols.find((protocol) => protocol.key === user.protocol)?.name}
          </div>
          <div className="mt-24">
            <p className="text-primary mb-3 font-bold">I want to change to</p>

            <div>
              {Protocols.map(
                (protocol) =>
                  protocol.key !== user.protocol && (
                    <div
                      onClick={() =>
                        protocol.commingSoon
                          ? null
                          : setSelectProtocol(protocol)
                      }
                      className="bg-cream-light relative rounded-xl px-4 py-3 justify-between items-center text-primary text-center mb-4 hover:bg-mistry-purple cursor-pointer hover:text-white"
                      key={protocol.key}
                    >
                      <p>{protocol.name}</p>
                      {protocol.commingSoon && (
                        <span className="text-[10px] rounded ">
                          Comming Soon
                        </span>
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
