interface ProgressBarProps {
  progressPercentage: string;
  protocolDay: number;
}

const ProgressBar = ({ progressPercentage, protocolDay }: ProgressBarProps) => {
  return (
    <div className="h-[21px] w-full bg-[#bddce2] rounded-2xl relative flex items-center">
      <div
        style={{ width: `${progressPercentage}%` }}
        className="rounded-2xl w-full h-full bg-[#7cc1d4] text-xs px-2 flex items-center"
      >
        <span className="absolute top-[2px] text-white font-bold">
          Day {protocolDay}
        </span>
      </div>
      <span className="absolute right-2 text-xs text-white font-bold">60</span>
    </div>
  );
};

export default ProgressBar;
