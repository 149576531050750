import React, { useState } from "react";
import { supabase } from "../../lib/supabaseClient";
const { REACT_APP_CLIENT_URL } = process.env;
type StartFormProps = {
  from?: string;
  email?: string;
};

const StartForm = ({ from, email }: StartFormProps) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({
    name: "",
    platform: "",
    email: email || "",
    how: "",
    region: "",
    acceptTerms: false,
    newsletter: true,
  });

  const handler = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
    name: string
  ): void => {
    setData({
      ...data,
      name: e.currentTarget.value,
    });
  };

  const submitForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const { email, how, platform, region, name, newsletter } = data;

    if (email === "") {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: `${REACT_APP_CLIENT_URL}/journal`,
          data: {
            name: name,
          },
        },
      });
      console.log(data);
      if (error) throw error;
      window.analytics.identify(email, {
        email,
        how,
        platform,
        name,
        agree_to_newsletter: newsletter,
        user_agent: window.navigator.userAgent,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        from: "website_popup_sign_up",
      });

      window.analytics.track("website_popup_sign_up");
      setSuccess(true);
    } catch (error: any) {
      setSuccess(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }

    // const date = new Date().toLocaleDateString();
    // await axios.get(
    //   "https://script.google.com/macros/s/AKfycbwKAek0nWlROvITn2Wy7e2zp5sKfr-uvyeBh5So-2DK4V5A0EcqKyOq0-erpQccaLKATw/exec",
    //   {
    //     params: {
    //       from: from ? from : "Contact",
    //       date,
    //       email,
    //       how,
    //       platform,
    //       name,
    //       region,
    //       who,
    //     },
    //   }
    // );

    // setData({
    //   ...data,
    //   name: "",
    //   email: "",
    //   how: "",
    //   region: "",
    //   acceptTerms: false,
    //   who: "",
    // });
    // setLoading(false);
    // setSuccess(true);
    // const url = `https://t.me/With_Naya_Bot?email=${btoa(email)}`;
    // // const url = `https://t.me/aya_nir_bot?start=${btoa(email)}`;
    // window.open(encodeURI(url), "_blank", "noreferrer");
  };

  return (
    <>
      {!success ? (
        <div className="flex flex-col gap-3 md:gap-4 ">
          <div className="py-2 md:pt-0">
            <h1 className="text-[26px] md:text-[42px] pb-2 mt-2 font-serif text-primary text-center">
              Welcome to Naya
            </h1>
            <hr className="text-gray-light my-2" />
            <p className="text-xl mb-2 pl-4">
              You’re just a step away from starting to microdosing with
              confidence
            </p>
            <p className="text-xl mb-2 pl-4">
              Let’s start with a few simple steps
            </p>
          </div>

          <div className="w-full mr-8">
            <input
              type="text"
              placeholder="Name"
              onChange={(v) => setData({ ...data, name: v.target.value })}
              value={data.name}
              className="border-0 px-4 py-[14px] text-[16px] md:text-[18px] md:py-[12px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded-md  focus:outline-none focus:ring w-full ease-linear
                transition-all
                duration-150
                {loading ? bg-green : '' }"
            />
          </div>
          <div className="w-full mr-8">
            <input
              type="email"
              placeholder="Email"
              onChange={(v) => setData({ ...data, email: v.target.value })}
              value={data.email}
              className="border-0 px-4 py-[14px] text-[16px] md:text-[18px] md:py-[12px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded-md  focus:outline-none focus:ring w-full ease-linear
                transition-all
                duration-150
                {loading ? bg-green : '' }"
            />
          </div>

          <div className="w-full mr-8">
            <select
              onChange={(v) => setData({ ...data, how: v.target.value })}
              name="how"
              className="border-0 px-4 py-[14px] text-[16px] md:text-[18px] md:py-[12px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded focus:outline-none focus:ring w-full ease-linear transition-all duration-150 {loading ? bg-green : '' }"
            >
              <option className="placeholder-blueGray-300 text-gray-300">
                How did you hear about Naya?
              </option>
              <option value="Search Engine">
                Search Engine (Google, Bing, ...)
              </option>
              <option value="Social Media">
                Social Media (Instagram, LinkedIn Facebook, ...)
              </option>
              <option value="A Psychedelic Community">
                A Psychedelic Community
              </option>
              <option value="Friend Recommendation">
                A Friend Recommendation
              </option>
              <option value="Online Event">Online Event</option>
              <option value="Conference">Conference</option>
            </select>
          </div>

          <label className="w-full mr-8 flex items-center">
            <input
              type="checkbox"
              checked={data.newsletter}
              onChange={(v) =>
                setData({ ...data, newsletter: v.target.checked })
              }
              className="mr-2"
            />
            Sign me up to the Naya’s newsletter
          </label>
          <div className="text-center">
            <button
              className="bg-[#05053C] m-auto text-white text-[18px] md:text-[18px] px-6 py-[18px] md:py-[12px] rounded-md hover:shadow-lg transition-all duration-150 w-full md:w-full m-auto"
              type="button"
              onClick={submitForm}
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin h-[32px] w-[68px] m-auto"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.1,16c-0.3-0.5-0.9-0.6-1.4-0.4c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.9,0.6,1.4,0.4C5.2,17.1,5.3,16.5,5.1,16C5.1,16,5.1,16,5.1,16z M4.7,6.6C4.2,6.4,3.6,6.5,3.3,7C3.1,7.5,3.2,8.1,3.7,8.4C4.2,8.6,4.8,8.5,5.1,8C5.3,7.5,5.2,6.9,4.7,6.6z M20.3,8.4c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4c-0.5,0.3-0.6,0.9-0.4,1.4C19.2,8.5,19.8,8.6,20.3,8.4z M4,12c0-0.6-0.4-1-1-1s-1,0.4-1,1s0.4,1,1,1S4,12.6,4,12z M7.2,18.8c-0.5,0.1-0.9,0.7-0.7,1.2c0.1,0.5,0.7,0.9,1.2,0.7c0.5-0.1,0.9-0.7,0.7-1.2C8.3,19,7.8,18.7,7.2,18.8z M21,11c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S21.6,11,21,11z M20.3,15.6c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4c0.5,0.3,1.1,0.1,1.4-0.4c0,0,0,0,0,0C20.9,16.5,20.8,15.9,20.3,15.6z M17,3.3c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4c0.5,0.3,1.1,0.1,1.4-0.4c0,0,0,0,0,0C17.6,4.2,17.5,3.6,17,3.3z M16.8,18.8c-0.5-0.1-1.1,0.2-1.2,0.7c-0.1,0.5,0.2,1.1,0.7,1.2c0.5,0.1,1.1-0.2,1.2-0.7C17.6,19.5,17.3,19,16.8,18.8z M12,20c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S12.6,20,12,20z M12,2c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S12.6,2,12,2z"
                    fill="#fff"
                  />
                </svg>
              ) : (
                <span>Start my free trial</span>
              )}
            </button>
          </div>
          <div className="flex justify-center md:flex-start mt-2">
            <p className="text-[10px] text-[#585757] uppercase pt-4 md:pt-0 w-[90%] md:w-full font-bold tracking-widest	">
              By continuing, you agree to NAYA’S{" "}
              <a href="/terms" target="_blank">
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div className="text-center flex flex-col justify-center items-center">
          <h4 className="text-xl font-semibold text-blue my-8">
            Thank you for joining the Naya family!
          </h4>
          <p className="text-4xl font-semibold text-blue mb-8">
            Check your email for the login link!
          </p>
          <p className="text-xl mb-4">
            {" "}
            You're welcome to join our growing community channels on{" "}
            <a
              href="https://t.me/+apF-W0ejF_AzZmM0"
              target="_blank"
              className="text-red"
              rel="noreferrer"
            >
              Telegram
            </a>{" "}
          </p>
          <img
            alt="..."
            className="w-30"
            src={require("../../assets/img/butterfly1.png")}
          />
        </div>
      )}
    </>
  );
};

export default StartForm;
