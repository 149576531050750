import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footers/Footer";
import { VerifyOtp } from "../views/Auth";
import logo from "../assets/img/logo-blue.png";
import patooLogo from "../assets/img/patoo/logo-blue.png";
import x from "../assets/img/patoo/x.png";
import joinPatoo from "../assets/img/patoo/join.png";
import forfree from "../assets/img/patoo/for-free.png";
import lady from "../assets/img/patoo/lady.png";
import offer from "../assets/img/patoo/offer.png";
import patooButton from "../assets/img/patoo/button.png";

import flowersButterfly from "../assets/img/flowers-butterfly.png";

import mobileContent from "../assets/img/mobile-content.png";
import mushroomFly from "../assets/img/mushroom-fly.png";
import cloudLeft from "../assets/img/cloud-left.png";
import cloudRight from "../assets/img/cloud-right.png";
import bgPsilocybinMolecule from "../assets/img/bg-psilocybin-molecule.png";
import { supabase } from "../lib/supabaseClient";

const { REACT_APP_CLIENT_URL } = process.env;

export default function PsLanding() {
  // const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ email: string }>({ email: "" });

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setErrorMsg("");
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!data.email.match(regex)) return setErrorMsg("Invalid email address");
    const { email } = data;
    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: `${REACT_APP_CLIENT_URL}/journal`,
          data: {
            ref: "withpatoo",
          },
        },
      });
      if (error) throw error;
      window.analytics.identify(email, {
        email,
        user_agent: window.navigator.userAgent,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        from: "withpatoo",
      });

      window.analytics.track("signed_up_patoo");
      setSuccess(true);
    } catch (error: any) {
      setSuccess(false);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   navigate("/");
  // });
  return (
    <>
      <main className="bg-floral overflow-hidden">
        <div className="relative w-full ">
          <div className="h-[191px] w-full absolute bg-white md:bg-gradient-to-b from-[#9900CC] via-purple-400 to-transparent" />
          <div className="container mx-auto z-10 relative">
            <div className="text-center pt-0 md:pt-2">
              <p className="text-xl md:text-5xl uppercase text-white mb-6 py-2 md:pt-10 bg-[#a72ad1] md:bg-inherit">
                Start Microdosing
              </p>
              <p className="text-gray  md:text-2xl font-bold">WITH</p>
            </div>
            <div className="flex gap-1 md:gap-4 justify-center items-center pt-2 px-2">
              <img
                src={logo}
                alt="logo"
                className="mx-auto mx-left md:mx-0 w-1/3 md:w-[160px]"
              />
              <img src={x} alt="logo" className=" " />
              <img
                src={patooLogo}
                alt="logo"
                className="mx-auto mx-left md:mx-0 w-1/3 md:w-[160px]"
              />
            </div>
            {!success ? (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 md:flex-row flex-nowrap mb-24 items-center justify-center text-center px-2">
                  <div className="flex flex-col gap-12 pt-10">
                    <div className="relative mb-10">
                      <img src={joinPatoo} alt="join" className="md:mx-auto" />
                      <img
                        src={forfree}
                        alt="for-free"
                        className="mx-auto absolute right-2 h-2/3 md:right-[-120px] md:bottom-[-72px] md:h-[85px]"
                      />
                    </div>
                    <div className="pt-10 hidden md:block">
                      <img src={offer} alt="offer" className="mx-auto" />
                    </div>
                  </div>
                  <div>
                    <img src={lady} alt="lady" className="mx-auto" />
                  </div>
                </div>
                <div className="flex mt-10 justify-center">
                  <div className="flex items-center relative w-full md:w-1/2 flex-col md:flex-row px-2">
                    <input
                      onChange={(v) =>
                        setData({ ...data, email: v.target.value })
                      }
                      value={data.email}
                      type="email"
                      className="px-3 py-2 rounded  border-none h-[64px] w-full md:w-3/4 drop-shadow-lg placeholder:text-gray text-xl text-center outline-none focus:outline-none"
                      placeholder="Email address"
                    />
                    <button
                      onClick={submit}
                      disabled={loading}
                      className="relative md:absolute right-0 mt-2 md:mt-0"
                    >
                      <img
                        src={patooButton}
                        alt="button"
                        className="h-full hover:bg-opacity-20 transition duration-300 hover:scale-95 active:scale-90 "
                      />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center mt-10">
                <VerifyOtp email={data.email} signLoading={loading} />
              </div>
            )}
            {errorMsg && (
              <p className="text-red text-center mt-2">{errorMsg}</p>
            )}
            <div className="flex justify-center md:flex-start pt-10 text-center">
              <p className="text-[10px] text-[#585757] uppercase pt-4 md:pt-0 w-[90%] md:w-2/3 font-bold tracking-widest	">
                By continuing, you agree to NAYA’S{" "}
                <a href="/terms" target="_blank">
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>

        <section className="relative bg-[#539BAD] px-4 md:px-0 mb-24 md:mb-10 md:mt-24 overflow-hidden">
          <div className="overflow-hidden md:flex hidden z-1">
            <div className="absolute left-0 top-[15%] max-w-[400px]">
              <img src={cloudLeft} alt="Naya-cloud" />
            </div>
            <div className="absolute right-0 top-0 max-w-[400px]">
              <img src={cloudRight} alt="Naya-cloud" />
            </div>
          </div>
          <div className="container mx-auto relative pt-10">
            <div className="flex gap-24 justify-center items-center">
              <img
                src={mobileContent}
                alt="mobileContent"
                className="z-9 w-1/5 hidden md:block"
              />
              <div className="pt-0 text-center md:text-left">
                <h1 className="text-[36px] text-white sm:text-[44px] md:text-[42px] leading-10 mb-[26px] font-serif">
                  A new way to get <br />
                  closer to yourself
                </h1>
                <p className="font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-white">
                  Be reminded on your favorite app <br />
                  Track what matters to you
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="px-4 md:px-0">
          <div className="container relative mx-auto">
            <div className="grid grid-cols-2 gap-24 mb-24 items-center justify-items-center">
              <div className="col-span-2 md:col-span-1">
                <p className="tracking-[-0.04em] text-red text-[20px] md:text-[28px] font-serif mb-3">
                  Self-paced microdosing programs
                </p>
                <h1 className="text-blue text-[36px] sm:text-[44px] md:text-[42px] leading-10	font-serif">
                  Designed to support
                  <br className="hidden md:block" /> all levels of experience
                </h1>
                <p className="text-[18px] md:text-[20px] md:leading-[125%] mt-3 md:mt-[25px] ">
                  Naya was developed to support you with all the information you
                  need to <span className="text-red">get started today</span>{" "}
                  and <span className="text-red">follow</span> through whether
                  your microdosing for the first time or been doing it for years
                </p>
              </div>
              <div className="col-span-2 md:col-span-1 -mt-22">
                <img src={flowersButterfly} alt="" />
              </div>
            </div>
            <div className="relative grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-24 mb-24 items-center justify-items-center">
              <div className="col-span-2 md:col-span-1 auto md:-mt-22 relative order-3 md:order-1">
                <img
                  src={mushroomFly}
                  alt="maitake"
                  className="relative z-2 w-[85%] m-auto"
                />
                <img
                  src={bgPsilocybinMolecule}
                  alt="bgPsilocybinMolecule"
                  className="absolute top-[25%] z-1 right-10 opacity-[0.1]"
                />
              </div>
              <div className="col-span-2 md:col-span-1 relative order-2 md:order-2">
                <p className="tracking-[-0.04em] text-red text-[20px] md:text-[28px] font-serif mb-3">
                  Your personal guide to
                </p>
                <h1 className="text-blue text-[36px] sm:text-[44px] md:text-[42px] leading-10	font-serif">
                  Unlock the full <br className="hidden md:block" />
                  potential of your <br className="hidden md:block" />
                  microdosing practice
                </h1>
                <p className="text-[18px] md:text-[20px] md:leading-[125%] mt-3 md:mt-[25px] ">
                  Access information. Get support. Join a community.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="relative px-4 md:px-0 mb-24">
          <div className="container md:mt-[60px] mx-auto z-1">
            <div className="mt-10 flex justify-center flex-col relative">
              <p className="tracking-[-0.04em] text-red text-[20px] md:text-[28px] font-serif text-center mb-3">
                The benefits
              </p>
              <h1 className="text-blue text-[36px] sm:text-[44px] md:text-[42px] leading-10	mb-[28px] md:mb-[72px] font-serif text-center">
                Why Microdosing?
              </h1>
              <div className="grid grid-cols-2 gap-2 md:gap-10">
                <div className="col-span-2 md:col-span-1 font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414]">
                  Microdosing is the practice of consuming a very small amount
                  of a psychedelic substance with the intention to{" "}
                  <span className="text-red">
                    improve the quality of your life.
                  </span>{" "}
                  Think of it as a holistic supplement. The effect is meant to
                  be “sub-perceptual,” or barely noticeable. And so the aim is
                  not to achieve a transcendental state but to support daily
                  activities within work, school, and social environments.
                </div>
                <div className="col-span-2 md:col-span-1 font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414]">
                  Microdosing has been reported to improve creativity, reduce
                  stress, boost mood, and facilitate flow states. It is also
                  noted that benefits are cumulative, and{" "}
                  <span className="text-red">increase over time</span>.
                  <br />
                  <br />
                  Check out our <a href="/faq">FAQs</a> to learn if microdosing
                  is right for you.
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
