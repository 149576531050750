import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../../contexts/AuthProvider";
import Loading from "../UiElements/Loading";
import OnBoardingWelcome from "./OnBoardingWelcome";
import OnBoardingWelcomePatoo from "./OnBoardingWelcomePatoo";
import { supabase } from "../../lib/supabaseClient";
import TypeFormOnBoarding from "./TypeFormOnBoarding";
import { UserType } from "../../types";
import Animation from "../../assets/img/animation.gif";
import fadiman from "../../assets/img/protocol/fadiman.svg";
interface OnBoardingProps {
  user: UserType;
}

type OnboardingStepType =
  | "finished_onboarding"
  | "onboarding"
  | "payment"
  | "protocol_on_boarding"
  | "paid"
  | null;

const OnBoarding = ({ user }: OnBoardingProps) => {
  const { getUser, setUser } = useAuth();
  const [onboardingStep, setOnboardingStep] = useState<OnboardingStepType>(
    user.onboarding_step
  );
  const [startOnboarding, setStartOnboarding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [counterOnboardingRequest, setCounterOnboardingRequest] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const chatRef = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    chatRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    setOnboardingStep(user.onboarding_step);
    scrollToBottom();
  }, []);

  useEffect(() => {
    if (user.onboarding_step === "finished_onboarding") {
      setTimeout(async () => {
        window.analytics.identify(user.email, {
          intention: user.intention,
          substance: user.medicine_type,
          name: user.user_name,
        });
        setUser({ ...user, onboarding_step: "protocol_on_boarding" });
        setOnboardingStep("protocol_on_boarding");
        window.analytics.track("viewed_matched_protocol");
      }, 7000);
    }
  }, [user.onboarding_step]);

  const submitOnboardingForm = () => {
    setIsLoading(true);
    setTimeout(async () => {
      const u = await getUser();
      const onboarding_step = u?.onboarding_step;
      if (!onboarding_step && counterOnboardingRequest < 3) {
        const counter = counterOnboardingRequest + 1;
        setCounterOnboardingRequest(counter);
        submitOnboardingForm();
      }
      setIsLoading(false);
      setOnboardingStep(onboarding_step);
      window.analytics.track("finished_tf_onboarding");
    }, 2000);
  };

  const startSubscription = async () => {
    setIsLoading(true);
    setErrorMessage("");
    const { data, error } = await supabase.functions.invoke(
      "subscription-actions",
      {
        body: {
          userId: user.id,
          reqMethod: "create",
          email: user.email,
          name: user.user_name,
        },
      }
    );
    if (error) {
      setErrorMessage(
        "We're sorry, but there was an issue setting up your free trail, try again later"
      );
      setIsLoading(false);
      return;
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await supabase.functions.invoke("user-action", {
      body: { user_id: user.id, timezone, method: "active_new_user" },
    });
    await getUser();
    setIsLoading(false);
    window.analytics.identify(user.email, {
      intention: user.intention,
      substance: user.medicine_type,
      name: user.user_name,
      notification_time: user.user_name,
      protocol: user.protocol,
      time_zone: timezone,
      id: user.id,
    });
  };

  // Define exit and entry animations
  const exitAnimation = { opacity: 0, x: -50 };
  const entryAnimation = { opacity: 1, x: 0 };

  return (
    <AnimatePresence mode="sync">
      {onboardingStep === "onboarding" && (
        <motion.div
          key="onboardingStep-null"
          initial={exitAnimation}
          animate={entryAnimation}
          exit={exitAnimation}
        >
          {user.ref === "withpatoo" ? (
            <OnBoardingWelcomePatoo
              open={true}
              setStartOnboarding={() => setStartOnboarding(true)}
            />
          ) : (
            <OnBoardingWelcome
              open={true}
              setStartOnboarding={() => setStartOnboarding(true)}
            />
          )}

          <div className="py-3 px-2 md:px-10">
            {startOnboarding && !isLoading ? (
              <div
                className=" max-h-[66vh] h-full text-center flex justify-center items-center w-ull flex-col"
                ref={chatRef}
              >
                <TypeFormOnBoarding
                  email={user.email}
                  user_id={user.id}
                  onSubmit={() => submitOnboardingForm()}
                  userRef={user.ref}
                />
              </div>
            ) : (
              <div className="justify-center flex mt-10">
                <Loading loading={!isLoading} />
              </div>
            )}
          </div>
        </motion.div>
      )}
      {onboardingStep === "finished_onboarding" && (
        <motion.div
          key="onboardingStep-finished"
          initial={exitAnimation}
          animate={entryAnimation}
          exit={exitAnimation}
        >
          <div className="py-2 md:py-4 px-10">
            <div className=" h-full min-h-[380px] flex justify-center items-center">
              <div>
                <p className="text-2xl mb-4 text-center">
                  Hang tight!
                  <br />
                  I’m matching you with a<br /> microdosing protocol
                </p>
                <hr className="text-gray-light" />
                <img src={Animation} alt="animation" />
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {onboardingStep === "protocol_on_boarding" && (
        <motion.div
          key="onboardingStep-protocol"
          initial={exitAnimation}
          animate={entryAnimation}
          exit={exitAnimation}
        >
          {" "}
          <div className="pb-8 px-10 jusitfy-center text-center">
            <div className="h-full min-h-[140px] flex justify-center items-center">
              <p className="text-3xl text-center text-blue">
                The Fadiman Protocol
              </p>
            </div>
            <img
              src={fadiman}
              alt="Fadiman"
              className="w-2/3 md:w-1/2 m-auto mb-10"
            />
            <button
              disabled={isLoading}
              onClick={() => {
                window.analytics.track("pressed_start");
                startSubscription();
              }}
              className={`w-3/4 md:1/3 bg-[#05053C] text-white rounded-md text-[18px] px-4 py-[8px] disabled:opacity-75`}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-[32px] w-[68px] m-auto"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.1,16c-0.3-0.5-0.9-0.6-1.4-0.4c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.9,0.6,1.4,0.4C5.2,17.1,5.3,16.5,5.1,16C5.1,16,5.1,16,5.1,16z M4.7,6.6C4.2,6.4,3.6,6.5,3.3,7C3.1,7.5,3.2,8.1,3.7,8.4C4.2,8.6,4.8,8.5,5.1,8C5.3,7.5,5.2,6.9,4.7,6.6z M20.3,8.4c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4c-0.5,0.3-0.6,0.9-0.4,1.4C19.2,8.5,19.8,8.6,20.3,8.4z M4,12c0-0.6-0.4-1-1-1s-1,0.4-1,1s0.4,1,1,1S4,12.6,4,12z M7.2,18.8c-0.5,0.1-0.9,0.7-0.7,1.2c0.1,0.5,0.7,0.9,1.2,0.7c0.5-0.1,0.9-0.7,0.7-1.2C8.3,19,7.8,18.7,7.2,18.8z M21,11c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S21.6,11,21,11z M20.3,15.6c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4c0.5,0.3,1.1,0.1,1.4-0.4c0,0,0,0,0,0C20.9,16.5,20.8,15.9,20.3,15.6z M17,3.3c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4c0.5,0.3,1.1,0.1,1.4-0.4c0,0,0,0,0,0C17.6,4.2,17.5,3.6,17,3.3z M16.8,18.8c-0.5-0.1-1.1,0.2-1.2,0.7c-0.1,0.5,0.2,1.1,0.7,1.2c0.5,0.1,1.1-0.2,1.2-0.7C17.6,19.5,17.3,19,16.8,18.8z M12,20c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S12.6,20,12,20z M12,2c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S12.6,2,12,2z"
                    fill="#fff"
                  />
                </svg>
              ) : (
                <span>Start microdosing</span>
              )}
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OnBoarding;
