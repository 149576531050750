import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { supabase } from "../../../lib/supabaseClient";
import MoodCheck from "../Journal/MoodCheck";
import { useAuth } from "../../../contexts/AuthProvider";

const ReportMood = () => {
  const [mood, setMood] = useState<number>(0);
  const [substance, setSubstance] = useState("Psilocybin Mushrooms");
  const { user } = useAuth();
  const addMood = async () => {
    const currentDate = moment().format("YYYY-MM-DD"); // Get the current date in the desired format
    const stageTracking = user?.state.stage_tracking || {};
    stageTracking[currentDate] = {
      ...stageTracking[currentDate],
      mood: mood,
    };
    await supabase
      .from("user_state")
      .update({ stage_tracking: stageTracking })
      .eq("user_id", user?.id);
    toast("Your daily journal practice is saved.");
    window.analytics.track("new_journal_entrie");
  };
  return (
    <>
      <p className="text-purple font-bold text-xl text-center">Log your mood</p>
      <hr className="my-4 text-purple-light" />
      <p className="text-center text-purple mb-6">How are you feeling today?</p>
      <MoodCheck mood={mood} updateMood={(v) => setMood(v)} />
      <button
        className="flex m-auto bg-purple text-white rounded-lg px-4 py-1 mt-8"
        onClick={() => addMood()}
      >
        Add
      </button>
    </>
  );
};

export default ReportMood;
