import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import leftArrowImage from "../../../assets/img/left_arrow.svg";
import rightArrowImage from "../../../assets/img/right_arrow.svg";
import slideImage1 from "../../../assets/img/slide_1.png";
import slideImage2 from "../../../assets/img/slide_2.png";
import slideImage3 from "../../../assets/img/slide_3.png";

export default function Footer() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const LeftArrow = ({ onClick }: any) => {
    return (
      <div
        onClick={onClick}
        className={`hidden md:block slider-arrow cursor-pointer absolute bottom-0 left-[20%] md:left-[41%]`}
      >
        <img src={leftArrowImage} alt="Left arrow" className="left-arrow" />
      </div>
    );
  };

  const RightArrow = ({ onClick }: any) => {
    return (
      <div
        onClick={onClick}
        className={`hidden md:block slider-arrow cursor-pointer absolute bottom-0 right-[20%] md:right-[44%]`}
      >
        <img src={rightArrowImage} alt="Left arrow" className="left-arrow" />
      </div>
    );
  };

  const paging = (number: any) => {
    return (
      <div className="h-[18px] w-[18px] rounded-full m-0 opacity-10"></div>
    );
  };

  return (
    <>
      <Slider
        {...settings}
        className="overflow-hidden justify-center flex"
        prevArrow={<LeftArrow />}
        nextArrow={<RightArrow />}
        customPaging={paging}
        dotsClass="slick-dots sleft-0  md:left-[-18px]"
      >
        <div>
          <div className="mb-24">
            <div className="grid grid-cols-10 gap-5">
              <div className="col-span-10 px-4 md:col-span-5 relative max-w-[300px] m-auto md:max-w-none md:m-0">
                <img src={slideImage1} alt="" />
              </div>
              <div className="col-span-10 md:col-span-5 flex items-center justify-center">
                <div className="relative z-[1]">
                  <p className="text-red text-2xl leading-[30.2px] mb-[29px] font-serif text-center md:text-left ">
                    About the practice
                  </p>
                  <h1 className="text-blue text-[28px] sm:text-[44px] md:text-[51px] leading-[56.1px] mb-[26px] font-serif text-center md:text-left">
                    Personal Growth
                  </h1>
                  <p className="font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414] text-center md:text-left max-w-[500px]">
                    Members of the global community report to have reached an
                    increased sense of psychological well-being and emotional
                    stability, an increased feeling of social connectedness,
                    improved mood, productivity, focus and creativity through
                    the practice of microdosing
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="mb-24">
            <div className="grid grid-cols-10 gap-5">
              <div className="col-span-10 px-4 md:col-span-5 relative max-w-[300px] m-auto md:max-w-none md:m-0">
                <img src={slideImage2} alt="" />
              </div>
              <div className="col-span-10 md:col-span-5 flex items-center justify-center">
                <div className="relative z-[1]">
                  <p className="text-red text-2xl leading-[30.2px] mb-[29px] font-serif text-center md:text-left">
                    About the practice
                  </p>
                  <h1 className="text-blue text-[28px] sm:text-[44px] md:text-[51px] leading-[56.1px] mb-[26px] font-serif text-center md:text-left">
                    Spiritual Connection
                  </h1>
                  <p className="font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414] text-center md:text-left max-w-[500px]">
                    Microdosing has been reported to increase a sense of
                    spirituality by creating a greater{" "}
                    <span className="text-red">sense of connectedness</span> to
                    your environment, friends and your community. The practice
                    of microdosing has the potential to help you gain a greater
                    sense of oneness
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="mb-24">
            <div className="grid grid-cols-10 gap-5">
              <div className="col-span-10 px-4 md:col-span-5 relative max-w-[300px] m-auto md:max-w-none md:m-0">
                <img src={slideImage3} alt="" />
              </div>
              <div className="col-span-10 md:col-span-5 flex items-center justify-center">
                <div className="relative z-[1]">
                  <p className="text-red text-2xl leading-[30.2px] mb-[29px] font-serif text-center md:text-left">
                    About the practice
                  </p>
                  <h1 className="text-blue text-[28px] sm:text-[44px] md:text-[51px] leading-[56.1px] mb-[26px] font-serif text-center md:text-left">
                    Therapeutic Effects
                  </h1>
                  <p className="font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414] text-center md:text-left max-w-[500px]">
                    Recent studies have shown that microdosing with psilocybin
                    as well as other substances, especially when combined with
                    psychotherapy, may{" "}
                    <span className="text-red">
                      reduce symptoms of depression
                    </span>
                    , OCD, migraine/cluster headaches, anxiety and some forms of
                    addictions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}
