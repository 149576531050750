import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

interface ConfirmationModalProps {
  title: string;
  handler: () => Promise<void>;
  text: string;
  buttonText: string;
  actionText: string;
  buttonType?: string;
  loading?: boolean;
}

export default function ConfirmationModal({
  title,
  handler,
  buttonText,
  text,
  actionText,
  buttonType,
  loading,
}: ConfirmationModalProps) {
  let [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="">
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className={`${
            buttonType === "text" ? "!bg-transparent text-blue" : ""
          } bg-blue text-white rounded p-2`}
        >
          {buttonText}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{text}</p>
                  </div>

                  <div className="flex gap-4 mt-4 ">
                    <button
                      type="button"
                      className="bg-blue text-white rounded px-4 py-1"
                      onClick={() => {
                        handler().then(() => setIsOpen(false));
                      }}
                    >
                      {actionText}
                    </button>
                    <button
                      type="button"
                      className=""
                      onClick={() => setIsOpen(false)}
                    >
                      No
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
