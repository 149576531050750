import { useState } from "react";
import { toast } from "react-toastify";
import TypeFormProtocol from "../OnBoarding/TypeFormPortocol";
import { supabase } from "../../lib/supabaseClient";
import SelectTime from "../Forms/SelectTime";
import { goToTelegram } from "../../utils/nayaUtils";
import { UserType } from "../../types";

const StartingPoint: React.FC<{ user: UserType }> = ({ user }) => {
  const [notificationTime, setNotificationTime] = useState("08:30");
  const [showTypeFormProtocol, setShowProtocolOnBoarding] = useState(false);
  const upateUserNotificationTime = async () => {
    window.analytics.track("sets_remider_time");
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { data, error } = await supabase
      .from("users")
      .update({
        notification_time: notificationTime,
        time_zone: timezone,
        onboarding_step: "protocol_on_boarding",
      })
      .eq("id", user.id);
    toast("Your daily reminder time updated");
    window.analytics.track("sets_reminder");
  };
  return (
    <>
      {showTypeFormProtocol ? (
        <div>
          <button onClick={() => setShowProtocolOnBoarding(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="h-5 w-5"
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
          </button>
          <div className="text-center mb-4">
            <TypeFormProtocol
              name={user.user_name}
              substance={user.medicine_type}
              protocol={user.protocol}
              platform={user.platform}
              onSubmit={() => setShowProtocolOnBoarding(false)}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="mx-auto my-10 bg-white pb-4 rounded-xl drop-shadow-md w-full md:w-2/3 text-center p-3 relative z-10">
            <p className="text-gray mb-4">Set your daily reminder time</p>
            <div className="flex gap-4 justify-center z-10">
              <SelectTime
                value={notificationTime}
                setValue={(v) => setNotificationTime(v)}
              />
              <button
                className="text-white bg-primary rounded text-sm px-4"
                onClick={upateUserNotificationTime}
              >
                Save
              </button>
            </div>
          </div>
          <div className="mx-auto relative my-10 bg-white pb-4 rounded-xl drop-shadow-md w-full md:w-2/3 text-center p-3 z-3">
            <p className="mb-5 text-gray">
              Learn how to microdose with
              <br />
              the Fadiman Protocol
            </p>
            <button
              className="text-white bg-primary rounded py-2 px-4"
              onClick={() => setShowProtocolOnBoarding(true)}
            >
              Teach Me!
            </button>
          </div>
          <div className="mx-auto relative my-10 bg-white pb-4 rounded-xl drop-shadow-md w-full md:w-2/3 text-center p-3 z-3">
            <p className="mb-5 text-gray">
              Press below once you are
              <br />
              ready to start microdosing
            </p>
            <button
              onClick={() => {
                window.analytics.track("user_clicked_start_button");
                goToTelegram(user.tg_user_id, user.id);
              }}
              className="bg-eastern-blue w-36 h-36 rounded-full drop-shadow-lg text-white text-shadow-xl font-bold text-3xl hover:bg-sky-light hover:scale-95 active:scale-90 transition duration-300 ease-in-out z-3"
            >
              Start
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default StartingPoint;
