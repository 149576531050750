import { useEffect, useState } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { supabase } from "../../lib/supabaseClient";
import { useAuth } from "../../contexts/AuthProvider";

const Chat = () => {
  const { user } = useAuth();
  const [platfrom, setPlatform] = useState("");
  const goToTelegram = async () => {
    const url = user?.tg_user_id
      ? "https://t.me/withnaya_bot"
      : `https://t.me/withnaya_bot?start=${user?.id}`;
    window.open(encodeURI(url), "_blank", "noreferrer");
  };

  const openWhatsapp = async () => {
    return;
    const url = `https://api.whatsapp.com/send?phone=15550456806&text=Start+your+journey+with+naya![Code:${user?.code}]&app_absent=0&type=phone_number`;
    window.open(encodeURI(url), "_blank", "noreferrer");
  };

  useEffect(() => {
    setPlatform("");
  }, []);

  return (
    <div className="h-full min-h-[400px] md:min-h-[500px]">
      {platfrom !== "web" && (
        <div className="p-8 min-h-[320px]">
          <p className="text-eastern-blue text-center text-xl">
            Chat with Naya
          </p>
          <hr className="border-gray-light my-4" />
          <p className="text-center">Choose where to chat with Naya</p>
          <div className="flex gap-5 justify-center m-auto pt-5 flex-col w-full md:w-2/3">
            <button
              onClick={() => setPlatform("web")}
              className="flex bg-gray-light rounded-xl px-4 py-3 justify-between items-center text-primary hover:text-eastern-blue"
            >
              <p className="">Ask Naya a question</p>
              <BsChatDots className="" size="24" />
            </button>
            <button
              onClick={() => goToTelegram()}
              className="flex bg-gray-light rounded-xl px-4 py-3 justify-between items-center text-primary hover:text-eastern-blue"
            >
              <p className="">
                {" "}
                {user?.state.starting_date
                  ? "Continue on telegram"
                  : "Start Microdosing"}
              </p>
              <FaTelegramPlane className="" size="24" />
            </button>
            <p className="text-right text-eastern-blue text-sm">
              * Requires Telegram
            </p>
          </div>
        </div>
      )}
      {platfrom === "web" && (
        <>
          <button onClick={() => setPlatform("")} className="pl-2 py-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="h-5 w-5"
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
          </button>
          <iframe
            src="https://formless.ai/c/zOK0UlpPPPfd"
            width="100%"
            style={{ border: 0 }}
            allow="microphone"
            className="rounded-2xl min-h-screen"
          ></iframe>
        </>
      )}
    </div>
  );
};

export default Chat;
