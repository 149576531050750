import { useRef, useState, Fragment } from "react";
import Slider from "react-slick";
import { Dialog, Transition } from "@headlessui/react";
import Amenita from "../../assets/img/amenita.png";
import Musroom3 from "../../assets/img/musroom-3.png";
import Datura from "../../assets/img/datura.png";
import Flowers1 from "../../assets/img/flowers-1.png";
import Cactus1 from "../../assets/img/cactus-1.png";

interface OnBoardingWelcomeProps {
  open: boolean;
  setStartOnboarding: () => void;
}

const OnBoardingWelcome = ({
  open,
  setStartOnboarding,
}: OnBoardingWelcomeProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const [slideNumber, setSlideNumber] = useState(0);
  const sliderRef = useRef<any>();
  const SlideItems = [
    {
      image: Amenita,
      title: "Beyond a Microdosing App",
      text: "We see microdosing as a stepping stone, a part of a broader journey towards comprehensive personal growth and healing",
    },
    {
      image: Musroom3,
      title: "Heal, Grow, Transform",
      text: "Our mission is to provide you with a safe space for you to explore psychedelics, to guide you in the process of unlocking your potential for a meaningful life and transformative relationships",
    },
    {
      image: Cactus1,
      title: "Ancient knowledge enhanced by technology",
      text: "Naya combines scientific research, collective wisdom, and technology to provide transformative insights into your psychedelic experiences",
    },
    {
      image: Datura,
      title: "Meeting you where you are",
      text: "Whether you are taking your first steps with psychedelics or if you are an experienced traveler - Naya is designed to meet you where you are",
    },
  ];

  const next = () => {
    if (slideNumber === 3) {
      setStartOnboarding();
      setIsOpen(false);
      return;
    }
    sliderRef.current.slickNext();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-sm max-h-full md:max-w-2xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className="bg-[#FDFCF9] py-10 px-10 text-center md:min-h-[580px]">
                  <Slider
                    dots={true}
                    ref={sliderRef}
                    beforeChange={(current, next) => setSlideNumber(next)}
                  >
                    {SlideItems.map((i) => (
                      <div key={i.title}>
                        <p className="text-2xl text-[#969696] font-bold">
                          {i.title}
                        </p>
                        <hr className="text-gray-light my-5 w-1/3 m-auto" />
                        <p className="text-xl text-gray">{i.text}</p>
                        <img src={i.image} className="m-auto my-[40px]" />
                      </div>
                    ))}
                  </Slider>

                  <button
                    onClick={() => next()}
                    className={`w-1/2 bg-[#05053C] text-white px-1 md:px-3 rounded-md text-[18px] py-[10px] mt-4`}
                  >
                    Continue
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default OnBoardingWelcome;
