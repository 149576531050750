import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { supabase } from "../../lib/supabaseClient";
import { UserType } from "../../types";

const ContactSupport = () => {
  const { user } = useAuth();
  const [emailSent, setEmailSent] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const sendEmail = async () => {
    if (!message || !subject) return;
    const { data, error } = await supabase.functions.invoke("send-email", {
      body: {
        id: user?.id,
        name: user?.user_name ?? user?.name,
        message,
        email: user?.email,
        subject,
      },
    });
    if (data.ok) {
      setEmailSent(true);
    }
  };
  return (
    <div className="p-8">
      <p className="text-red text-center text-xl mb-5">Contact Support</p>
      {emailSent ? (
        <p className="text-center text-xl text-blue">
          We will get back to you shortly
        </p>
      ) : (
        <div>
          <p className="mb-4">Subject</p>
          <div className="border-b mb-4">
            <input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
            />
          </div>
          <p className="mb-4">Message</p>
          <div className="border-b mb-8">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={6}
              className="px-0 appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight outline-none focus:outline-none"
            />
          </div>
          <button
            onClick={() => sendEmail()}
            className="bg-primary justify-center items-center flex text-white font-bold uppercase w-full md:w-full px-5 md:px-8 py-2 md:py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 text-[14px]"
          >
            SEND
          </button>
        </div>
      )}
    </div>
  );
};

export default ContactSupport;
