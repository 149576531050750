import React, { useState } from "react";
interface InlineEditProps {
  value: any;
  setValue: (v: any) => void;
}

const InlineEdit = ({ value, setValue }: InlineEditProps) => {
  const [editingValue, setEditingValue] = useState(value);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEditingValue(event.target.value);


  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === "") {
      setEditingValue(value);
    } else {
      setValue(event.target.value);
    }
  };

  return (
    <input
      type="text"
      aria-label="Field name"
      value={editingValue}
      onChange={onChange}
      onBlur={onBlur}
      className="text-[1em] bg-amber px-2"
    />
  );
};

export default InlineEdit;