import { Link } from "react-router-dom";
import { IoLogoDiscord, IoLogoInstagram } from "react-icons/io5";
import { FaTelegram } from "react-icons/fa";
import logo from "../../assets/img/logo-w.png";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-blue py-8 min-h-[340px]">
        <div className="container mx-auto px-4 ">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <div className="logo flex items-center justify-center md:justify-start">
                <Link to={"/"}>
                  <img className="w-[90px]" src={logo} alt="" />
                </Link>
              </div>
              <h4 className="text-[18px] md:text-[22px] leading-[33px] font-serif text-white my-3">
                Let's keep in touch!
              </h4>
              <div className="flex space-x-[16px] my-6 lg:mt-[16px] justify-center lg:justify-start">
                <a
                  href="https://www.instagram.com/nayapsychedelics/"
                  target="_blank"
                  className="bg-white h-[40px] w-[40px] flex justify-center items-center rounded-full"
                  type="button"
                >
                  <IoLogoInstagram color="black" size={20} />
                </a>
                <a
                  href="https://t.me/+apF-W0ejF_AzZmM0"
                  target="_blank"
                  className="bg-white h-[40px] w-[40px] flex justify-center items-center rounded-full"
                  type="button"
                >
                  <FaTelegram color="black" size={20} />
                </a>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto text-white">
                  <span className="block capitalize text-sm font-semibold mb-6">
                    Useful Links
                  </span>
                  <ul className="list-unstyled space-y-6">
                    <li>
                      <Link to={"/what-is-microdosing"}>
                        <a className="underline">Microdosing</a>
                      </Link>
                    </li>
                     <li>
                      <Link to={"/about-us"}>
                        <a className="underline">About us</a>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4 text-white mt-12 lg:mt-0">
                  <span className="block capitalize font-semibold text-sm mb-6">
                    Other Resources
                  </span>
                  <ul className="list-unstyled space-y-6">
                    <li>
                      <Link to={"/terms"}>
                        <a className="underline text-sm">Terms & Conditions</a>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"}>
                        <a className="underline text-sm">Privacy Policy</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[1px] my-[32px] bg-[#D7E0E0] bg-opacity-20"></div>
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-white py-1">
                Copyright © {new Date().getFullYear()} made with love by Naya                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
