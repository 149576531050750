import { useNavigate } from "react-router";
import StartingPoint from "./StartingPoint";
import { useAuth } from "../../contexts/AuthProvider";
import { SurveysType } from "../../types";
import Calendar from "./Calendar";
import ProgressBar from "../UiElements/ProgressBar";
import Progress from "./Progress";

interface JournalHomeProps {
  surveys: SurveysType[] | [];
}

const JournalHome = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const protocolDay = user?.state?.protocol_day ?? 0;

  const state = user?.state;

  return (
    <>
      {user && (
        <div className="p-4 md:p-8">
          <p className="text-eastern-blue text-center text-xl mb-3">Home</p>
          <hr className="border-gray-light mb-8 w-full md:w-2/3 mx-auto" />
          {state?.starting_date ? (
            <>
              <div className="flex justify-center mt-6 gap-4">
                <button
                  onClick={() => navigate("/journal/entries#journal")}
                  className="bg-blue-light rounded px-2 md:px-8 py-2 flex gap-1 md:gap-4 font-light w-full text-center justify-center items-center hover:text-eastern-blue"
                >
                  <span className="text-sm md:text-md">Journal 📝</span>
                  <span className="text-sm md:text-md">+</span>
                </button>
                <button
                  onClick={() => navigate("/journal/entries#checkin")}
                  className="bg-blue-light rounded px-2 md:px-8 py-2 flex gap-1 md:gap-4 font-light w-full text-center justify-center items-center hover:text-eastern-blue"
                >
                  <span className="text-sm md:text-md">Check-in 🌻</span>
                  <span className="text-sm md:text-md">+</span>
                </button>
              </div>
              <div className="mt-8">
                <Calendar user={user} />
              </div>
              <div className="mt-8">
                <p className="text-xl">Progress</p>
                <hr className="border-gray my-4" />
                <ProgressBar
                  protocolDay={protocolDay}
                  progressPercentage={((protocolDay / 60) * 100).toFixed()}
                />
                <div className="mt-8">
                  <Progress
                    user={user}
                    surveys={user.surveys}
                    protocolDay={protocolDay}
                  />
                </div>
              </div>
            </>
          ) : (
            <StartingPoint user={user} />
          )}
        </div>
      )}
    </>
  );
};

export default JournalHome;
