// src/serviceWorkerRegistration.ts
import { Workbox } from "workbox-window";

export function registerServiceWorker() {
  if ("serviceWorker" in navigator) {
    const wb = new Workbox("/service-worker.js");

    wb.addEventListener("waiting", (event) => {
      // Prompt user to update the app
      console.log("A new service worker is waiting to activate.");
    });

    wb.addEventListener("controlling", (event) => {
      window.location.reload();
    });

    wb.register();
  }
}
