import React, { useState } from "react";
import moment from "moment";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { supabase } from "../../../lib/supabaseClient";
import SelectInput from "../../Forms/SelectInput";
import check from "../../../assets/img/check.png";
import { useAuth } from "../../../contexts/AuthProvider";

interface Protocol {
  key: string;
  name: string;
  info: string;
  img?: string;
}

interface StepContentProps {
  protocol: Protocol;
  step: string;
  setStep: (newStep: string) => void;
  microdosingDays: string[];
  setMicrodosingDays: (newDays: string[]) => void;
  close: () => void;
}

const StepContent = ({
  protocol,
  step,
  setStep,
  microdosingDays,
  setMicrodosingDays,
  close,
}: StepContentProps) => {
  const { user, getUser } = useAuth();
  const variants = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50 },
  };
  const changeProtocol = async () => {
    const oldStartingDate = {
      protocol: user?.protocol ?? "",
      starting_date: user?.state.starting_date ?? "",
    };
    const { data, error } = await supabase
      .from("users")
      .update({ protocol: protocol.key })
      .eq("id", user?.id);
    if (error) {
      toast.error("Something went wrong try again later");
      console.log(error);
      return;
    }
    // Update user state
    const archiveStartingDate =
      user?.state.archive_starting_date ?? ([] as any);
    archiveStartingDate.push(oldStartingDate);
    const dataToUpdate = {
      starting_date: moment().format("YYYY-MM-DD"),
      archive_starting_date: archiveStartingDate,
    };
    await supabase
      .from("user_state")
      .update(dataToUpdate)
      .eq("user_id", user?.id);
    await getUser();
    toast(`Your protocol updated to ${protocol.name}`);
    setStep("updated");
  };
  switch (step) {
    case "map":
      return (
        <motion.div
          className="flex justify-between flex-col"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={variants}
          transition={{ duration: 0.8 }}
        >
          <div className="flex justify-center mb-16 my-6">
            <img src={protocol.img} alt={protocol.name} className="w-2/5" />
          </div>
          <button
            className="p-3 rounded-xl bg-orange w-full md:w-1/3 text-white m-auto"
            onClick={() => setStep("info")}
          >
            Next
          </button>
        </motion.div>
      );
    case "info":
      return (
        <div className="w-full md:w-3/4 m-auto text-center tra">
          <p className="text-eastern-blue text-center text-xl mb-10">
            {protocol.name}
          </p>
          <p className="text-left text-xl font-light mb-24 text-primary">
            {protocol.info}
          </p>
          <button
            className="p-3 rounded-xl bg-orange w-full md:w-1/3 text-white"
            onClick={() =>
              setStep(
                protocol.key === "naya" || protocol.key === "stamets"
                  ? "setup"
                  : "shifting"
              )
            }
          >
            Next
          </button>
        </div>
      );
    case "setup":
      if (protocol.key === "naya" || protocol.key === "stamets") {
        return (
          <motion.div
            className="w-full md:w-3/4 m-auto text-center"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.5 }}
          >
            <p className="text-eastern-blue text-center text-xl mb-6 font-bold">
              {protocol.name}
            </p>
            <p className="text-eastern-blue text-center text-xl font-light mb-4 ">
              {protocol.key === "naya"
                ? "Set your microdosing days"
                : "Set your protocol starting day "}
            </p>
            <div className="flex z-20 mb-10 w-full justify-center gap-4">
              <div className="w-full">
                <SelectInput
                  items={moment
                    .weekdays()
                    .filter((day) => day !== microdosingDays[1])}
                  value={microdosingDays[0]}
                  setValue={(v) => setMicrodosingDays([v, microdosingDays[1]])}
                  buttonClass="bg-cream-light rounded-xl px-4 py-3 text-center shadow-none"
                />
              </div>
              {protocol.key === "naya" && (
                <div className="w-full">
                  <SelectInput
                    items={moment
                      .weekdays()
                      .filter((day) => day !== microdosingDays[0])}
                    value={microdosingDays[1]}
                    setValue={(v) =>
                      setMicrodosingDays([microdosingDays[0], v])
                    }
                    buttonClass="bg-cream-light rounded-xl px-4 py-3 text-center shadow-none"
                  />
                </div>
              )}
            </div>
            <button
              className="p-3 rounded-xl bg-orange w-2/3 text-white"
              onClick={() => setStep("shifting")}
            >
              Next
            </button>
          </motion.div>
        );
      }
      return null;
    case "shifting":
      return (
        <motion.div
          className="w-full md:w-3/4 m-auto text-center"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={variants}
          transition={{ duration: 0.5 }}
        >
          <p className="text-eastern-blue text-center text-xl mb-8 font-bold">
            Shifting paths ahead
          </p>
          <p className="text-left text-xl font-light mb-24 text-primary">
            By proceeding, you acknowledge that your calendar will adjust to
            reflect the new protocol and insights will be tailored based on this
            selection
          </p>
          <button
            className="p-3 rounded-xl bg-orange w-full md:w-1/3 text-white"
            onClick={() => changeProtocol()}
          >
            Let’s go
          </button>
        </motion.div>
      );
    case "updated":
      return (
        <motion.div
          className="w-full md:w-3/4 m-auto text-center"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={variants}
          transition={{ duration: 0.5 }}
        >
          <p className="text-eastern-blue text-center text-xl mb-6">
            Protocol updated successfully!
          </p>
          <img src={check} alt="check" className="m-auto mb-6" />
          <p className="text-eastern-blue text-center text-xl font-light mb-24">
            Look at you, mixing things up! I’ve got your back and your new
            protocol is all set. I’ve updated your calendar accordingly so all
            you have to do is let me know the next time you microdose Onward to
            adventure!
          </p>
          <button
            className="p-3 rounded-xl bg-orange w-full w-full md:w-2/3 text-white"
            onClick={() => close()}
          >
            Back to Portal
          </button>
        </motion.div>
      );
    default:
      return null;
  }
};

export const SelectedProtocol = ({
  protocol,
  close,
}: {
  protocol: Protocol;
  close: () => void;
}) => {
  const [step, setStep] = useState("map");
  const [microdosingDays, setMicrodosingDays] = useState<string[]>([
    "Sunday",
    "Wednesday",
  ]);

  return (
    <StepContent
      protocol={protocol}
      step={step}
      setStep={setStep}
      microdosingDays={microdosingDays}
      setMicrodosingDays={setMicrodosingDays}
      close={close}
    />
  );
};
