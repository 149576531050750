import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import cloudLeft from "../assets/img/cloud-left.png";
import cloudRight from "../assets/img/cloud-right.png";
// Team
import ashley from "../assets/img/team/ashley.png";
import dannah from "../assets/img/team/dannah.png";
import dustin from "../assets/img/team/dustin.png";
import gal from "../assets/img/team/gal.png";
import ishai from "../assets/img/team/ishai.png";
import kirill from "../assets/img/team/kirill.png";
import mira from "../assets/img/team/mira.png";
import nirA from "../assets/img/team/nir-a.png";
import nir from "../assets/img/team/nir.png";
import ayelet from "../assets/img/team/ayelet.png";
import rotem from "../assets/img/team/rotem.png";
export default function Landing() {
  return (
    <>
      <Navbar logoW={true} />
      <main
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "434px",
          backgroundBlendMode: "overlay",
          backgroundPosition: "84% 14%",
        }}
        className="bg-[#F5F1EB] bg-right-top overflow-hidden"
      >
        <div className="relative bg-[#539BAD] px-8 md:px-0 h-[300px] md:h-[300px] mb-24 md:mb-10">
          <div className="container mx-auto relative text-center">
            <div className="pt-[140px] md:pt-[140px]">
              <h1 className="text-[38px] text-white sm:text-[44px] md:text-[51px] leading-[110%] md:leading-[56.1px] tracking-[-0.04em] mb-[26px] font-serif">
                About us
              </h1>
              <p className="font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-white">
                Meet the team behind the Naya experience
              </p>
            </div>
          </div>
          <div className="overflow-hidden md:flex hidden">
            <div className="absolute left-0 top-[10%] max-w-[300px]">
              <img src={cloudLeft} alt="Naya-cloud" />
            </div>
            <div className="absolute right-0 top-0 max-w-[300px]">
              <img src={cloudRight} alt="Naya-cloud" />
            </div>
          </div>
        </div>
        <section className="relative container mx-auto mt-0 px-8 md:px-0 md:mt-24">
          <div className="mt-10 flex justify-center flex-col relative">
            <h1 className="text-blue text-[38px] leading-[110%] sm:text-[44px] tracking-[-0.04em] md:text-[51px] md:leading-[56.1px] mb-[28px] md:mb-[42px] font-serif">
              Build by Microdosers for
              <br />
              Microdosers
            </h1>
            <div className="grid grid-cols-2 gap-2 md:gap-10">
              <div className="col-span-2 md:col-span-1 font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414]">
                Naya built by a team of domain experts who came together to
                breath to life a unique microdosing experience. Naya is designed
                to guide you in matching your intentions with the right
                substance and protocol, tracking your progress, and
              </div>
              <div className="col-span-2 md:col-span-1 font-light text-[18px] md:text-[20px] leading-[31.2px] mt-[2px] mb-4 text-[#141414]">
                adhering to your protocol, so you can make the most of your
                microdosing experience. At Naya, our mission is to make
                microdosing safe, accessible and affordable for anyone looking
                to improve their personal growth and well-being
              </div>
            </div>
          </div>
        </section>
        <section className="relative container mx-auto mt-0 px-8 md:px-0 md:mt-24 pb-24">
          <div className="mt-10 flex justify-center flex-col relative">
            <h1 className="text-blue text-[38px] leading-[110%] sm:text-[44px] tracking-[-0.04em] md:text-[51px] md:leading-[56.1px] mb-[28px] md:mb-[42px] font-serif">
              The team
            </h1>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-10 md:gap-16">
              <div className="col-span-1 md:col-span-1">
                <img src={gal} alt="gal" />
                <p className="text-[24px] mt-2">Gal</p>
                <span className="text-[18px]">Heart of Experience</span>
              </div>
              <div className="col-span-1 md:col-span-1">
                <img src={nirA} alt="nir" />
                <p className="text-[24px] mt-2">Nir</p>
                <span className="text-[18px]">Heart of Technology</span>
              </div>
              <div className="col-span-1 md:col-span-1">
                <img src={mira} alt="mira" />
                <p className="text-[24px] mt-2">Mira</p>
                <span className="text-[18px]">Experience team</span>
              </div>
              <div className="col-span-1 md:col-span-1">
                <img src={dannah} alt="dannah" />
                <p className="text-[24px] mt-2">Dannah</p>
                <span className="text-[18px]">Experience team</span>
              </div>
              <div className="col-span-1 md:col-span-1">
                <img src={kirill} alt="gal" />
                <p className="text-[24px] mt-2">Kirill</p>
                <span className="text-[18px]">Technology team</span>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-amber py-10 md:py-24">
          <div className="overflow-hidden md:flex relative">
            <div className="absolute left-0  max-w-[300px]">
              <img src={cloudLeft} alt="Naya-cloud" />
            </div>
            <div className="absolute right-0 top-0 max-w-[300px]">
              <img src={cloudRight} alt="Naya-cloud" />
            </div>
          </div>
          <section className="relative container mx-auto px-8 md:px-0">
            <div className="mt-10 flex justify-center flex-col relative">
              <h1 className="text-blue text-[38px] leading-[110%] sm:text-[44px] tracking-[-0.04em] md:text-[51px] md:leading-[56.1px] mb-[22px] font-serif">
                Advisors
              </h1>
              <p>
                We take our mission seriously. This is way we are <br />
                being advised by industry thought leaders.
              </p>

              <div className="grid grid-cols-2 md:grid-cols-4 gap-10 md:gap-12 mt-8">
                <div className="col-span-1 md:col-span-1">
                  <img src={ashley} alt="ashley" className="md:w-[65%]" />
                  <p className="text-[24px] mt-2">Ashley Southlad</p>
                  <p className="text-[16px] font-bold">
                    Strategic Partnerships
                  </p>
                  <p className="text-[16px]">
                    Co-Founder Mushroom Design and the Healer Collective <br />
                    Co-founder the Ibogaine Research institute and Psychedelics
                    advocate
                  </p>
                </div>
                <div className="col-span-1 md:col-span-1">
                  <img src={nir} alt="nir" className="md:w-[65%]" />
                  <p className="text-[24px] mt-2">Nir Tadmor</p>
                  <p className="text-[16px] font-bold">
                    Integration and Support
                  </p>
                  <p className="text-[16px]">
                    Psychotherapist and Harm Reduction Specialist. Director of
                    Education and Training at Impulse - Integrative Clinical
                    Center, Co-founder of Safe Shore - Psychedelic Care.
                  </p>
                </div>
                {/* <div className="col-span-1 md:col-span-1">
                  <img src={dustin} alt="dustin" className="md:w-[65%]" />
                  <p className="text-[24px] mt-2">Dustin Marlan</p>
                  <p className="text-[14px]">Advisor</p>
                </div> */}
                <div className="col-span-1 md:col-span-1">
                  <img src={ishai} alt="Ishai" className="md:w-[65%]" />
                  <p className="text-[24px] mt-2">Ishai Ankri</p>
                  <p className="text-[16px] font-bold">Product</p>
                  <p className="text-[18px]">
                    Experienced Product Executive, VP of Products, Rider Via
                    (unicorn) and Founding PM in Behave.ai
                  </p>
                </div>
                <div className="col-span-1 md:col-span-1">
                  <img src={ayelet} alt="Ayelet" className="md:w-[65%]" />
                  <p className="text-[24px] mt-2">Prof. Ayelet Gneezy </p>
                  <p className="text-[16px] font-bold">Advisor</p>
                  <p className="text-[18px]">
                    The Carol Lazier and Family Endowed Chair in Social
                    Innovation and Impact Professor of Behavioral Sciences and
                    Marketing
                  </p>
                </div>
                <div className="col-span-1 md:col-span-1">
                  <img src={rotem} alt="Rotem" className="md:w-[65%]" />
                  <p className="text-[24px] mt-2">Rotem Petranker</p>
                  <p className="text-[16px] font-bold">Advisor</p>
                  <p className="text-[18px]">
                   Director, Canadian Centre for Psychedelic Science
Associate Director, Psychedelic Studies Research Program at the University of Toronto 
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="container mx-auto px-8 md:px-0 pt-10">
            <div className="mt-10 relative text-center ">
              <h1 className="text-blue text-[28px] leading-[110%] sm:text-[30px] tracking-[-0.04em] md:text-[31px] md:leading-[56.1px] mb-[22px] font-serif text-center">
                Join our growing community on{" "}
                <a
                  href="https://t.me/+apF-W0ejF_AzZmM0"
                  target="_blank"
                  className="text-red"
                >
                  Telegram
                </a>
              </h1>
              <div className="">
                <img
                  alt="..."
                  className="m-auto"
                  src={require("../assets/img/butterfly1.png")}
                />
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}
